import React, { useState, useEffect, useRef } from "react";
import ReactCrop from 'react-image-crop';
import { useHistory } from "react-router-dom";
import 'react-image-crop/dist/ReactCrop.css';
import Select from "react-select";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";

import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'

import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";

import DayTimePicker from "./DayTimePicker";

import { ToastContainer, toast } from "react-toastify";
import { postRequest, postRequestForm } from "../../components/Common/Utils.js";
import LocationPicker from "react-location-picker";




const defaultPosition = {
  lat: 33.6706,
  lng: 36.0227,
};
export default function CreateVenue() {
  const [previewCover, setPreviewCover] = useState("");
  const [previewLogo, setPreviewLogo] = useState("");
  const [venueData, setVenueData] = useState(null);

  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const [loading, setLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [completedCrop, setCompletedCrop] = useState(null)
  const [selectedLogo, setSelectedLogo] = useState("");
  const [crop, setCrop] = useState({ unit: 'px', x: 25, y:25, width: 250, height: 250, aspect: 1, maxWidth: 250, maxHeight: 250, minHeight: 250, minWidth: 250});
  const [selectedCover, setSelectedCover] = useState("");

  const blobUrlRef = useRef('')

  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)

  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);

  const [logoSelected, setLogoSelected] = useState(0);

  const [remoteLogo, setRemoteLogo] = useState(null)

  const [themeList, setThemeList] = useState([]);
  const [musicList, setMusicList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [cuisineList, setCuisineList] = useState([]);

  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  

  const [businessOptions, setBusinessOptions] = useState([
    { value: "", label: "None" },
  ]);

  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;
    setTimeOptions(newTimeOptions);
  };

  let history = useHistory();

  const msUpdateTheme = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedTheme(ids);
  };

  const msUpdateMusic = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedMusic(ids);
  };

  const msUpdateFeature = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedFeature(ids);
  };

  const msUpdateCuisine = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCuisine(ids);
  };

  useEffect(() => {
    if (!selectedLogo) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewLogo(reader.result);
    };
    reader.readAsDataURL(selectedLogo);
  }, [selectedLogo]);

  const handleLogoChange = (e) => {
    setSelectedLogo(e.target.files[0]);

    console.log("e.target.files[0]", e.target.files[0])


    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
  };

  
  const handleCropComplete = (crop) => {

    setCompletedCrop(crop);    

    

   
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )

        setLogoSelected(logoSelected + 1);
      }
    },
    100,
    [completedCrop],
  )

  useEffect(() => {
    
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to create blob')
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current)
      }
      blobUrlRef.current = URL.createObjectURL(blob)
     

     // convert blob to file

      const file = new File([blob], venueData.name + "-logo.jpg", { type: "image/jpeg" });
      setRemoteLogo(file)

     console.log("blobUrlRef.current", file);
    })
    
  }, [logoSelected]);

  const handleLocationChange = ({ position, address, places }) => {
    const lng = position.lng;
    const lat = position.lat;
    const venueDt = { ...venueData, latitude: lat, longitude: lng };
    setVenueData(venueDt);
  };
  useEffect(() => {
    fetchList();
    fetchTheme();
    fetchMusic();
    fetchFeature();
    fetchCuisine();
  }, []);

  const fetchTheme = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("theme/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setThemeList(options);
    }
  };

  const fetchMusic = async () => {
    const param = { page: 1, limit: 100000 };
    const music = await postRequest("music/list", param);
    if (music.status == 1) {
      const options = music.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setMusicList(options);
    }
  };

  const fetchFeature = async () => {
    const param = { page: 1, limit: 100000 };
    const feature = await postRequest("feature/list", param);
    if (feature.status == 1) {
      const options = feature.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setFeatureList(options);
    }
  };

  const fetchCuisine = async () => {
    const param = { page: 1, limit: 100000 };
    const cuisine = await postRequest("cuisine/list", param);
    if (cuisine.status == 1) {
      const options = cuisine.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setCuisineList(options);
    }
  };

  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

  const [itemsdata, setItems] = useState([]);
  const fetchList = async () => {
     //  setLoading(true);
    const param = { page: 1, limit: 100000 };
    const business = await postRequest("business/businesses_list", param);
    if (business.status == 1) {
      const options = business.data.list.map((item) => {
        return { value: item._id, label: item.name };
      });

      
      setBusinessOptions(options);
    }

    setLoading(false);
  };

  const Createvenue = async (e) => {
    e.preventDefault();

    if (!venueData.business_id) {
      toast.error("Please select business");
      return;
    }

    if (!venueData.name) {
      toast.error("Please enter venue name");
      return;
    }
    if (!venueData.about) {
      toast.error("Please enter venue about");
      return;
    }
    if (!venueData.address) {
      toast.error("Please enter venue address");
      return;
    }
    if (!venueData.phone) {
      toast.error("Please enter venue phone");
      return;
    }
    if (!venueData.email) {
      toast.error("Please enter venue email");
      return;
    }
    if (!venueData.website) {
      toast.error("Please enter venue website");
      return;
    }
    if (!venueData.booking_url) {
      toast.error("Please enter venue booking url");
      return;
    }
    if (!venueData.menu_url) {
      toast.error("Please enter venue menu url");
      return;
    }
    if (!venueData.dress_code) {
      toast.error("Please enter venue dress code");
      return;
    }

    if (!venueData.latitude) {
      toast.error("Please enter venue latitude");
      return;
    }

    if (!venueData.longitude) {
      toast.error("Please enter venue longitude");
      return;
    }
    if (!remoteLogo) {
      toast.error("Please select venue logo");
      return;
    }
    if (!selectedCover) {
      toast.error("Please select venue cover");
      return;
    }

    if (!selectedTheme.length) {
      toast.error("Please select venue theme");
      return;
    }

  

    if (!selectedFeature.length) {
      toast.error("Please select venue feature");
      return;
    }

    if (!selectedCuisine.length) {
      toast.error("Please select venue cuisine");
      return;
    }

     //  setLoading(true);

    const formData = new FormData();
    formData.append("business_id", venueData.business_id);
    formData.append("name", venueData.name);
    formData.append("about", venueData.about);
    formData.append("address", venueData.address);
    formData.append("phone", venueData.phone);
    formData.append("email", venueData.email);
    formData.append("website", venueData.website);
    formData.append("latitude", venueData.latitude);
    formData.append("longitude", venueData.longitude);
    formData.append("booking_url", venueData.booking_url);
    formData.append("menu_url", venueData.menu_url);
    formData.append("dress_code", venueData.dress_code);
    formData.append("logo", remoteLogo);
    formData.append("cover", selectedCover);

    // add selected themes
    selectedTheme.forEach((item) => {
      formData.append("themes[]", item);
    });

    // add selected music
    selectedMusic.forEach((item) => {
      formData.append("music[]", item);
    });

    // add selected feature
    selectedFeature.forEach((item) => {
      formData.append("features[]", item);
    });

    // add selected cuisine
    selectedCuisine.forEach((item) => {
      formData.append("cuisines[]", item);
    });

    timeOptions.forEach((item, index) => {
      if (item.checked) {
        formData.append(`days[]`, days[index].toString().toLowerCase());
        formData.append(`open_time[]`, item.open);
        formData.append(`close_time[]`, item.close);
      }
    });

    

    const response = await postRequestForm("venue/create", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/venues");
      }, 1000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...venueData };
    list[index] = value;
    setVenueData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div claaName="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Venue Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Venue Management</li>:{" "}
              <li className="breadcrumb-item">Create Venue</li>
            </ol>
          </div>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Venue </h4>
                <Form onSubmit={Createvenue}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Business Name
                          </label>
                          <div className="col-sm-9">
                            <Select
                              options={businessOptions}
                              onChange={(e) =>
                                updateValue("business_id", e.value)
                              }
                              placeholder="Select Business Name"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("name", e.target.value)
                              }
                              name="name"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            About
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("about", e.target.value)
                              }
                              rows="4"
                              cols="50"
                              name="about"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Address
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("address", e.target.value)
                              }
                              rows="4"
                              cols="50"
                              name="address"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Phone
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("phone", e.target.value)
                              }
                              name="phone"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Email
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="email"
                              onChange={(e) =>
                                updateValue("email", e.target.value)
                              }
                              name="email"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Website
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("website", e.target.value)
                              }
                              name="website"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Booking Url
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("booking_url", e.target.value)
                              }
                              name="booking_url"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Menu Url
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("menu_url", e.target.value)
                              }
                              name="menu_url"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Dress Code
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("dress_code", e.target.value)
                              }
                              name="dress_code"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Logo
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="file"
                              // onChange={(e) =>
                              //   setSelectedLogo(e.target.files[0])
                              // }
                              onChange={handleLogoChange}
                              name="logo"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewLogo && (                             
                              <ReactCrop                                
                                crop={crop}
                                src={previewLogo}
                                onChange={(newCrop) => setCrop(newCrop)}
                                onComplete={handleCropComplete}    
                                style={{ maxWidth: '720px', maxHeight: '600px' }}                             
                              >
                                <img ref={imgRef} src={previewLogo} />
                              </ReactCrop>
                            )}

                            {completedCrop && (  
                                 <div>
                                  <canvas
                                    ref={previewCanvasRef}
                                    style={{
                                      border: '1px solid black',
                                      objectFit: 'contain',
                                      width: completedCrop.width,
                                      height: completedCrop.height,
                                    }}
                                  />
                               </div>                       
                            )}



                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Cover
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedCover(e.target.files[0])
                              }
                              name="cover"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewCover && (
                              <img
                                src={previewCover}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Theme
                          </label>
                          <div className="col-sm-9">
                            <MultiSelect
                              options={themeList}
                              onSelect={msUpdateTheme}
                              selectedValues={[]}
                              placeholder="Select Theme"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Cuisine
                          </label>
                          <div className="col-sm-9">
                            <MultiSelect
                              options={cuisineList}
                              onSelect={msUpdateCuisine}
                              selectedValues={[]}
                              placeholder="Select Cuisine"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Music
                          </label>
                          <div className="col-sm-9">
                            <MultiSelect
                              options={musicList}
                              onSelect={msUpdateMusic}
                              selectedValues={[]}
                              placeholder="Select Music"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Feature
                          </label>
                          <div className="col-sm-9">
                            <MultiSelect
                              options={featureList}
                              onSelect={msUpdateFeature}
                              selectedValues={[]}
                              placeholder="Select Feature"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Time
                          </label>
                          <div className="col-sm-9">
                            {days.map((day, index) => (
                              <DayTimePicker
                                updateTiming={updateTiming}
                                currentValue={timeOptions[index]}
                                index={index}
                                key={index}
                                day={day}
                              />
                            ))}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Select Location
                          </label>
                          <div className="col-sm-9">
                            <LocationPicker
                              containerElement={
                                <div style={{ height: "100%" }} />
                              }
                              mapElement={<div style={{ height: "600px" }} />}
                              defaultPosition={currentLocation}
                              onChange={handleLocationChange}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Latitude
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="latitude"
                              defaultValue={venueData?.latitude}
                              className="form-control"
                              id="horizontal-email-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Longitude
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="longitude"
                              defaultValue={venueData?.longitude}
                              className="form-control"
                              id="horizontal-email-input"
                            />
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
