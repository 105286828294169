import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequestForm, postRequest, putRequestForm, putRequestJson, putRequest } from "../../components/Common/Utils.js";
import CustomLoder from "./CustomLoder.js";
import MultiselectCommon from "../CustomTicket/MultiselectCommon.js";

export default function UpdateTicketSubCategory() {
  const { _id } = useParams();
  const location = useLocation();
  const state = location?.state?.row;
  console.log({ state });

  const [bannerData, setBannerData] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [ShowLoder, setShowLoder] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const [categoryOptions, setCategoryData] = useState([]);
  const [categoryDataIds, setSelectedCaterogyIds] = useState([]);

  const [url, seturl] = useState("");

  let history = useHistory();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);
  const CategorylistManagment = async () => {
    const payload = {
      limit: 100000,
      page: 1,
    };
    try {
      const response = await postRequest(`ticket/category/list`, payload);
      const data = response.data;
      let filter = data.list.map((item) => ({
        name: item?.name,
        id: item?._id,
      }));
      setCategoryData(filter);
    } catch (error) {
      console.log("Failed to fetch data:", error);
    } finally {
    }
  };
  useEffect(() => {
    CategorylistManagment();
  }, []);
  const Createbannerform = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    setShowLoder(true);
    console.log({ url })
    // formData.append("name", bannerData.name);

    // formData.append("image", url);
    let payload = {
      id: _id,
      name: bannerData.name,
      customCategoryId: bannerData.customCategoryId,
      image: url
    }

    const response = await putRequest("ticket/sub-category/update", payload);
    console.log({ response });
    if (response.status == 1) {
      toast.success(response.message);
      setShowLoder(false);
      setTimeout(() => {
        history.push("/SubCategoryList");
      }, 1000);
    } else {
      toast.error(response.message);
      setShowLoder(false);
    }
  };
  const msUpdateDay = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCaterogyIds(ids);
  };
  const updateValue = (index, value) => {
    const list = { ...bannerData };
    list[index] = value;
    setBannerData(list);
  };

  const Imageupload = async (e) => {
    const formData = new FormData();
    formData.append("file", selectedImage);
    const response = await postRequestForm("homepage/upload/image", formData);
    let image = response.data.url;
    console.log({ image });
    seturl(image)
    // setSelectedImage(image);
  };

  useEffect(() => {
    Imageupload();
  }, [selectedImage]);

  useEffect(() => {
    if (state) {
      console.log(state)
      setBannerData(state);
      setPreviewImage(state?.image);
      seturl(state?.image);
    }
  }, [state]);

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row d-flex flex-row">
        <div className="col-md-10">
          <div className="page-title-box">
            <h4>Ticket Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Ticket Management</li>
              <li className="breadcrumb-item">Update Sub Category </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title"> Update Sub Category</h4>
              {ShowLoder ? (
                <CustomLoder />
              ) : (
                <Form onSubmit={Createbannerform}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Title</label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("name", e.target.value)
                              }
                              value={bannerData?.name}
                              name="name"
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Category
                          </label>
                          <div className="col-sm-6">
                            <MultiselectCommon
                              options={categoryOptions}
                              onSelect={msUpdateDay}
                            // setselectedCaterogy={setselectedCaterogy}
                            />
                            
                          </div>
                        </div> */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Category</label>

                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("customCategoryId", e.target.value)
                              }
                              name="customCategoryId"
                              value={bannerData?.customCategoryId || ""}
                              class="form-select"
                            >
                              <option value="">Select</option>
                              {categoryOptions.map((option) => {
                                return <option value={option.id}>{option.name}</option>
                              })}


                            </select>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            File
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="mediaUrl"
                              className="form-control"
                              id="horizontal-password-input"
                            // required="required"
                            />
                            <br />
                            {bannerData?.mediaType == "video"
                              ? null
                              : previewImage && (
                                <img
                                  src={previewImage}
                                  style={{ width: "100px", height: "100px" }}
                                />
                              )}
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>)}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
