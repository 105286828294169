import { Modal } from "reactstrap";
import DragDropItems from "./DragDropItemsModel";

// import DragDropItemsModel from './DragDropItemsModel';

const DragDropModal = ({ show, closeModal, data, setData }) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5
          className="modal-title mt-0"
          id="myLargeModalLabel"
        >
          List Ordering
        </h5>
        <button
          onClick={() => {
            closeModal()
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <DragDropItems data={data} setData={setData} />
        {/* <DragDropItems/> */}
   
      </div>

    </Modal>
  );
};


export default DragDropModal;