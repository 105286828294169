import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import moment from "moment";
import {
  getRequest,
  putRequest,
  deleteRequest,
  postRequest,
} from "../../components/Common/Utils.js";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  Spinner,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const BlockSizeList = (props) => {
  const { SearchBar } = Search;
  const [userdata, setuserdata] = useState([]);
  const [loader, setLoader] = useState(false);
  let token = localStorage.getItem("token");

  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [image, setImage] = React.useState("");

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const wishlists_pageOptions = {
    sizePerPage: 10,
    totalSize: userdata.length,
    custom: true,
  };

  function SNoFormatter(cellContent, row, i) {
    return (
      <>
        <span style={{ width: "20px" }}>{i + 1}</span>
      </>
    );
  }

  function CreatedAt(cellContent, row) {
    let createdAt = row.createdAt;
    return (
      <React.Fragment>
        <div>
          <p>{moment(createdAt).format("DD-MM-YYYY")} </p>
        </div>
      </React.Fragment>
    );
  }

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: true,
    },

    {
      dataField: "type",
      text: "Type",
      sort: true,
    },
    {
      dataField: "ratio",
      text: "Ratio",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
      formatter: CreatedAt,
    },
    {
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];

  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex justify-content-center">
          <button type="button" className=" btn btn-primary  mx-2">
            {" "}
            <Link
              to={{
                pathname: `/Updatesizedata/${row._id}`,
                state: { row },
              }}
            >
              {" "}
              <i className="fal fa-edit fs-5 text-light"></i>
            </Link>{" "}
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id)}
          >
            <i className="fal fa-trash  fs-5"></i>
          </button>
        </div>
      </React.Fragment>
    );
  }

  const limit = 2;

  const sizeManagment = async (page = 0) => {
    setLoader(true);
    const response = await postRequest(`homepage/size/list`);
    const data = response.data.list;

    setuserdata(data);

    setLoader(false);
  };

  async function handlePageClick(responseData) {
    console.log(responseData);
    const page = responseData.selected + 0;
    const commentsFormServer = await UserManagmentlist(page);
    console.log(commentsFormServer);
    setuserdata(commentsFormServer);
  }

  useEffect(() => {
    sizeManagment();
  }, []);
  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`homepage/size/delete`, {
          id: [id],
        });

        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          sizeManagment();
        }
      }
    });
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Size Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Size Management</li>:{" "}
                <li className="breadcrumb-item">
                  <Link to="#">Size List</Link>
                </li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end">
            <Link to="/Createsizedata">
              <Button>
                {" "}
                <i className="fas fa-plus"></i> Create
              </Button>
            </Link>
          </div>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  <PaginationProvider
                    pagination={paginationFactory(wishlists_pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <>
                        <ToolkitProvider
                          keyField="id"
                          data={userdata}
                          columns={columns}
                          search
                        >
                          {(props) => (
                            <Row>
                              <Col className="col-lg-12 text-form">
                                <SearchBar
                                  style={{ width: "20%" }}
                                  {...props.searchProps}
                                />
                              </Col>

                              <Col className="col-lg-12 text-end">
                                <BootstrapTable
                                  defaultSorted={defaultSorted}
                                  classes="table-striped"
                                  {...props.baseProps}
                                  {...paginationTableProps}
                                />
                              </Col>
                            </Row>
                          )}
                        </ToolkitProvider>

                        <Row>
                          <Col className="col-lg-12 text-end">
                            <div className="text-md-right">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </PaginationProvider>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>

        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
    </>
  );
};

export default BlockSizeList;
