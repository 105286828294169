import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";

//import filter modal
import FilterModal from "../../components/Modal/FilterModal";

import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest,
  putRequestForm,
  postRequestForm,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Hotel } from "@material-ui/icons";

const filterObj = {
  name: "",
  code: "",
  intCode: "",
  serviceTypeCode: "",
  createdAt: "",
};

const TicketManagement = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [ratingdata, setratingdata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [value, setValue] = React.useState(10);

  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");

  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [canExport, setCanExport] = useState(true);
  const [model, setModel] = useState(false);
  const [filter, setFilter] = useState({ ...filterObj });

  const [loader2, setLoader2] = useState(false);

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value == 1 ? 1 : 0;

    const formData = new FormData();
    formData.append("ratingId", actId);
    formData.append("status", status);

    const res = await putRequestForm("rating/update", formData);

    if (res.status == 1) {
      toast.success("Rating Status updated Successfully");
      ratingManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  useEffect(() => {
    ratingManagment();
  }, [currentPage]);

  useEffect(() => {
    ratingManagment();
  }, [sort, sortOrder]);

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    console.log("toggleModel", model);
    setModel(!model);
  };
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
        console.log("page", page);
      }
    }

    console.log("type", type);

    if (type === "search") {
      console.log("searchText", searchText);
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const applyFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key],
        };

        if (
          key === "name" ||
          key === "code" ||
          key === "intCode" ||
          key === "serviceTypeCode"
        ) {
          filterObject.type = "regex";
        } else if (key === "status" || key === "stars") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });

    console.log("filterArgs", filterArgs);
    toggleModel();
    ratingManagment(filterArgs);
  };

  const getFilters = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key],
        };

        if (
          key === "name" ||
          key === "code" ||
          key === "intCode" ||
          key === "serviceTypeCode"
        ) {
          filterObject.type = "regex";
        } else if (key === "status" || key === "stars") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });

    console.log("filterArgs", filterArgs);
    toggleModel();
    ratingManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    ratingManagment();
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function activeFormatter(cellContent, row) {
    return (
      <>
        {row.status == 1 ? (
          <>
            <button
              className="btn btn-active"
              type="button"
              onClick={() => GetValueOnChange((row.status = false), row._id)}
            >
              Active
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-deactive"
              type="button"
              onClick={() => GetValueOnChange((row.status = true), row._id)}
            >
              Inactive
            </button>
          </>
        )}
      </>
    );
  }

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }
  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Name</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.name}
          onChange={(e) => updateFilter("name", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Code</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.code}
          onChange={(e) => updateFilter("code", e.target.value)}
          name="code"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Int Code</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.intCode}
          onChange={(e) => updateFilter("intCode", e.target.value)}
          name="intCode"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Service Type Code</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.serviceTypeCode}
          onChange={(e) => updateFilter("serviceTypeCode", e.target.value)}
          name="serviceTypeCode"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    // submit button

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  function ImgFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.image.fileName}
          onClick={() => showLightBox(row.image.fileName)}
          alt="img"
          width={"50px"}
        />
      </>
    );
  }

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },

    {
      dataField: "name",
      text: "Name",
      sort: false,
    },

    {
      dataField: "code",
      text: "Code",
      sort: false,
    },
    {
      dataField: "intCode",
      text: "Int Code",
      sort: false,
    },

    {
      dataField: "serviceTypeCode",
      text: "Service Type Code",
      sort: false,
    },

    {
      dataField: "zone.name",
      text: "Zone Details",
      sort: false,
    },

    {
      dataField: "createdAt",
      text: "createdAt",
      sort: true,
      // formatter: createdAtdata,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];
  const handleChange = (event) => {
    setSizePerPage(event.target.value);
    if (currentPage != 1) {
      setCurrentPage(1);
    } else {
      ratingManagment(null, event.target.value);
    }
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    ratingManagment(null, null, true);
  };
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex">
          <button type="button" className=" btn btn-primary text-light">
            {" "}
            <Link
              to={{
                pathname: `/ticketDetails/${row._id}`,
                state: { row },
              }}
            >
              {" "}
              <i className="fal fa-eye text-light"></i>
            </Link>{" "}
          </button>
          <button type="button" className=" btn btn-info  mx-2">
            {" "}
            <Link
              to={{
                pathname: `/ticketdetails/edit/${row._id}`,
                state: { row },
              }}
            >
              {" "}
              <i class="fal fa-edit fs-5 text-light"></i>
            </Link>{" "}
          </button>
          {/*  <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id)}
          >
            <i class="fal fa-trash  fs-5"></i>
          </button> */}
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }
  const exportCSV = async () => {
    setLoader(true);

    const filterArgs = getFilters();
    const payload = {
      sortField: sort,
      sortOrder: sortOrder,
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText) {
        payload.search = searchText;
      }
    }

    try {
      const response = await postRequest(`ticket/export-csv`, payload);
      const data = response.data;
      window.location.href = data.url;
      console.log("data", data);
    } catch (error) {
      console.log("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const ratingManagment = async (filterArgs = [], limit = null, isReload = false) => {
    console.log(currentPage);
    setLoader(true);
    const payload = {
      limit: limit ? limit : sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
    };
    if (!isReload) {
      if (filterArgs?.length) {
        payload.filterArgs = filterArgs;
      } else {
        if (searchText) {
          payload.search = searchText;
        }
      }
    }
    try {
      const response = await postRequest(`ticket/list`, payload);
      const data = response.data;

      console.log(data, "data");
      setTotalPage(data.count);
      setCurrentFPage(data.page);
      setratingdata(data.list);
      // setSearchText("");
    } catch (error) {
      console.log("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`rating/update`, { ratingId: id });
        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          ratingManagment();
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Ticket Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Ticket Management</li>:{" "}
                <li className="breadcrumb-item">Ticket List</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end">
            {canExport && (
              <button
                onClick={() => {
                  exportCSV();
                }}
                type="button"
                className="btn btn-primary mx-2"
              >
                <i class="fas fa-upload"></i> Export CSV
              </button>
            )}
          </div>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={ratingdata}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-12 d-flex mb-3">
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>
                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ width: "7%" }}
                                  >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                  </select>

                                  <label className="mb-0 text-label ms-3">
                                    Sort By:
                                  </label>
                                  <select
                                    value={sort}
                                    onChange={handlesorting}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="name">Name</option>
                                    <option value="code">Code</option>
                                    <option value="intCode">Int Code</option>
                                    <option value="serviceTypeCode">
                                      Service Type Code
                                    </option>
                                    <option value="created_at">
                                      Created at
                                    </option>
                                  </select>
                                  <label className="mb-0 text-label ms-3">
                                    Sort Order:
                                  </label>
                                  <select
                                    value={sortOrder}
                                    onChange={handlesortOrder}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="asc"> Ascending</option>
                                    <option value="desc">Descending</option>
                                  </select>
                                  <div className="filter-item">
                                    <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      style={{ width: "30%" }}
                                    />
                                    <button
                                      onClick={() => {
                                        ratingManagment();
                                      }}
                                      type="button"
                                      className="btn btn-primary mx-2"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="button"
                                      onClick={handleReload}
                                      className="btn btn-danger mx-2"
                                    >
                                      Reset
                                    </button>

                                    <button
                                      type="button"
                                      onClick={toggleModel}
                                      className="btn btn-info"
                                    >
                                      Filter
                                    </button>
                                  </div>
                                </Col>

                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    remote={true}
                                    classes="table-striped"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </React.Fragment>
      {openLightbox && (
        <Lightbox
          mainSrc={image}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default TicketManagement;
