import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequestForm, postRequest } from "../../components/Common/Utils.js";
import HotelModal from "../../components/Modal/HotelModal.js";
import CategoryModal from "../../components/Modal/CategoryModal.js";
import BannerModal from "components/Modal/BannerModal.js";
import TicketModal from "components/Modal/TicketModal.js";
import TransferModal from "components/Modal/TransferModal.js";
import CustomTicketModal from "components/Modal/CustomTicketModal.js";
import CustomTicketlistModal from "components/Modal/CustomTicketlistModal.js";
import FileUpload from "../../components/Common/FileUpload.js";

// import VenueModal from "../../components/Modal/HotelModal.js";

export default function CreateDeal() {
  const location = useLocation();
  const state = location.state?.row;
  const today = new Date().toISOString().slice(0,10)
  const [bannerData, setBannerData] = useState(null);
  const [previewImage, setPreviewImage] = useState("");

  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [model, setModel] = useState(false);
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");
  const [offerImage, setOfferImage] = useState("");
  const [offerModel, setOfferModel] = useState(false);
  const [activityId, setActivityId] = useState("");
  const [activityText, setActivityText] = useState("");
  const [activityImage, setActivityImage] = useState("");
  const [activityModel, setActivityModel] = useState(false);
  const [eventId, setEventId] = useState("");
  const [eventText, setEventText] = useState("");
  const [eventImage, setEventImage] = useState("");
  const [eventModel, setEventModel] = useState(false);
  const [fileUploadLoader, setFileUploadLoader] = useState(false);

  const [selectedImage, setSelectedImage] = useState("");

  const [url, seturl] = useState("");

  let history = useHistory();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const toggleModel = () => {
    console.log("toggleModel", model);
    setModel(!model);
  };

  const closeModal = () => {
    setModel(false);
  };
  const toggleOfferModel = () => {
    console.log("toggleOfferModel", offerModel);
    setOfferModel(!offerModel);
  };
  const closeOfferModal = () => {
    setOfferModel(false);
  };

  const toggleActivityModel = () => {
    console.log("toggleActivityModel", activityModel);
    setActivityModel(!activityModel);
  };
  const closeActivityModal = () => {
    setActivityModel(false);
  };

  const toggleEventModel = () => {
    console.log("toggleEventModel", eventModel);
    setEventModel(!eventModel);
  };
  const closeEventModal = () => {
    setEventModel(false);
  };

  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(id?.name);
    setVenueImage(id?.thumbImage);
    //console.log("id", id);Start Date

    const formInput = { ...bannerData };
    formInput.item = id;
    formInput.subHeading = name;
    formInput.subText = venue?.address;
    formInput.buttonText = "View";
    formInput.logo = venue?.thumbImage;
    formInput.background = venue?.cover;
    setBannerData(formInput);
    // setPreviewLogo(venue?.image);
    //setPreviewImage(venue?.image);
    closeModal();
  };

  const selectOfferRow = (offer) => {
    setVenueId(offer?._id);
    setOfferText(offer?.title);
    setOfferImage(offer?.image);
    const formInput = { ...bannerData };
    formInput.item = offer?._id;

    console.log("offer", offer, formInput);

    if (bannerData.title == "") formInput.title = offer?.title;
    if (bannerData.description == "")
      formInput.description = offer?.description;

    formInput.subHeading = offer?.venue?.name;
    formInput.subText = offer?.venue?.address;
    formInput.buttonText = "View";
    formInput.logo = offer?.venue?.logo;
    formInput.background = offer?.image;

    setBannerData(formInput);
    // setPreviewImage(offer?.image);
    // setPreviewLogo(offer?.venue?.logo);
    closeOfferModal();
  };

  const selectActivityRow = (id, name, activity) => {
    setVenueId(id);
    console.log({ id, name, activity });

    setActivityText(id?.title);
    setActivityImage(id?.images[0] || "");
    console.log("id", id);
    const formInput = { ...bannerData };
    formInput.item = id;
    if (bannerData.title == "") formInput.title = name;
    if (bannerData.description == "") formInput.description = id?.description;
    formInput.subHeading = id?.name;
    formInput.subText = id?.address;
    formInput.buttonText = "View";
    formInput.logo = id?.logo;
    formInput.background = id?.images?.length ? id?.images[0] : "";
    setBannerData(formInput);
    // setPreviewImage(activity?.galleries?.length ? activity?.galleries[0] : "");
    // setPreviewLogo(activity?.provider?.logo);
    closeActivityModal();
  };

  const selectEventRow = (id, name, event) => {
    setVenueId(id);
    setEventText(id?.name);
    setEventImage(id?.coverImage);
    console.log("id", id);
    const formInput = { ...bannerData };
    formInput.item = id;
    if (bannerData.title == "") formInput.title = name;
    if (bannerData.description == "")
      formInput.description = event?.description;
    formInput.subHeading = event?.venue?.name;
    formInput.subText = event?.venue?.address;
    formInput.buttonText = "View";
    formInput.logo = event?.venue?.logo;
    formInput.background = event?.image;
    setBannerData(formInput);
    // setPreviewImage(event?.image);
    // setPreviewLogo(event?.venue?.logo);
    closeEventModal();
  };

  const Createdealform = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("title", bannerData.title);
    formData.append("titleFrench", bannerData.titleFrench);
    formData.append("titleGerman", bannerData.titleGerman);
    formData.append("description", bannerData.description);
    formData.append("descriptionFrench", bannerData.descriptionFrench);
    formData.append("descriptionGerman", bannerData.descriptionGerman);
    formData.append("amount", bannerData.amount);
    formData.append("finalAmount", bannerData.finalAmount);
    formData.append("discountText", bannerData.discountText);
    formData.append("discountTextFrench", bannerData.discountTextFrench);
    formData.append("discountTextGerman", bannerData.discountTextGerman);
    formData.append("startDate", bannerData.startDate);
    formData.append("endDate", bannerData.endDate);
    formData.append("totalPerson", bannerData.totalPerson);

    if (bannerData.type == "transfer") {
      formData.append("originCode", bannerData.originCode);
      formData.append("destinationCode", bannerData.destinationCode);
      formData.append("route", bannerData.route);
    }
    // formData.append("link", bannerData.link);
    formData.append("type", bannerData.type);
    formData.append("selectedId", venueId?._id);
    // formData.append("redirectToPaymentGateway", bannerData.redirectPayment ? 1 : 0);

    formData.append("image", url);

    const response = await postRequestForm("deal/add/", formData);
    console.log({ response });
    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/deals");
      }, 1000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...bannerData };
    list[index] = value;
    setBannerData(list);
  };

  const Imageupload = async (e) => {
    const formData = new FormData();
    setFileUploadLoader(true)
    formData.append("file", e.target.files[0]);
    // formData.append("file", selectedImage);
    const response = await postRequestForm("homepage/upload/image", formData);
    let image = response.data.url;
    setFileUploadLoader(false)
    console.log({ image });
    seturl(image);
  };

  // useEffect(() => {
  //   Imageupload();
  // }, [selectedImage]);

  // const handlerUploadImage = async (e) => {

  //   const formData = new FormData();
  //   const selectedImageForm = new FormData();
  //   setStartLoader(true);
  //   selectedImageForm.append("image", e.target.files[0]);
  //   const selectedImageRes = await postRequestForm(
  //     "comman/img/upload",
  //     selectedImageForm
  //   ).then((res) => {
  //     let data = res.data
  //     console.log({ data })
  //     if (data?.url) {
  //       let url = data?.url
  //       console.log({ url })
  //       setPreviewCover((preview) => [...preview, url])
  //       setStartLoader(false);
  //     }
  //   });
  // }
  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row d-flex flex-row">
        <div className="col-md-10">
          <div className="page-title-box">
            <h4>Deal Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Deal Management</li>:{" "}
              <li className="breadcrumb-item">Create Deal</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title"> Create Deal</h4>

              <Form onSubmit={Createdealform}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Title(English)
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            maxLength={120}
                            onChange={(e) =>
                              updateValue("title", e.target.value)
                            }
                            value={bannerData?.title}
                            name="title"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Title(French)
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            maxLength={120}
                            onChange={(e) =>
                              updateValue("titleFrench", e.target.value)
                            }
                            value={bannerData?.titleFrench}
                            name="titleFrench"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Title(German)
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            maxLength={120}
                            onChange={(e) =>
                              updateValue("titleGerman", e.target.value)
                            }
                            value={bannerData?.titleGerman}
                            name="titleGerman"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Description (English)
                        </label>
                        <div className="col-sm-6">
                          <textarea
                            type="text"
                            maxLength={250}
                            rows="3"
                            onChange={(e) =>
                              updateValue("description", e.target.value)
                            }
                            value={bannerData?.description}
                            name="description"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Description (French)
                        </label>
                        <div className="col-sm-6">
                          <textarea
                            type="text"
                            maxLength={250}
                            rows="3"
                            onChange={(e) =>
                              updateValue("descriptionFrench", e.target.value)
                            }
                            value={bannerData?.descriptionFrench}
                            name="descriptionFrench"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Description (German)
                        </label>
                        <div className="col-sm-6">
                          <textarea
                            type="text"
                            maxLength={250}
                            rows="3"
                            onChange={(e) =>
                              updateValue("descriptionGerman", e.target.value)
                            }
                            value={bannerData?.descriptionGerman}
                            name="descriptionGerman"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          File
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="file"
                            onChange={(e) =>
                              Imageupload(e)
                            }
                            name="mediaUrl"
                            className="form-control"
                            id="horizontal-password-input"
                            required="required"
                          />
                          <br />
                          {bannerData?.mediaType == "video"
                            ? null
                            : url && (
                              <img
                                src={url}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Type</label>

                        <div className="col-sm-6">
                          <select
                            className="form-control"
                            onChange={(e) =>
                              updateValue("type", e.target.value)
                            }
                            name="type"
                            class="form-select"
                          >
                            <option value="">Select</option>

                            <option value="ticket">Ticket</option>

                            {/* <option value="hotel">Hotel</option> */}
                            <option value="transfer">Transfer</option>
                          </select>
                        </div>
                      </div>

                      {bannerData?.type == "ticket" && (
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Ticket
                          </label>
                          <div
                            className="col-sm-6"
                            onClick={() => toggleActivityModel()}
                          >
                            {activityText ? (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#7a6fbe",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleActivityModel()}
                                >
                                  Choose Ticket
                                </button>
                                {/* <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div> */}
                                {/* <img
                                    src={activityImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img> */}
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={activityText}
                                  id="selectedVenue"
                                  placeholder="No Ticket chosen"
                                />
                              </div>
                            ) : (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#7a6fbe",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleActivityModel()}
                                >
                                  Choose Ticket
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No Ticket chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {/* {bannerData?.type == "hotel" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Hotel
                            </label>

                            <div
                              className="col-sm-6"
                              onClick={() => toggleModel()}
                            >
                              {venueText ? (
                               
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#7a6fbe",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Hotel
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={venueImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={venueText}
                                    id="selectedOffer"
                                    placeholder="No Hotel chosen"
                                  />
                                 
                                </div>
                              ) : (
                               
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#7a6fbe",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Hotel
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={venueText}
                                    id="selectedOffer"
                                    placeholder="No Hotel chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )} */}
                      {bannerData?.type == "transfer" && (
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Transfer
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => toggleEventModel()}
                          >
                            {eventText ? (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",

                                    background: "#7a6fbe",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseOfferBtn"
                                  onclick={() => toggleEventModel()}
                                >
                                  Choose Transfer
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={eventImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={eventText}
                                  id="selectedOffer"
                                  placeholder="No Transfer chosen"
                                />
                              </div>
                            ) : (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",

                                    background: "#7a6fbe",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseOfferBtn"
                                  onclick={() => toggleEventModel()}
                                >
                                  Choose Transfer
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  value={eventText}
                                  id="selectedOffer"
                                  placeholder="No Transfer chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {bannerData?.type == "transfer" && (
                        <>
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Origin Code
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                onChange={(e) =>
                                  updateValue("originCode", e.target.value)
                                }
                                value={bannerData?.originCode}
                                name="amount"
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Destination Code
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                onChange={(e) =>
                                  updateValue("destinationCode", e.target.value)
                                }
                                value={bannerData?.destinationCode}
                                name="amount"
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Route Type
                            </label>
                            <div className="col-sm-6">
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  updateValue("route", e.target.value)
                                }
                                name="routeType"
                                class="form-select"
                              >
                                <option value="">One Way</option>
                                <option value="InOut">Round Trip</option>
                              </select>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          No. Of Person
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="number"
                            onChange={(e) =>
                              updateValue("totalPerson", e.target.value)
                            }
                            value={bannerData?.totalPerson}
                            name="amount"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Amount
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="number"
                            min={0}
                            onChange={(e) =>
                              updateValue("amount", e.target.value)
                            }
                            value={bannerData?.amount}
                            name="amount"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Final Amount
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="number"
                            min={0}
                            onChange={(e) =>
                              updateValue("finalAmount", e.target.value)
                            }
                            value={bannerData?.finalAmount}
                            name="finalAmount"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Discount Text (English)
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            maxLength={75}
                            onChange={(e) =>
                              updateValue("discountText", e.target.value)
                            }
                            value={bannerData?.discountText}
                            name="discountText"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Discount Text (French)
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            maxLength={75}
                            onChange={(e) =>
                              updateValue("discountTextFrench", e.target.value)
                            }
                            value={bannerData?.discountTextFrench}
                            name="discountTextFrench"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Discount Text (German)
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            maxLength={75}
                            onChange={(e) =>
                              updateValue("discountTextGerman", e.target.value)
                            }
                            value={bannerData?.discountTextGerman}
                            name="discountTextGerman"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Start Date
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="date"
                            min={today}
                            onChange={(e) =>
                              updateValue("startDate", e.target.value)
                            }
                            value={bannerData?.startDate}
                            name="startDate"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          End Date
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="date"
                            min={bannerData?.startDate||today}
                            onChange={(e) =>
                              updateValue("endDate", e.target.value)
                            }
                            value={bannerData?.endDate}
                            name="endDate"
                            className="form-control"
                          />
                        </div>
                      </div>



                      <div className="mt-5 mt-lg-4">
                        <div className="row justify-content-end">
                          <div className="col-sm-9">
                            <div>
                              <button
                                type="submit"
                                className="btn btn-primary w-md"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* modal */}
      <HotelModal show={model} closeModal={closeModal} selectRow={selectRow} />
      <CategoryModal
        show={offerModel}
        closeModal={closeOfferModal}
        selectRow={selectOfferRow}
      />
      {/* <TicketModal
        show={activityModel}
        closeModal={closeActivityModal}
        selectRow={selectActivityRow}
      /> */}




      <CustomTicketlistModal
        show={activityModel}
        closeModal={closeActivityModal}
        selectRow={selectActivityRow}
      />
      <TransferModal
        show={eventModel}
        closeEventModal={closeEventModal}
        selectRow={selectEventRow}
      />
      <FileUpload message="File Uploading" status={fileUploadLoader} />
    </React.Fragment>
  );
}
