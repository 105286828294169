import React, { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Card,
  CardBody, 
  FormGroup,
  Button, Label, Input,
  Form, 
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {  
  postRequest,
} from "../../components/Common/Utils.js";

import { Bar, Line } from 'react-chartjs-2';
import CustomDateModal from "../../components/Modal/CustomDateModal";

const defaultChartData = {
  labels: [],
  datasets: [{
    label: 'User Registrations',
    data: [],
    backgroundColor: 'rgba(75, 192, 192, 0.2)',
    borderColor: 'rgba(75, 192, 192, 1)',
    borderWidth: 1,
    tension: 0.5,
    fill: false,
  }]
}

const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};


export default function ChartComponets() {
  const [chartData, setChartData] = useState(defaultChartData);
  const [previewCover, setPreviewCover] = useState("");
  const [previewLogo, setPreviewLogo] = useState("");
  const [loading, setLoading] = useState(false);
  const [componentType, setComponentType] = useState("user");
  const [period, setPeriod] = useState("current-month");
  const [chartType, setChartType] = useState("bar");

  const [lastPeriod, setLastPeriod] = useState("current-month");

  const [showModel, setShowModel] = useState(false);

  const [inputData, setInputData] = useState({
    componentType: "user",
    period: "current-month",
    chartType: "bar",
    interval : "day",
    dateStart : "",
    dateEnd : "",
  })

  const [selectedLogo, setSelectedLogo] = useState("");
  const [selectedCover, setSelectedCover] = useState("");
  const [selectedLogourl, setSelectedLogourl] = useState("");
  const [selectedCoverurl, setSelectedCoverurl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const loadChartData = useCallback(async ( payload ) => {
    //return false;

    setIsLoading(true);
   
    const response = await postRequest("admin/chart-data", payload);

    const labelsData = response.data.labels;
    const datasetsData = response.data.datasets;
    const labelMessage = response.data.message;

    const tempChartData = {...chartData}
    tempChartData.labels = labelsData;
    tempChartData.datasets[0].data = datasetsData;
    tempChartData.datasets[0].label = labelMessage;
    setChartData(tempChartData);
    setIsLoading(false); 
  }, []);


  useEffect(() => {
    if (!selectedLogo) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewLogo(reader.result);
    };
    reader.readAsDataURL(selectedLogo);
  }, [selectedLogo]);

  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

  useEffect(() => {
    loadChartData(inputData);
  }, [loadChartData]);


  const reloadChart = () => {

    let reloadEp = false;

    if(inputData.componentType !== componentType ) {
      setComponentType(inputData.componentType);
      reloadEp = true;
    }

    if (inputData.period !== period) {
      setPeriod(inputData.period);
      reloadEp = true;
    }

    if(reloadEp) {
      loadChartData(inputData);
    }
    if (inputData.chartType !== chartType) {
      // If only the chartType is updated, update the state without API call
      setChartType(inputData.chartType);
    }

    if(inputData.period == 'custom-date'){
      loadChartData(inputData);
    }
  }

  const updateInput = (target, value) => {
    setInputData({
      ...inputData,
      [target]: value
    })   

    if(value === 'custom-date') {
      setLastPeriod(inputData.period);
      setShowModel(true);
    }
  }

  const closeModal = () => {
    setShowModel(false);
    updateInput('period', lastPeriod);
  }

  const selectDate = () => {
    setShowModel(false);    
    console.log(inputData);
  }


  return (
    <React.Fragment>
      <ToastContainer />

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>                
                <Form>
                <Row>
                  <Col md={1}>
                    
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="component-type">Data Type</Label>
                      <Input 
                        type="select" 
                        id="component-type"
                        value={inputData.componentType}
                        onChange={e => updateInput('componentType', e.target.value)}
                      >
                        <option value="user">User</option>
                        <option value="hotel">Hotel</option>
                        <option value="transfer">Transfer</option>
                        <option value="ticket">Ticket</option>
                        <option value="deals">Deals</option>
                        <option value="hotel-booking">Hotel Booking</option>
                        <option value="transfer-booking">Transfer Booking</option>
                        <option value="ticket-booking">Ticket Booking</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="period">Period</Label>
                      <Input 
                        type="select" 
                        id="period"
                        value={inputData.period}
                        onChange={e => updateInput('period', e.target.value)}
                      >
                        <option value="current-month">Current Month</option>
                        <option value="current-year">Current Year</option>
                        <option value="last-30-days">Last 30 Days</option>
                        <option value="last-12-month">Last 12 Months</option>
                        <option value="previous-month">Previous Month</option>
                        <option value="previous-year">Previous Year</option>                      
                        <option value="custom-date">Custom Date</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="chart-type">Chart Type</Label>
                      <Input 
                        type="select" 
                        id="chart-type"
                        value={inputData.chartType}
                        onChange={e => updateInput('chartType', e.target.value)}
                      >
                        <option value="bar">Bar</option>
                        <option value="line">Line</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={1}>
                    <Button type="button" color="primary" style={{marginTop: "24px"}} onClick={reloadChart} block>Submit</Button>
                  </Col>
                </Row>               
              </Form>               
            </CardBody>
            )}
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', margin: '20px' }}>
            <CardBody>

              {isLoading ? (<center><img className="text-center" src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" alt="Loading..." /></center>) : (
                <>
                { chartType === 'line' ?  (
                  <Line
                    data={chartData}
                    options={options}
                  />
                ) : (<Bar
                  data={chartData}
                  options={options}
                />) }
                
                </>
              )}
             

              
            </CardBody>
          </Card>
        </Col>
      </Row>

      <CustomDateModal  show={showModel}
        closeModal={closeModal}
        onSubmit={selectDate}
        inputData={inputData}
        updateValue = {updateInput}/>
    </React.Fragment>
  );
}
