import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
import moment from "moment";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import SearchblockDragModal from "./SearchblockDragModal";

//import filter modal
import FilterModal from "../../components/Modal/FilterModal";

import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequestForm,
  putRequest,
  postRequestForm,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import DragDropModal from "components/Modal/DragDropModal";

const filterObj = {
  title: "",
  type: "",
  createdAt: "",
};

const SearchBlockManagementlist = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [searchBlockData, setSearchBlockData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showOrder, setShowOrder] = useState(false);

  const [value, setValue] = React.useState(10);

  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");

  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [sizeList, setSizeList] = useState([]);
  const [model, setModel] = useState(false);
  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canExport, setCanExport] = useState(true);
  const [filter, setFilter] = useState({ ...filterObj });

  const [loader2, setLoader2] = useState(false);

  useEffect(() => {
    searchBlockManagment();
  }, [currentPage]);

  useEffect(() => {
    searchBlockManagment();
  }, [sort, sortOrder]);

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value == 1 ? 1 : 0;

    const formData = new FormData();
    formData.append("searchBlockId", actId);
    formData.append("enable", status);

    const res = await putRequestForm("homepage/search-block", formData);

    if (res.status == 1) {
      toast.success("Status updated Successfully");
      searchBlockManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };
  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    console.log("toggleModel", model);
    setModel(!model);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
        console.log("page", page);
      }
    }

    console.log("type", type);

    if (type === "search") {
      console.log("searchText", searchText);
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const applyFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key],
        };

        if (key === "title") {
          filterObject.type = "regex";
        } else if (key === "type") {
          filterObject.type = "eq";
        } else if (key === "sizeId") {
          filterObject.type = "obj-eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });

    console.log("filterArgs", filterArgs);
    toggleModel();
    searchBlockManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    searchBlockManagment();
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }
  function createdAtdata(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <p>{moment(row.createdAt).format("DD-MM-YYYY")} </p>
        </div>
      </React.Fragment>
    );
  }

  function activeFormatter(cellContent, row) {
    return (
      <>
        {row.enable == true ? (
          <>
            <button
              className="btn btn-active"
              type="button"
              onClick={() => GetValueOnChange((row.enable = false), row._id)}
            >
              Active
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-deactive"
              type="button"
              onClick={() => GetValueOnChange((row.enable = true), row._id)}
            >
              Inactive
            </button>
          </>
        )}
      </>
    );
  }
  function MediaFormatter(cellContent, row) {
    return (
      <>
        <video width="50" height="50" controls>
          <source src={row.videoUrl} type="video/mp4" />
        </video>
      </>
    );
  }

  function SizeFormatter(cellContent, row) {
    return (
      <>
        <p>
          {" "}
          {row?.size ? row?.size?.type + " - " + row?.size?.ratio : "N.A"}{" "}
        </p>
      </>
    );
  }

  function ImgLogoFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.logo}
          onClick={() => showLightBox(row.logo)}
          alt="logo img"
          width={"50px"}
        />
      </>
    );
  }

  function ImgCoverFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.cover}
          onClick={() => showLightBox(row.cover)}
          alt="cover img"
          width={"50px"}
        />
      </>
    );
  }

  function VenueFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.venues?.length}</p>
      </div>
    );
  }
  function OfferFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.offers?.length}</p>
      </div>
    );
  }
  function SliderFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.sliders?.length}</p>
      </div>
    );
  }
  function VideoFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.videos?.length}</p>
      </div>
    );
  }
  function CCFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.customComponents?.length}</p>
      </div>
    );
  }
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Title</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.title}
          onChange={(e) => updateFilter("title", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,

    // <div className="row mb-4" key="title">
    //   <label className="col-sm-3 col-form-label">Type</label>
    //   <div className="col-sm-6">
    //     <select
    //       onChange={(e) => updateFilter("type", e.target.value)}
    //       className="form-select"
    //       value={filter.type}
    //     >
    //       <option value="">Select Type</option>
    //       <option value="transfer">Transfer</option>
    //       <option value="hotel">Hotel</option>
    //       <option value="banner">Banner</option>
    //       <option value="category">Category</option>
    //       <option value="activities_and_events">Activity & Event</option>
    //       <option value="inspired_by_elevate">Inspired By Elevate</option>
    //       <option value="my_itinerary">My Itinerary</option>
    //       <option value="custom_components">Custom Components</option>
    //     </select>
    //   </div>
    // </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    // submit button

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },

    {
      dataField: "title",
      text: "Title",
      sort: false,
    },

    {
      dataField: "titleFrench",
      text: "Title (French)",
      sort: true,
    },
    {
      dataField: "titleGerman",
      text: "Title (German)",
      sort: true,
    },

    {
      dataField: "description",
      text: "Description",
      sort: false,
    },
    {
      dataField: "descriptionFrench",
      text: "Description (French)",
      sort: true,
    },
    {
      dataField: "descriptionGerman",
      text: "Description (German)",
      sort: true,
    },
    {
      dataField: "type",
      text: "Type",
      sort: false,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: activeFormatter,
      sort: false,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      formatter: createdAtdata,
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];
  const handleChange = (event) => {
    setSizePerPage(event.target.value);
    if (currentPage != 1) {
      setCurrentPage(1);
    } else {
      searchBlockManagment(null, event.target.value);
    }
  };
  const getFilters = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key],
        };

        if (key === "title") {
          filterObject.type = "regex";
        } else if (key === "homeBlockType") {
          filterObject.type = "eq";
        } else if (key === "sizeId") {
          filterObject.type = "obj-eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });
    return filterArgs;
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    setSearchText("");
    searchBlockManagment(null, null, true);
  };
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex">
          {/* <button type="button" className=" btn btn-primary text-light mx-2">
            {" "}
            <Link
              to={{
                pathname: `/homeblockdetails/${row._id}`,
                state: { row },
              }}
            >
              {" "}
              <i className="fal fa-eye text-light"></i>
            </Link>{" "}
          </button>        */}
          <button type="button" className=" btn btn-info  mx-2">
            {" "}
            <Link
              to={{
                pathname: `/search-block/update/${row._id}`,
                state: { row },
              }}
            >
              {" "}
              <i class="fal fa-pencil fs-5 text-light"></i>
            </Link>{" "}
          </button>
          {canDelete && (
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => handlerdelete(row._id)}
            >
              <i class="fal fa-trash  fs-5"></i>
            </button>
          )}
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const limit = 10;

  const exportCSV = async () => {
    setLoader(true);

    const filterArgs = getFilters();
    const payload = {
      sortField: sort,
      sortOrder: sortOrder,
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText) {
        payload.search = searchText;
      }
    }

    try {
      const response = await postRequest(
        `homepage/search-block/export-csv`,
        payload
      );
      const data = response;
      window.location.href = data.url;
      console.log("data", data);
    } catch (error) {
      console.log("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const searchBlockManagment = async (filterArgs = [], limit = null, isReload = false) => {
    console.log(currentPage);
    setLoader(true);
    const payload = {
      limit: limit ? limit : sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
    };
    if (!isReload) {
      if (filterArgs?.length) {
        payload.filterArgs = filterArgs;
      } else {
        if (searchText) {
          payload.search = searchText;
        }
      }
    }
    try {
      const response = await postRequest(`homepage/search-block/list`, payload);
      const data = response;

      console.log("searchBlockManagment", data);

      setTotalPage(data?.count ? data?.count : 0);
      setCurrentFPage(data?.page ? data?.page : 1);
      setSearchBlockData(data?.searchBlocks ? data?.searchBlocks : []);
    } catch (error) {
      console.log("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`homepage/search-block/`, {
          searchBlockId: id,
        });
        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          searchBlockManagment();
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };

  const searchBlockDataOrder = async (data) => {
    setSearchBlockData(data);
    setShowOrder(false);

    const response = await postRequest(`homepage/search-block/order`, {
      data: data,
    });
    console.log("data-response", response);
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <DndProvider backend={HTML5Backend}>
          <Row>
            <Col className="12">
              <div className="page-title-box">
                <h4>Search Block Management</h4>

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Search Block Management
                  </li>
                  : <li className="breadcrumb-item">Search Block List</li>
                </ol>
              </div>
            </Col>
            <div className="col-md-4 text-end">
              <Button
                onClick={() => setShowOrder(true)}
                className="btn btn-info"
                style={{ marginRight: "10px" }}
              >
                <i className="fas fa-grip-vertical"></i> Order
              </Button>
              {canExport && (
                <button
                  onClick={() => {
                    exportCSV();
                  }}
                  type="button"
                  className="btn btn-primary mx-2"
                >
                  <i class="fas fa-upload"></i> Export CSV
                </button>
              )}
              {canCreate && (
                <Link to="/search-block/create">
                  {" "}
                  <Button>
                    {" "}
                    <i class="fas fa-plus"></i> Create
                  </Button>{" "}
                </Link>
              )}
              &nbsp;&nbsp;&nbsp;
            </div>
            <Col className="col-12">
              <Card>
                {loader ? (
                  <CardBody style={{ height: "100px" }}>
                    <Spinner
                      color="info"
                      style={{
                        height: "4rem",
                        width: "4rem",
                        position: "absolute",
                        left: "50%",
                      }}
                    >
                      Loading...
                    </Spinner>
                  </CardBody>
                ) : (
                  <CardBody className="table-shorting">
                    {
                      <PaginationProvider
                        pagination={paginationFactory({
                          page: currentFPage,
                          sizePerPage: sizePerPageOrg,
                          totalSize: totalPage,
                          custom: true,
                        })}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <>
                            <ToolkitProvider
                              keyField="_id"
                              data={searchBlockData}
                              columns={columns}
                            >
                              {(props) => (
                                <Row>
                                  <Col className='col-lg-12 d-flex mb-3'>

                                    <label className='mb-0 text-label'>Page Size</label>

                                    <select value={sizePerPageOrg} onChange={handleChange} className="form-select" style={{ width: "7%" }}>

                                      <option value="10">10</option>

                                      <option value="20">20</option>

                                      <option value="50">50</option>

                                    </select>


                                    <label className='mb-0 text-label ms-3'>Sort By:</label>
                                    <select value={sort} onChange={handlesorting} className="form-select" style={{ width: "10%" }}>
                                      <option value="title"> Title</option>
                                      <option value="type"> Type</option>
                                      <option value="createdAt">Created At</option>


                                    </select>
                                    <label className="mb-0 text-label ms-3">
                                      Sort Order:
                                    </label>
                                    <select
                                      value={sortOrder}
                                      onChange={handlesortOrder}
                                      className="form-select"
                                      style={{ width: "10%" }}
                                    >
                                      <option value="asc"> Ascending</option>
                                      <option value="desc">Descending</option>

                                    </select>
                                    <div className='filter-item'>
                                      <input type="search" placeholder='Search' value={searchText} class="form-control" onChange={(e) => setSearchText(e.target.value)} style={{ width: "30%" }} />
                                      <button onClick={() => { searchBlockManagment() }} type='button' className='btn btn-primary mx-2'>Search</button>
                                      <button type='button' onClick={handleReload} className='btn btn-danger'>Reset</button>
                                      <button
                                        type="button"
                                        onClick={toggleModel}
                                        className="btn btn-info mx-2"
                                      >
                                        Filter
                                      </button>
                                    </div>
                                  </Col>

                                  <Col className="col-lg-12 text-end">
                                    <BootstrapTable
                                      onTableChange={handleTableChange}
                                      remote={true}
                                      classes="table-striped"
                                      {...props.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </Col>
                                </Row>
                              )}
                            </ToolkitProvider>

                            <Row>
                              <Col className="col-lg-12 text-end">
                                <div className="text-md-right">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </PaginationProvider>
                    }
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </DndProvider>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
        {/* <SearchblockDragModal
          show={showOrder}
          closeModal={() => setShowOrder(false)}
          data={searchBlockData}
          setData={searchBlockData}
        /> */}
        <DragDropModal
          show={showOrder}
          closeModal={() => setShowOrder(false)}
          data={searchBlockData}
          setData={searchBlockDataOrder}
        />
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default SearchBlockManagementlist;
