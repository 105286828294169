import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import BannerDragModal from "./BannerDragModal";

import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequestForm,
  putRequest,
  postRequestForm,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";

import moment from "moment";
import { ca } from "date-fns/locale";
import DragDropModal from "components/Modal/DragDropModal";

const type = 'ROW';

const filterObj = {
  
};


const BannerManagementlist = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [bannerdata, setbannerdata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [loader2, setLoader2] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [canCreate, setCanCreate] = useState(true);
  const [canEdit, setCanEdit] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canExport, setCanExport] = useState(true);
  const [filter, setFilter] = useState({ ...filterObj });

  useEffect(() => {
    bannerManagment();
  }, [currentPage]);
  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
        console.log("page", page);
      }
    }

    console.log("type", type);

    if (type === "search") {
      console.log("searchText", searchText);
    }
  };

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value == 1 ? 1 : 0;

    const formData = new FormData();
    formData.append("bannerId", actId);
    formData.append("status", status);

    const res = await putRequestForm("homepage/banner/", formData);

    if (res.status == 1) {
      toast.success("Banner Status updated Successfully");
      bannerManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };
  const getFilters = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
       
          var filterObject = {
            [key]: filter[key],
          };  
       

        if (key === "title" ) {
          filterObject.type = "regex";
        }else if (key === "homeBlockType") {
          filterObject.type = "eq";
        }         
         else if (key === "sizeId") {
          filterObject.type = "obj-eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });
    return filterArgs;    
  };

  function activeFormatter(cellContent, row) {
    return (
      <>
        {row.status == 1 ? (
          <>
            <button
              className="btn btn-active"
              type="button"
              onClick={() => GetValueOnChange((row.status = false), row._id)}
            >
              Active
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-deactive"
              type="button"
              onClick={() => GetValueOnChange((row.status = true), row._id)}
            >
              Inactive
            </button>
          </>
        )}
      </>
    );
  }
  function MediaFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.image}
          onClick={() => showLightBox(row.image)}
          alt="Image"
          width={"50px"}
        />
      </>
    );
  }

  function selectedFormatter(cellContent, row) {
    return (
      <div style={{ display: 'flex',  gap: '10px' }}>
        <img
          src={row.selectedImage}
          onClick={() => showLightBox(row.selectedImage)}
          alt="Image"
          width={"50px"}
          style={{ borderRadius: '20%' }} // This makes the image round
        />
        <p>{row.selectedName}</p>
      </div>
    );
}

function redirectToPaymentGatewayFormatter(cellContent, row) {
  return (
    <>
      {row.redirectToPaymentGateway == 1 ? (
        <>

          <button

            className="btn btn-active"
            type="button"
            
          >
            Yes
          </button>
        </>
      ) : (
        <>

          <button
            className="btn btn-deactive"
            type="button"
           
          >
           No
          </button>
        </>
      )}
    </>
  );
}

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }
  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "title",
      text: "Title (English)",
      sort: false,
    },
    {
      dataField: "titleFrench",
      text: "Title (French)",
      sort: false,
    },
    {
      dataField: "titleGerman",
      text: "Title (German)",
      sort: false,
    },

    // {
    //   dataField: "link",
    //   text: "Link",
    //   sort: false,
    // },
    {
      dataField: "image",
      text: "Image",
      formatter: MediaFormatter,
      sort: false,
    },


    {
      dataField: "bannerType",
      text: "Type",
      sort: false,
    },

    {
      dataField: "selectedName",
      formatter: selectedFormatter,
      text: "Selected Item",
      sort: false,
    },

    {
      dataField: "redirectToPaymentGateway",
      text: "Redirect To Payment Gateway",    
      formatter: redirectToPaymentGatewayFormatter,
      sort: false,
    },


    {
      dataField: "status",
      text: "Status",
      formatter: activeFormatter,
      sort: false,
    },

    {
      dataField: "createdAt",
      text: "Created At",
      formatter: dateFormatter,
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];

  function dateFormatter(cellContent, row) {
    return (
      <React.Fragment>
     {moment(row.createdAt).format("MMM Do YY")}
      </React.Fragment>
    );
  }
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div  className="d-flex">
          { canEdit && (
          <button type="button" className=" btn btn-info  mx-2">            
            <Link
              to={{
                pathname: `/updateBannerType/${row._id}`,
                state: { row },
              }}
            >              
              <i className="fal fa-pencil fs-5 text-light"></i>
            </Link>
          </button>
          )}
          { canDelete && (
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id)}
          >
            <i className="fal fa-trash  fs-5"></i>
          </button>
          )}
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const limit = 10;
  const exportCSV = async () => {   
    setLoader(true);

    const filterArgs = getFilters();
    const payload = {     
     
    };

    // if (filterArgs?.length) {
    //   payload.filterArgs = filterArgs;
    // } else {
    //   if (searchText) {
    //     payload.search = searchText;
    //   }
    // }
    
    try {
    const response = await postRequest(`homepage/banner/export-csv`, payload);
    const data = response;
      window.location.href = data.url;
      console.log("data", data);
    }catch(error){
      console.log("Failed to fetch data:", error);
    }finally{
      setLoader(false);
    }


  }
  const bannerManagment = async (page = 0) => {
    setLoader(true);
    try{
    const response = await postRequest(`homepage/banner/list`, { limit: 1000000});
    const data = response;
    setTotalPage(data.count);
    setCurrentPage(data.page);
    setbannerdata(data.list);
    }catch(error){
      console.log("Failed to fetch data:", error)
    }finally{
    setLoader(false);
    }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`homepage/banner`, {
          bannerId: id,
        });
        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          bannerManagment();
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };  
  
  const updateBannerData = async (data) => {
    setbannerdata(data);
    setShowOrder(false);

    const response = await postRequest(`homepage/banner/order`, { data: data});
    console.log("data-response", response);
  }



  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <DndProvider backend={HTML5Backend}>
          <Row>
            <Col className="12">
              <div className="page-title-box">
                <h4>Banner Management</h4>

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Banner Management</li>:
                  <li className="breadcrumb-item">Banner List</li>
                </ol>
              </div>
            </Col>
            <div className="col-md-4 text-end">
               
                <Button onClick={ () => setShowOrder(true)  } className="btn btn-info" style={{ marginRight: '10px' }}> 
                  <i className="fas fa-grip-vertical" ></i> Order
                </Button>
                { canExport && (
                <button onClick={ () => { exportCSV() }  } type='button' className='btn btn-primary mx-2'><i class="fas fa-upload"></i> Export CSV</button>
                )}
              {canCreate && (
              <Link to="/createBanner">
                <Button>
                  <i className="fas fa-plus"></i> Create
                </Button>
              </Link>
              )}
              &nbsp;&nbsp;&nbsp;


            </div>


            <Col className="col-12">
              <Card>
                {loader ? (
                  <CardBody style={{ height: "100px" }}>
                    <Spinner
                      color="info"
                      style={{
                        height: "4rem",
                        width: "4rem",
                        position: "absolute",
                        left: "50%",
                      }}
                    >
                      Loading...
                    </Spinner>
                  </CardBody>
                ) : (
                  <CardBody className="table-shorting">
                    {
                      <PaginationProvider
                        pagination={paginationFactory({
                          page: currentPage,
                          sizePerPage: sizePerPageOrg,
                          totalSize: bannerdata?.length,
                          custom: true,
                        })}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <>
                            <ToolkitProvider
                              keyField="_id"
                              data={bannerdata}
                              columns={columns}
                            >
                              {(props) => (
                                <Row>
                                  <Col className="col-lg-12 text-end">
                                    <BootstrapTable
                                       rowRenderer={(row, rowIndex) => (
                                        <DraggableRow index={rowIndex} moveRow={moveRow}>
                                        {row}
                                  </DraggableRow>
                                      )}
                                      // onTableChange={handleTableChange}
                                      remote={false}
                                      classes="table-striped"
                                      {...props.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </Col>
                                </Row>
                              )}
                            </ToolkitProvider>

                            <Row>
                              <Col className="col-lg-12 text-end">
                                <div className="text-md-right">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </PaginationProvider>
                    }
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </DndProvider>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}

        <BannerDragModal  show={showOrder} closeModal={() => setShowOrder(false)} data={bannerdata} setData={updateBannerData} />
        {/* DragDropModal */}
{/* <DragDropModal show={showOrder} closeModal={() => setShowOrder(false)} data={bannerdata} setData={updateBannerData}/> */}
      </React.Fragment>
    </>
  );
};

export default BannerManagementlist;
