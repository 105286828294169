import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
// import rButton from "reactstrap/button";

import {
  deleteRequest,
  putRequest,
  postRequest,
  putRequestForm,
  RBButton,
  deleteConfirmationWords,
} from "components/Common/Utils";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { format } from "date-fns";
// import StarComponent from "./StarComponent";

import Lightbox from "react-image-lightbox";

import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Avatar,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import LocationPicker from "react-location-picker";
// import DayTimePicker from "./DayTimePicker";

import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import "./CustomCard.css";
// import RoomListTable from "./RoomTable";
import TicketRatingTable from "./TicketRatingTable";
import TicketBookingTable from "./TicketBookingTable";
// import VenueSpecialOfferListTable from "./VenueSpecialOfferList";
// import VenueClaimsListTable from "./VenueClaimsTable";
// import VenueNotificationListTable from "./VenueNotificationTable";
// import VenueReviewListTable from "./VenueReviewTable";
// import VenueFollowListTable from "./VenueFollowingTable";
// import VenueCheckinTable from "./VenueCheckinTable";
// import VenueActiveStoryListTable from "./VenueActiveStoryTable";
// // import VenueStoryListTable from "./VenueStoryTable";
// import VenueAdListTable from "./VenueAdsTable";

const defaultPosition = {
  lat: 33.6706,
  lng: 36.0227,
};

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default function Ticketdetails({ venueId, venueName = "" }) {

  const location = useLocation();
  const state = location.state?.row;
  const [venueData, setData] = useState(state);

  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [loader, setLoader] = useState(false);
  const [image, setImage] = React.useState("");
  const [images, setImages] = React.useState([]);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightboxGallery, setOpenLightBoxGallery] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedReview, setSelectedReview] = React.useState(null);

  const [textReply, setTextReply] = React.useState("");

  const handleMusicSelectionChange = (newSelectedMusic) => {
    const oldMusic = [...selectedMusic];
    if (newSelectedMusic.length > oldMusic.length) {
      const addedMusic = newSelectedMusic.filter(
        (newItem) => !oldMusic.some((oldItem) => oldItem._id === newItem._id)
      );
      if (addedMusic.length > 0) {
        setSelectedMusic(newSelectedMusic);
        handlerToggle("venue/music/toggle", {
          venueId: id,
          musicId: addedMusic[0]._id,
        });
      }
    } else if (newSelectedMusic.length < oldMusic.length) {
      const removedMusic = oldMusic.filter(
        (oldItem) =>
          !newSelectedMusic.some((newItem) => newItem._id === oldItem._id)
      );
      if (removedMusic.length > 0) {
        setSelectedMusic(newSelectedMusic);
        handlerToggle("venue/music/toggle", {
          venueId: id,
          musicId: removedMusic[0]._id,
        });
      }
    }
  };

  const handleThemeSelectionChange = (newSelectedTheme) => {
    const oldTheme = [...selectedTheme];
    if (newSelectedTheme.length > oldTheme.length) {
      const addedTheme = newSelectedTheme.filter(
        (newItem) => !oldTheme.some((oldItem) => oldItem._id === newItem._id)
      );
      if (addedTheme.length > 0) {
        setSelectedTheme(newSelectedTheme);
        handlerToggle("venue/theme/toggle", {
          venueId: id,
          themeId: addedTheme[0]._id,
        });
      }
    } else if (newSelectedTheme.length < oldTheme.length) {
      const removedTheme = oldTheme.filter(
        (oldItem) =>
          !newSelectedTheme.some((newItem) => newItem._id === oldItem._id)
      );
      if (removedTheme.length > 0) {
        setSelectedTheme(newSelectedTheme);
        handlerToggle("venue/theme/toggle", {
          venueId: id,
          themeId: removedTheme[0]._id,
        });
      }
    }
  };

  const handleCuisineSelectionChange = (newSelectedCuisine) => {
    const oldCuisine = [...selectedCuisine];
    if (newSelectedCuisine.length > oldCuisine.length) {
      const addedCuisine = newSelectedCuisine.filter(
        (newItem) => !oldCuisine.some((oldItem) => oldItem._id === newItem._id)
      );
      if (addedCuisine.length > 0) {
        setSelectedCuisine(newSelectedCuisine);
        handlerToggle("venue/cuisine/toggle", {
          venueId: id,
          cuisineId: addedCuisine[0]._id,
        });
      }
    } else if (newSelectedCuisine.length < oldCuisine.length) {
      const removedCuisine = oldCuisine.filter(
        (oldItem) =>
          !newSelectedCuisine.some((newItem) => newItem._id === oldItem._id)
      );
      if (removedCuisine.length > 0) {
        setSelectedCuisine(newSelectedCuisine);
        handlerToggle("venue/cuisine/toggle", {
          venueId: id,
          cuisineId: removedCuisine[0]._id,
        });
      }
    }
  };

  const handleOpen = (review) => {
    setSelectedReview(review);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFeatureSelectionChange = (newSelectedFeature) => {
    const oldFeature = [...selectedFeature];
    if (newSelectedFeature.length > oldFeature.length) {
      const addedFeature = newSelectedFeature.filter(
        (newItem) => !oldFeature.some((oldItem) => oldItem._id === newItem._id)
      );
      if (addedFeature.length > 0) {
        setSelectedFeature(newSelectedFeature);
        handlerToggle("venue/feature/toggle", {
          venueId: id,
          featureId: addedFeature[0]._id,
        });
      }
    } else if (newSelectedFeature.length < oldFeature.length) {
      const removedFeature = oldFeature.filter(
        (oldItem) =>
          !newSelectedFeature.some((newItem) => newItem._id === oldItem._id)
      );
      if (removedFeature.length > 0) {
        setSelectedFeature(newSelectedFeature);
        handlerToggle("venue/feature/toggle", {
          venueId: id,
          featureId: removedFeature[0]._id,
        });
      }
    }
  };

  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const useStyles = makeStyles({
    tableHeader: {
      color: "#ffffff", // Use your preferred color
      backgroundColor: "#3f51b5", // Header background color
      fontWeight: "bold",
    },
    descriptionText: {
      fontSize: "0.8rem",
    },
    deleteIcon: {
      color: "red",
    },
    roundImage: {
      borderRadius: "50%",
      width: "75px",
      height: "75px",
    },
    roundImageSm: {
      borderRadius: "50%",
    },

    gridList: {
      width: "100%",
    },
    icon: {
      color: "rgba(255, 55, 25, 0.54)",
    },

    // galleryImage: {
    //   width: "100%",
    // },
  });

  const breakpointColumnsObj = {
    default: 3, // adjust number of columns here
    1100: 3,
    700: 2,
    500: 1,
  };

  const classes = useStyles();

  const { id } = useParams();

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const TicketFetchDetail = async () => {
    setLoader(false);
    postRequest(`ticket/detail`, { ticketId: `${id}` }).then((data) => {
      let venueData = data.data;
      //let status = data.status;
      setData(venueData);
      console.log("venueData");
      console.log(venueData);

      setCurrentLocation({
        lat: parseFloat(venueData?.latitude),
        lng: parseFloat(venueData?.longitude),
      });

      if (venueData?.timings && venueData?.timings.length > 0) {
        // fetch the index from days

        const newTimeOptions = [...timeOptions];
        venueData?.timings.map((item, index) => {
          const daysIndex = days.indexOf(capitalizeFirstLetter(item.day));
          newTimeOptions[daysIndex].checked = true;
          newTimeOptions[daysIndex].open = item.openingTime;
          newTimeOptions[daysIndex].close = item.closingTime;
        });

        setTimeOptions(newTimeOptions);
      }

      if (venueData?.music && venueData?.music.length > 0) {
        setSelectedMusic(venueData.music);
      }

      if (venueData?.themes && venueData?.themes.length > 0) {
        setSelectedTheme(venueData.themes);
      }

      if (venueData?.cuisines && venueData?.cuisines.length > 0) {
        setSelectedCuisine(venueData.cuisines);
      }

      if (venueData?.features && venueData?.features.length > 0) {
        setSelectedFeature(venueData.features);
      }

      setLoader(false);
    });
  };

  const Updatevenue = async (e) => {
    const formData = new FormData();
    formData.append("venueId", id);

    if (timeOptions && timeOptions.length > 0) {
      timeOptions.forEach((item, index) => {
        if (item.checked) {
          formData.append(`days[]`, days[index].toString().toLowerCase());
          formData.append(`open_time[]`, item.open);
          formData.append(`close_time[]`, item.close);
        }
      });
    }

    const response = await putRequestForm("venue/update", formData);

    if (response.status == 1) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  useEffect(() => {
    //TicketFetchDetail();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;
    setTimeOptions(newTimeOptions);
  };

  const handlerdelete = (id, url, obj, key) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`${url}`, obj);

        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          // remove from venueData
          const vData = { ...venueData };
          vData[key] = vData[key].filter((item) => item._id !== id);
          setData(vData);
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };

  const handlerdelete2 = (id, file) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id, file);
      }
    });
  };

  const showRandomWordDialog = (id, file) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteGalleryFile(id, file);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteGalleryFile = async (id, file) => {
    const response = await deleteRequest(`venue/gallery/delete`, {
      venueId: id,
      file: file,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      TicketFetchDetail();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const handlerToggle = async (url, obj) => {
    await deleteRequest(`${url}`, obj);
  };

  const updateReply = async () => {
    const response = await putRequest("rating/reply/update", {
      ratingId: selectedReview._id,
      reply: textReply,
    });

    // update in venueData as well
    const selReview = selectedReview;

    if (selReview.reply?.reply) {
      selReview.reply.reply = textReply;
    } else {
      selReview.reply = { reply: textReply };
    }

    setSelectedReview(selReview);

    setOpen(false);

    if (response.status == 1) {
      toast.success("reply Updated");
    } else {
      toast.error(response.message);
    }
  };

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const showLightBoxGallery = (imgSrc) => {

    const index = images.indexOf(imgSrc);
    setPhotoIndex(index !== -1 ? index : 0); 
    setOpenLightBoxGallery(true);
}


  const {
    name,  
    thumbImage,
    zone,
    code,
    intCode,
    serviceTypeCode,   
    createdAt   
  } = venueData;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reply to Review</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your reply to the review here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Reply"
            type="text"
            fullWidth
            multiline
            defaultValue={
              selectedReview?.reply?.reply ? selectedReview?.reply?.reply : ""
            }
            onChange={(e) => {
              setTextReply(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={updateReply} color="primary">
            Reply
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
      <Row>
        <Col className="10">
          <div className="page-title-box">
            <h4>Ticket Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Ticket Management</li>:{" "}
              <li className="breadcrumb-item">Ticket details</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.back()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>

        {loader ? (
          <CardBody style={{ height: "100px" }}>
            <Spinner
              color="info"
              style={{
                height: "4rem",
                width: "4rem",
                position: "absolute",
                left: "50%",
              }}
            >
              Loading...
            </Spinner>
          </CardBody>
        ) : (
          <>
            <Card className="custom-card">
              {/* <div
                className="cover-image"
                style={{ backgroundImage: `url(${coverImage})` }}
                onClick={() => showLightBox(coverImage)}
              ></div> */}
              <div
                className="cover-image"
                style={{ backgroundColor: "rgb(29, 155, 240)" }}
              ></div>
              <div className="profile-image">
                <img
                  src={`https://ui-avatars.com/api/?background=random&name=${name}`}                
                  onClick={() => showLightBox(`https://ui-avatars.com/api/?background=random&name=${name}`)}
                  alt="Image Not Available"
                />
              </div>
              <br></br>
              <div className="row my-2 py-2">
                <div
                  className="userinfo col-md-12 "
                  style={{ paddingLeft: "20px" }}
                >
                  <div className="fst">
                    {" "}
                    <h3> {name} </h3>{" "}
                  </div>
                  <div className="row">
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Code: <span> {code}</span>{" "}
                      </p>{" "}
                    </div>

                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Zone Name: <span> {zone.name}</span>{" "}
                      </p>{" "}
                    </div>

                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Zone Code: <span> {zone.code}</span>{" "}
                      </p>{" "}
                    </div>


                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        intCode: <span> {intCode}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        ServiceTypeCode: <span> {serviceTypeCode}</span>{" "}
                      </p>{" "}
                    </div>                   
                    <div className="col-md-4 fst py-1">
                      <p>
                        {" "}
                        Created At:
                        <span>{formatDate(createdAt)}</span>
                      </p>
                    </div>                   
                  </div>
                  <hr />

                </div>
              </div>
            </Card>
            <Col md="12">
              <div
                id="exTab2"
                className="twitter-like-tabs2"
                style={{ background: "#ffff", marginLeft: "25px" }}
              >
                <ul
                  className="nav nav-pills"
                  style={{ width: "480px", display: "contents" }}
                >
                  {/* <li className="active">
                    <a href="#location" data-toggle="tab">
                      {" "}
                      Location{" "}
                    </a>
                  </li>
                */}                              


                  <li className="active">
                    <a href="#reviews" data-toggle="tab">
                      {" "}
                      Reviews{" "}
                    </a>
                  </li>

                  <li>
                    <a href="#bookings" data-toggle="tab">
                      {" "}
                      Bookings{" "}
                    </a>
                  </li>


                </ul>
              </div>
            </Col>
            <Col md="12">
              <div className="tab-content clearfix">

                <div className="tab-pane padded-tab" id="bookings">
                  {venueData?.intCode !== 0 && (
                    <TicketBookingTable
                    entityId={venueData.code}
                    />
                  )}
                </div>      

              <div className="tab-pane active padded-tab" id="reviews">
               {venueData?._id  && (
                    <TicketRatingTable
                    ticketId={venueData._id}
                    />
                  )}
                </div>      

   
              </div>
            </Col>
          </>
        )}

        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}

    {openLightboxGallery && (
         <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setOpenLightBoxGallery(false)}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
     />
        )}


      </Row>
      <ToastContainer />{" "}
    </>
  );
}
