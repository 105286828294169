import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";

import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequestForm,
  putRequest,
  postRequestForm,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";

import moment from "moment";

import ComponentDragModal from "./ComponentDragModal";

const filterObj = {

};


const ComponentManagementlist = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [Componentdata, setComponentdata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [backgroundImage, setBackgroundImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightbox2, setOpenLightBox2] = React.useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [canCreate, setCanCreate] = useState(true);
  const [canEdit, setCanEdit] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canExport, setCanExport] = useState(true);
  const [filter, setFilter] = useState({ ...filterObj });

  const [loader2, setLoader2] = useState(false);

  useEffect(() => {
    ComponentManagment();
  }, [currentPage]);
  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
        console.log("page", page);
      }
    }

    console.log("type", type);

    if (type === "search") {
      console.log("searchText", searchText);
    }
  };

  const getFilters = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {

        var filterObject = {
          [key]: filter[key],
        };


        if (key === "title") {
          filterObject.type = "regex";
        } else if (key === "homeBlockType") {
          filterObject.type = "eq";
        }
        else if (key === "sizeId") {
          filterObject.type = "obj-eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });
    return filterArgs;
  };

  function activeFormatter(cellContent, row) {
    return (
      <>
        {row.status == 1 ? (
          <>
            <button className="btn btn-active" type="button">
              Active
            </button>
          </>
        ) : (
          <>
            <button className="btn btn-deactive" type="button">
              Inactive
            </button>
          </>
        )}
      </>
    );
  }

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };
  const showLightBox2 = (backgroundImage) => {
    setBackgroundImage(backgroundImage);
    setOpenLightBox2(true);
  };

  function MediaFormatter(cellContent, row) {
    return (
      <>
        {row.image ?
          <img src={row.image}
            onClick={() => showLightBox(row.image)}
            alt="Image" width={"50px"} />
          : <></>}
      </>
    );
  }

  function itemFormatter(cellContent, row) {
    return (
      <>
        {row.contentType == 'link' ? (
          <div>
            <p>{row.item}</p>
          </div>
        ) :
          (<div>
            <p>{row.selectedItem}</p>
          </div>)}
      </>
    );
  }

  function MediaFormatterbg(cellContent, row) {
    return (
      <>
        <img src={row.backgroundImage}
          onClick={() => showLightBox2(row.backgroundImage)}
          alt="Image" width={"50px"} />
      </>
    );
  }
  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }
  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "title",
      text: "Title (English)",
      sort: false,
    },
    {
      dataField: "titleFrench",
      text: "Title (French)",
      sort: false,
    },
    {
      dataField: "titleGerman",
      text: "Title (German)",
      sort: false,
    },
    {
      dataField: "type",
      text: "Type",
      sort: false,
    },

    {
      dataField: "contentType",
      text: "Content Type",
      sort: false,
    },

    {
      dataField: "item",
      text: "Item",
      sort: false,
      formatter: itemFormatter,
    },

    {
      dataField: "buttonText",
      text: "Button Text (English)",
      sort: false,
    },
    {
      dataField: "buttonTextFrench",
      text: "Button Text (French)",
      sort: false,
    },
    {
      dataField: "buttonTextGerman",
      text: "Button Text (German)",
      sort: false,
    },

    {
      dataField: "image",
      text: "Image",
      formatter: MediaFormatter,
      sort: false,
    },
    {
      dataField: "backgroundColor",
      text: "Background Color",
      sort: false,
    },
    {
      dataField: "backgroundImage",
      text: "Background Image",
      formatter: MediaFormatterbg,
      sort: false,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: activeFormatter,
      sort: false,
    },

    {
      dataField: "createdAt",
      text: "Created At",
      formatter: dateFormatter,
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },

    // {
    //   dataField: "url",
    //   text: "Link",
    //   sort: false,
    // },
  ];

  function dateFormatter(cellContent, row) {
    return (
      <React.Fragment>
        {moment(row.createdAt).format("MMM Do YY")}
      </React.Fragment>
    );
  }
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex">
          {canEdit && (
            <button type="button" className=" btn btn-info  mx-2">
              <Link
                to={{
                  pathname: `/updateComponent/${row._id}`,
                  state: { row },
                }}
              >
                <i className="fal fa-pencil fs-5 text-light"></i>
              </Link>
            </button>
          )}
          {canDelete && (
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => handlerdelete(row._id)}
            >
              <i className="fal fa-trash  fs-5"></i>
            </button>
          )}
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const limit = 10;
  const exportCSV = async () => {
    setLoader(true);

    const filterArgs = getFilters();
    const payload = {

    };

    // if (filterArgs?.length) {
    //   payload.filterArgs = filterArgs;
    // } else {
    //   if (searchText) {
    //     payload.search = searchText;
    //   }
    // }

    try {
      const response = await postRequest(`homepage/component/export-csv`, payload);
      const data = response;
      window.location.href = data.url;
      console.log("data", data);
    } catch (error) {
      console.log("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }


  }

  const ComponentManagment = async (page = 0) => {
    setLoader(true);
    try {
      const response = await postRequest(`homepage/component/list`, { limit: 10 });
      const data = response;
      setTotalPage(data?.count ? data?.count : 0);
      setCurrentPage(data?.page ? data?.page : 1);
      setComponentdata(data?.list ? data?.list : []);
    } catch (error) {
      console.log("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`homepage/component`, {
          componentId: id,
        });
        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          ComponentManagment();
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };

  const updateComponentData = async (data) => {
    setComponentdata(data);
    setShowOrder(false);
    const response = await postRequest(`homepage/component/update-order`, { data: data });
    console.log("data-response", response);
  }



  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Component Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Component Management</li>
                :<li className="breadcrumb-item">Component List</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-4 text-end">
            <Button onClick={() => setShowOrder(true)} className="btn btn-info" style={{ marginRight: '10px' }}>
              <i className="fas fa-grip-vertical" ></i> Order
            </Button>
            {canExport && (
              <button onClick={() => { exportCSV() }} type='button' className='btn btn-primary mx-2'><i class="fas fa-upload"></i> Export CSV</button>
            )}
            {canCreate && (
              <Link to="/CreateComponets">
                <Button>
                  <i className="fas fa-plus"></i> Create
                </Button>
              </Link>
            )}
            &nbsp;&nbsp;&nbsp;
          </div>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={Componentdata}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                {/* <Col className='col-lg-12 d-flex mb-3'>
                          
                          <label className='mb-0 text-label'>Page Size</label>
                         
                           <select value={value} onChange={handleChange} className="form-select" style={{ width: "7%" }}>

                           <option value="10">10</option>

                           <option value="20">20</option>

                           <option value="50">50</option>

                           </select>
                          

                           <label className='mb-0 text-label ms-3'>Sort By:</label>
                           <select value={sort} onChange={handlesorting} className="form-select" style={{ width: "10%" }}>
                           <option value="first_name"> First Name</option>
                           <option value="last_name"> Last Name</option>
                           <option value="email">Email ID</option>

                           <option value="phone">Phone No.</option>
                           <option value="birth_date">DOB</option>
                           <option value="gender">Gender</option>

                           </select>
                         <div className='filter-item'>
                           <input type="search" placeholder='Search' value={searchText} className="form-control" onChange={ (e) => setSearchText(e.target.value) } style={{ width: "30%" }} />                            
                           <button onClick={ () => { storyManagment() }  } type='button' className='btn btn-primary mx-2'>Search</button>
                           <button type='button' onClick={handleReload} className='btn btn-danger'>Reset</button>
                           </div>
                       </Col> */}

                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    remote={true}
                                    classes="table-striped"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
        {openLightbox2 && (
          <Lightbox
            mainSrc={backgroundImage}
            onCloseRequest={() => setOpenLightBox2(false)}
          />
        )

        }

        <ComponentDragModal show={showOrder} closeModal={() => setShowOrder(false)} data={Componentdata} setData={updateComponentData} />

      </React.Fragment>
    </>
  );
};

export default ComponentManagementlist;
