import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequestForm, postRequest } from "../../components/Common/Utils.js";

export default function AutoResponder() {
  let history = useHistory();

  const [bannerData, setBannerData] = useState(null);

  useEffect(() => {
    autoResponder();
  }, []);

  const autoResponder = async () => {
    const response = await postRequest("chat/auto-responder/fetch", {});
    const resData = response?.data;
    setBannerData(resData);
  };

  const Createbannerform = async (e) => {
    e.preventDefault();
    const bData = {
      welcomeMessage: bannerData?.welcomeMessage,
      delayMessage: bannerData?.delayMessage,
    };

    const response = await postRequest("chat/auto-responder/update", bData);
    console.log({ response });
    if (response.status == 1) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...bannerData };
    list[index] = value;
    setBannerData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row d-flex flex-row">
        <div className="col-md-10">
          <div className="page-title-box">
            <h4>Auto Responder</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">
                Auto Responder Management
              </li>
              : <li className="breadcrumb-item">Auto Responder</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title"> Auto Responder</h4>

              <Form onSubmit={Createbannerform}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Welcome Message
                        </label>
                        <div className="col-sm-6">
                          <textarea
                            onChange={(e) =>
                              updateValue("welcomeMessage", e.target.value)
                            }
                            value={bannerData?.welcomeMessage}
                            name="title"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Delay Message
                        </label>
                        <div className="col-sm-6">
                          <textarea
                            onChange={(e) =>
                              updateValue("delayMessage", e.target.value)
                            }
                            value={bannerData?.delayMessage}
                            name="link"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="mt-5 mt-lg-4">
                        <div className="row justify-content-end">
                          <div className="col-sm-9">
                            <div>
                              <button
                                type="submit"
                                className="btn btn-primary w-md"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
