import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";

//import filter modal
import FilterModal from "../../components/Modal/FilterModal";

import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequestForm,
  putRequest,
  postRequestForm,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";

const filterObj = {
  venue_name: "",
  contenType: "",
  mediaType: "",  
  buttonText: "",
  status: "",
  createdAt: "",
};
const StoryManagementlist = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [storydata, setstorydata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [value, setValue] = React.useState(10);

  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");

  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [model, setModel] = useState(false);
  const [filter, setFilter] = useState({ ...filterObj });

  const [loader2, setLoader2] = useState(false);

  useEffect(() => {
    storyManagment();
  }, [currentPage]);

  useEffect(() => {
    storyManagment();
  }, [sizePerPageOrg, sort,sortOrder]);

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value == 1 ? 1 : 0;

    const formData = new FormData();
    formData.append("themeId", actId);
    formData.append("status", status);

    const res = await putRequestForm("theme/update", formData);

    if (res.status == 1) {
      toast.success("Theme Status updated Successfully");
      themeManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    console.log("toggleModel", model);
    setModel(!model);
  };
  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
        console.log("page", page);
      }
    }

    console.log("type", type);

    if (type === "search") {
      console.log("searchText", searchText);
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const applyFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {

        if(key === "venue_name") {
          var filterObject = {
            'venue.name': filter['venue_name'],
          };

        }else{
          var filterObject = {
            [key]: filter[key],
          };
        }       

        if (key === "venue_name" || key === "contentType" || key === "mediaType" || key === "buttonText") {
          filterObject.type = "regex";
        } else if (key === "status") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });

    console.log("filterArgs", filterArgs);
    toggleModel();
    storyManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    storyManagment();
  };


  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function activeFormatter(cellContent, row) {
    return (
      <>
        {row.status == 1 ? (
          <>
            <button
              className="btn btn-active"
              type="button"
              onClick={() => GetValueOnChange((row.status = false), row._id)}
            >
              Active
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-deactive"
              type="button"
              onClick={() => GetValueOnChange((row.status = true), row._id)}
            >
              Inactive
            </button>
          </>
        )}
      </>
    );
  }
  function MediaFormatter(cellContent, row) {
    if (row.mediaType == "video") {
      return (
        <>
          <video width="50" height="50" controls>
            <source src={row.mediaUrl} type="video/mp4" />
          </video>
        </>
      );
    }

    return (
      <>
        <img
          src={row.mediaUrl}
          onClick={() => showLightBox(row.mediaUrl)}
          alt="thumbnail img"
          width={"50px"}
        />
      </>
    );
  }
  function ImgLogoFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.logo}
          onClick={() => showLightBox(row.logo)}
          alt="logo img"
          width={"50px"}
        />
      </>
    );
  }

  function ImgCoverFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.cover}
          onClick={() => showLightBox(row.cover)}
          alt="cover img"
          width={"50px"}
        />
      </>
    );
  }

  function VenueFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.venue?.name}</p>
      </div>
    );
  }
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Venue</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={ filter.venue_name }
          onChange={(e) => updateFilter("venue_name", e.target.value)}
          name="venue"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="amount">
      <label className="col-sm-3 col-form-label">Content Type</label>
      <div className="col-sm-6"> 
        <select 
          value={ filter.contentType }
          onChange={(e) => updateFilter("contentType", e.target.value)}
          name="contentType"
          className="form-control"
        >
          <option value="">Select Content Type</option>
          <option value="web">Web</option>
          <option value="business">Business</option>
          <option value="offer">Offer</option>
        </select>

      </div>
    </div>,

<div className="row mb-4" key="amount">
<label className="col-sm-3 col-form-label">Media Type</label>
<div className="col-sm-6">

<select
  value={ filter.mediaType }
  onChange={(e) => updateFilter("mediaType", e.target.value)}
  name="mediaType"
  className="form-control"
>
  <option value="">Select Media Type</option>
  <option value="image">Image</option>
  <option value="video">Video</option>
</select>

 
</div>
</div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Button Text</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={ filter.buttonText }
          onChange={(e) => updateFilter("buttonText", e.target.value)}
          name="buttonText"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Status</label>
      <div className="col-sm-6">
        <select
          onChange={(e) => updateFilter("status", e.target.value)}
          className="form-select"
          value={ filter.status}
        >
          <option value="">Select Status</option>
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={ filter.createdAt }
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    // submit button

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "venue",
      text: "Venue",
      formatter: VenueFormatter,
      sort: false,
    },
    {
      dataField: "contentType",
      text: "Content Type",
      sort: false,
    },
    {
      dataField: "mediaType",
      text: "Media Type",
      sort: false,
    },
    {
      dataField: "mediaUrl",
      text: "File",
      formatter: MediaFormatter,
      sort: false,
    },
    {
      dataField: "thumbnail",
      text: "Thumbnail",
      sort: false,
    },

    {
      dataField: "buttonText",
      text: "Button Text",
      sort: false,
    },
   

    {
      dataField: "createdAt",
      text: "Created At",
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];
  const handleChange = (event) => {    
    setSizePerPage(event.target.value);
    
  };
  const handlesorting = (event) => {
    setsort(event.target.value);   
  };
  const handlesortOrder = (event) => {   
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    window.location.reload();
  };
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex">
          
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id)}
          >
            <i class="fal fa-trash  fs-5"></i>
          </button>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  

  const storyManagment = async (filterArgs = []) => {
    console.log(currentPage);
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
    };
    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText) {
        payload.search = searchText;
      }
    }
    const response = await postRequest(`story/list`, payload);
    const data = response.data;
    setTotalPage(data.count);
    setCurrentFPage(data.page);
    setstorydata(data.list);
    setLoader(false);
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`story/delete`, { storyId: id });
        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          storyManagment();
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };



  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Story Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Story Management</li>:{" "}
                <li className="breadcrumb-item">Story List</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end">
            <Link to="/createStory">
              {" "}
              <Button>
                {" "}
                <i class="fas fa-plus"></i> Create
              </Button>{" "}
            </Link>
            &nbsp;&nbsp;&nbsp;
          </div>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={storydata}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-12 d-flex mb-3">
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>

                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ width: "7%" }}
                                  >
                                    <option value="10">10</option>

                                    <option value="20">20</option>

                                    <option value="50">50</option>
                                  </select>

                                  <label className="mb-0 text-label ms-3">
                                    Sort By:
                                  </label>
                                  <select
                                    value={sort}
                                    onChange={handlesorting}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="venue">Venue</option>
                                    <option value="content_type">
                                      Content Type
                                    </option>
                                    <option value="media_type">
                                      Media Type
                                    </option>

                                    <option value="button_text">
                                      Button Text
                                    </option>
                                    <option value="createdAt">
                                      Created At
                                    </option>
                                  </select>
                                  <label className="mb-0 text-label ms-3">
                                    Sort Order:
                                  </label>
                                  <select
                                    value={sortOrder}
                                    onChange={handlesortOrder}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="asc"> Ascending</option>
                                    <option value="desc">Descending</option>
                                    
                                  </select>
                                  <div className="filter-item">
                                    <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      style={{ width: "30%" }}
                                    />
                                    <button
                                      onClick={() => {
                                        storyManagment();
                                      }}
                                      type="button"
                                      className="btn btn-primary mx-2"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="button"
                                      onClick={handleReload}
                                      className="btn btn-danger mx-2"
                                    >
                                      Reset
                                    </button>
                                    <button
                                      type="button"
                                      onClick={toggleModel}
                                      className="btn btn-info"
                                    >
                                      Filter
                                    </button>
                                  </div>
                                </Col>

                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    remote={true}
                                    classes="table-striped"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default StoryManagementlist;
