import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.min.css";
//import filter modal
import FilterModal from "../../components/Modal/FilterModal";

import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequestForm,
  putRequest,
  postRequestForm,
  convertTimeToFormattedString
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";

const filterObj = {
  title: "",
  status: "",
  createdAt: "",
};

const AppTransactionList = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [appTransactiondata, setappTransactiondata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [value, setValue] = React.useState(10);

  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");

  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [canCreate, setCanCreate] = useState(true);
  const [canEdit, setCanEdit] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [filter, setFilter] = useState({ ...filterObj });
  const [model, setModel] = useState(false);
  const [loader2, setLoader2] = useState(false);



  useEffect(() => {
    appTransactionManagment();
  }, [currentPage]);

  useEffect(() => {
    appTransactionManagment();
  }, [sizePerPageOrg, sort, sortOrder]);

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value == 1 ? 1 : 0;

    const formData = new FormData();
    formData.append("appTransactionId", actId);
    formData.append("status", status);

    const res = await putRequestForm("appTransaction", formData);

    if (res.status == 1) {
      toast.success("appTransaction Status updated Successfully");
      appTransactionManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    console.log("toggleModel", model);
    setModel(!model);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
        console.log("page", page);
      }
    }

    console.log("type", type);

    if (type === "search") {
      console.log("searchText", searchText);
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const applyFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key],
        };

        if (key === "title") {
          filterObject.type = "regex";
        } else if (key === "status") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });

    console.log("filterArgs", filterArgs);
    toggleModel();
    appTransactionManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    appTransactionManagment();
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "desc",
    },
  ];

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }


  function ImgCoverFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.image}
          onClick={() => showLightBox(row.image)}
          alt="cover img"
          width={"50px"}
        />
      </>
    );
  }
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  function AmountFormater(cellContent, row) {

    return <span>{row?.amount?.value} {row?.amount?.currency}</span>;
  }

  function readPercentageFormatter(cellContent, row) {
    return (
      <>
        {Math.floor(row.readPercentage)}%
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: Math.floor(row.readPercentage) + "%" }}
            aria-valuenow={Math.floor(row.readPercentage)}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </>
    );
  }

  function activeFormatter(cellContent, row) {
    return (
      <>
        {row.status == 1 ? (
          <>
            <button
              className="btn btn-active"
              type="button"
              onClick={() => GetValueOnChange((row.status = 0), row._id)}
            >
              Active
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-deactive"
              type="button"
              onClick={() => GetValueOnChange((row.status = 1), row._id)}
            >
              Inactive
            </button>
          </>
        )}
      </>
    );
  }

  function dateFormatter(cellContent, row) {
    return (
      <div >
        {convertTimeToFormattedString(row.createdAt)}
      </div>
    );
  }


  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };


  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Title</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.title}
          onChange={(e) => updateFilter("title", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Status</label>
      <div className="col-sm-6">
        <select
          onChange={(e) => updateFilter("status", e.target.value)}
          className="form-select"
          value={filter.status}
        >
          <option value="">Select Status</option>
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    // submit button

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  const data = [
    {
      id: 1,
      title: "Example Title 1",
      status: "Active",
      createdAt: "2023-05-14",
    },
    {
      id: 2,
      title: "Example Title 2",
      status: "Inactive",
      createdAt: "2023-05-13",
    },
  ];
  // const columns = [
  //   {
  //     text: "ID",
  //     dataField: "id",
  //     sort: false,
  //   },
  //   {
  //     dataField: "title",
  //     text: "Title",
  //     sort: false,
  //   },
  //   {
  //     dataField: "status",
  //     text: "Status",
  //     sort: false,
  //   },

  //   {
  //     dataField: "createdAt",
  //     text: "Created At",
  //     sort: false,
  //   },

  //   {
  //     dataField: "actions",
  //     text: "Actions",
  //     formatter: iconFormatter,
  //     sort: false,
  //   },
  // ];
  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "customerName",
      text: "Customer",
      sort: false,
    },
    {
      dataField: "customerDetails.email",
      text: "Customer Email",
      sort: false,
    },
    {
      dataField: "bookingTitle",
      text: "Booking",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div
            style={{
              maxHeight: '5em',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              // cursor: 'pointer'
            }}
            title={row.bookingTitle}
          >
            {row.bookingTitle}
          </div>
        );
      }
    },
    {
      dataField: "bookingCode",
      text: "Booking Code",
      sort: false,
      formatter: (cellContent, row) => (
        <Link
          to={{
            pathname: row.type === "elevate-ticket" 
              ? `/elevate-bookings/${row.selectedId}` 
              : `/bookingdetails/${row.selectedId}`,
            state: { row }
          }}
          style={{
            textDecoration: 'none',
            color: '#ec672c',
          }}
        >
          {cellContent}
        </Link>
      ),
    },

    // {
    //   dataField: "image",
    //   text: "Image",
    //   formatter: ImgCoverFormatter,
    // },
    {
      dataField: "type",
      text: "Type",
      sort: false,
    },
    {
      dataField: "link",
      text: "Booking Amount",
      formatter: AmountFormater,
    },
    {
      dataField: "orderReference",
      text: "Order Reference",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div
            style={{
              maxHeight: '5em',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              // cursor: 'pointer'
            }}
            title={row.orderReference}
          >
            {row.orderReference}
          </div>
        );
      }
    },
    {
      dataField: "paymentReference",
      text: "Payment Reference",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div
            style={{
              maxHeight: '5em',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              // cursor: 'pointer'
            }}
            title={row.paymentReference}
          >
            {row.paymentReference}
          </div>
        );
      }
    },
    {
      dataField: "paymentStatus",
      text: "Payment Status",
      sort: false,
    },
    // {
    //   dataField: "totalAudience",
    //   text: "Total Audience",
    //   sort: false,
    // }, 
    // {
    //   dataField: "status",
    //   text: "Status",
    //   formatter: activeFormatter,
    // },
    {
      dataField: "createdAt",
      text: "Created At",
      formatter: dateFormatter,
      sort: false,
    },

    // {
    //   dataField: "actions",
    //   size: "120px",
    //   text: "Actions",
    //   formatter: iconFormatter,
    //   sort: false,
    // },
  ];
  const handleChange = (event) => {
    //setValue(event.target.value);
    setSizePerPage(event.target.value);
    console.log("page value ", event.target.value);
  };
  const handlesorting = (event) => {
    //setsort(event.target.value);
    setsort(event.target.value);
    console.log("sorting  value ", event.target.value);
  };
  const handleReload = () => {
    setSearchText("");
    appTransactionManagment(null, null, true);
  };
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex-center d-flex">
          {/* <button type="button" className=" btn btn-secondary mx-2">
            <Link
              to={{
                pathname: `/appTransactionsetting/${row._id}`,
                state: { row },
              }}
            >
              <i className="fal fa-cog fs-5 text-light"></i>
            </Link>
          </button>
          <button type="button" className=" btn btn-primary mx-2">
            <Link
              to={{
                pathname: `/appTransactiondetails`,
              }}
            >
              <i className="fal fa-eye fs-5 text-light"></i>
            </Link>
          </button> */}
          {canEdit && (
            <button type="button" className=" btn btn-info mx-2">
              {" "}
              <Link
                to={{
                  state: { row },
                  pathname: `/UpdateappTransactiondata`,
                }}
              >
                {" "}
                <i class="fal fa-pencil fs-5 text-light"></i>
              </Link>{" "}
            </button>
          )}
          {canDelete && (
            <button
              type="button"
              className="btn btn-danger mx-2"
              onClick={() => handlerdelete(row._id)}
            >
              <i class="fal fa-trash  fs-5"></i>
            </button>
          )}
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const appTransactionManagment = async (filterArgs = [], limit = null, isReload = false) => {
    console.log(currentPage);
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
    };
    if (!isReload) {
      if (filterArgs?.length) {
        payload.filterArgs = filterArgs;
      } else {
        if (searchText) {
          payload.search = searchText;
        }
      }
    }
    try {
      const response = await postRequest(`payment/all-transaction/list`, payload);
      const data = response.data;
      setTotalPage(data.count);
      setCurrentFPage(data.page);
      setappTransactiondata(data.orders);
    } catch (error) {
      console.log("Failed to fetch data:", error);
    }
    finally {
      setLoader(false);
    }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`appTransaction`, {
          appTransactionId: id,
        });
        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          appTransactionManagment();
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>App Transactions</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">App Transactions</li>:{" "}
                <li className="breadcrumb-item">App Transaction List</li>
              </ol>
            </div>
          </Col>
          {/* <div className="col-md-2 text-end">
            {canCreate && (
              <Link to="/CreateappTransaction">
                {" "}
                <Button>
                  {" "}
                  <i class="fas fa-plus"></i> Create
                </Button>{" "}
              </Link>
            )}
            &nbsp;&nbsp;&nbsp;
          </div> */}
          <Col className="col-12">
            <Card>
              {/* {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : ( */}
              <CardBody className="table-shorting">
                {
                  <PaginationProvider
                    pagination={paginationFactory({
                      page: currentFPage,
                      sizePerPage: sizePerPageOrg,
                      totalSize: totalPage,
                      custom: true,
                    })}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <>
                        <ToolkitProvider
                          keyField="_id"
                          data={appTransactiondata}
                          columns={columns}
                        >
                          {(props) => (
                            <Row>
                              <Col className="col-lg-12 d-flex mb-3">
                                <label className='mb-0 text-label'>Page Size</label>

                                <select value={sizePerPageOrg} onChange={handleChange} className="form-select" style={{ width: "7%" }}>

                                  <option value="10">10</option>

                                  <option value="20">20</option>

                                  <option value="50">50</option>

                                </select>


                                <label className='mb-0 text-label ms-3'>Sort By:</label>
                                <select value={sort} onChange={handlesorting} className="form-select" style={{ width: "10%" }}>
                                  <option value="title"> Title</option>
                                  <option value="type"> Type</option>
                                  <option value="createdAt">Created At</option>


                                </select>
                                <label className="mb-0 text-label ms-3">
                                  Sort Order:
                                </label>
                                <select
                                  value={sortOrder}
                                  onChange={handlesortOrder}
                                  className="form-select"
                                  style={{ width: "10%" }}
                                >
                                  <option value="asc"> Ascending</option>
                                  <option value="desc">Descending</option>

                                </select>
                                <div className="filter-item">
                                  <input
                                    type="search"
                                    placeholder="Search"
                                    value={searchText}
                                    class="form-control"
                                    onChange={(e) =>
                                      setSearchText(e.target.value)
                                    }
                                    style={{ width: "30%" }}
                                  />
                                  <button
                                    onClick={() => {
                                      appTransactionManagment();
                                    }}
                                    type="button"
                                    className="btn btn-primary mx-2"
                                  >
                                    Search
                                  </button>
                                  <button
                                    type="button"
                                    onClick={handleReload}
                                    className="btn btn-danger mx-2"
                                  >
                                    Reset
                                  </button>

                                  {/* <button
                                    type="button"
                                    onClick={toggleModel}
                                    className="btn btn-info mx-2"
                                  >
                                    Filter
                                  </button> */}
                                </div>
                              </Col>

                              <Col className="col-lg-12 text-end">
                                <BootstrapTable
                                  onTableChange={handleTableChange}
                                  remote={true}
                                  classes="table-striped"
                                  {...props.baseProps}
                                  {...paginationTableProps}
                                />
                              </Col>
                            </Row>
                          )}
                        </ToolkitProvider>

                        <Row>
                          <Col className="col-lg-12 text-end">
                            <div className="text-md-right">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </PaginationProvider>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default AppTransactionList;
