import React, { useState, useRef } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const TYPE = 'ROW';

const DragIcon = () => <span class="fas fa-grip-vertical" style={{ fontSize: "1.5rem" }}></span>;

const DraggableRow = ({ item, index, moveRow, children, setData }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: TYPE,
    hover: (draggedItem) => {
      if (draggedItem.index === index) return;
      moveRow(draggedItem.index, index);
      draggedItem.index = index;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: TYPE,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (!monitor.didDrop()) return;     
    },
});

  drag(drop(ref));

  return (
    <tr ref={ref} style={{ opacity: isDragging ? 0 : 1 }}>
      <td className="dragHandle text-center align-middle">
        <DragIcon />
      </td>
      {children}
    </tr>
  );
};

const DragDropTable = ({ data, onRowReorder, setData }) => {
  const [items, setItems] = useState(data);

  const moveRow = (fromIndex, toIndex) => {
    const updatedItems = [...items];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);
    setItems(updatedItems);
    if (onRowReorder) onRowReorder(updatedItems);
    //setData(updatedItems);  // add this line to update the data after dragging is complete
 };

  return (
    <>
    <table className="table table-striped table-bordered table-hover">
      <thead className="thead-dark">
        <tr>
          <th></th>
          <th>Order</th>
          <th>Title</th>
          <th>Image</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <DraggableRow
            key={item.id}
            index={index}
            item={item}
            moveRow={moveRow}
            setData={setData}
          >
            <td className='text-center align-middle'>{index + 1}</td>
            <td className='text-center align-middle'>{item.title}</td>
            <td className='text-center align-middle'><img src={item.image} style={{ width: "50px", height: "50px" }}/></td>
            <td className='text-center align-middle'>{item.type}</td>
          </DraggableRow>
        ))}
      </tbody>
    </table>

    <div style={{ textAlign: 'center', margin: '20px' }}> {/* Add a div to center the button */}
    <button 
      onClick={() => setData(items)} /* Set onClick to call setData(items) */
      className="btn btn-primary"
    >
      Save Order
    </button>
  </div>
    
    </>
  );
};

const DragDropComponent = ({data, setData}) => {


  const handleRowReorder = (newData) => {
    //console.log('New Data:', newData);
    //setData(newData)
    // Here you can make a call to your endpoint to update the order in the backend.
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <DragDropTable
        data={data}        
        onRowReorder={handleRowReorder}
        setData={setData}
      />
    </DndProvider>
  );
};

export default DragDropComponent;