import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { getRequest, postRequest } from "../../components/Common/Utils.js";



export default function AppThemeSetting() {

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  let history = useHistory();

  const AppThemeGet = async () => {
    setLoader(true);
    try {
      const response = await getRequest(`admin/app-theme/get`);
      const data = response;
      setData(data.data)
      console.log(data)
    } catch (error) {
      console.log("Failed to fetch data:", error)
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    AppThemeGet();
  }, []);

  const AppThemeSettingform = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    let formData = Object.fromEntries(data.entries())
    console.log(formData)
    const response = await postRequest("admin/app-theme/update", formData);
    console.log({ response })
    if (response.status == 1) {
      toast.success(response.message);
      AppThemeGet()
    } else {
      toast.error(response.message);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div claaName="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Settings</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Settings</li>:{" "}
              <li className="breadcrumb-item">App Theme</li>
            </ol>
          </div>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loader ?
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: '4rem',
                    width: '4rem',
                    position: "absolute",
                    left: "50%"
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
              :
              <CardBody>
                <h4 className="card-title"> App Themes</h4>

                <Form onSubmit={AppThemeSettingform}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Background Color</label>
                          <div className="col-sm-6">
                            <input
                              type="color"
                              name="appBgColor"
                              defaultValue={data.appBgColor || ""}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Brand Color</label>
                          <div className="col-sm-6">
                            <input
                              type="color"
                              name="brandColor"
                              defaultValue={data.brandColor || ""}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Card Color</label>
                          <div className="col-sm-6">
                            <input
                              type="color"
                              name="cardColor"
                              defaultValue={data.cardColor || ""}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Title Color</label>
                          <div className="col-sm-6">
                            <input
                              type="color"
                              name="titleColor"
                              defaultValue={data.titleColor || ""}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Sub Title Color</label>
                          <div className="col-sm-6">
                            <input
                              type="color"
                              name="subTitleColor"
                              defaultValue={data.subTitleColor || ""}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Tabun Select Color</label>
                          <div className="col-sm-6">
                            <input
                              type="color"
                              name="tabunSelect"
                              defaultValue={data.tabunSelect || ""}
                              className="form-control"
                            />
                          </div>
                        </div>
                    


                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Update Theme
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            }
          </Card>
        </Col>
      </Row>


    </React.Fragment>
  );
}
