import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { useLocation } from "react-router-dom";
import { getRequest, postRequest } from "components/Common/Utils";
import { useParams } from "react-router-dom";

export default function Notificationdetails() {
  const [notificationdata, setData] = useState([]);
  console.log(notificationdata);

  const [loader, setLoader] = useState(true);
  const location = useLocation();
  console.log(location.state);

  const { id } = useParams();

  const UserFetchDetail = async () => {
    setLoader(false);
    postRequest(`/profile`, { userId: `${id}` }).then((data) => {
      let userdata = data.data;
      let status = data.status;
      setData(userdata);
      console.log("userdata");
      console.log(userdata);

      if (status == 1) {
        setLoader(false);
      }
      setLoader(false);
    });
  };
  useEffect(() => {
    UserFetchDetail();
  }, []);

  return (
    <>
      <Row>
        <Col className="10">
          <div className="page-title-box">
            <h4>Notification Management</h4>

            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">
                Notification Management
              </li>
              : <li className="breadcrumb-item">Notification details</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.back()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>

        <>
          <Card>
            <div className="row my-2 py-2">
              <div className="userprofile col-md-2">
                <img alt="Image Not Avalibler " />
              </div>
              <div className="userinfo col-md-10">
                <div className="fst">
                  <h3>Notification Example</h3>
                </div>

                <div className="row">
                  <div className="col-md-4 fst py-1">
                    <p>
                      Title: <span>Example</span>
                    </p>
                  </div>
                  <div className="col-md-4 fst py-1">
                    <p>
                      Description: <span>Sample</span>
                    </p>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-4 fst py-1">
                    <p>
                      {" "}
                      Image Title: <span>Image</span>
                    </p>
                  </div>
                  <div className="col-md-4 fst py-1">
                    <p>
                      {" "}
                      Scheduled: <span>Inactive</span>{" "}
                    </p>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-4 fst py-1">
                    <p>
                      {" "}
                      Platform: <span>IOS</span>{" "}
                    </p>
                  </div>
                  <div className="col-md-4 fst py-1">
                    <p>
                      {" "}
                      Type: <span>General</span>{" "}
                    </p>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-4 fst py-1">
                    <p>
                      {" "}
                      Target(Guest): <span>Active</span>{" "}
                    </p>
                  </div>
                  <div className="col-md-4 fst py-1">
                    <p>
                      {" "}
                      Target(Users): <span>All Users</span>{" "}
                    </p>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-4 fst py-1">
                    <p>
                      {" "}
                      Status: <span>Published</span>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </>
      </Row>
    </>
  );
}
