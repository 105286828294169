import React from 'react';
import { Avatar } from "@chatscope/chat-ui-kit-react";

const ContactItem = ({ contact, onselectContact }) => {
  return (
    <div className="contactItem" onClick={() => onselectContact(contact)}>
      <Avatar src={contact.userImage ? contact.userImage : `https://ui-avatars.com/api/?name=${contact.userName}`  } name={contact.userName} />
      <div className="contactInfo">
      

        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between"  }}>

            <div>

            <div className="contactName">{contact.userName}</div>
               <p>{contact.msgType == 'text' ?  contact.lastMessage : 'Image'}</p>
            <div className="contactStatus">
              {/* <span className={`statusCircle ${contact.status}`}></span>
              {contact.status.charAt(0).toUpperCase() + contact.status.slice(1)} */}
            </div>


            </div>
           
            {contact.newMessageCount && contact.newMessageCount > 0 && (
              <div className="newMessageCounter">
                <span className="newMessageIcon"></span>
                {contact.newMessageCount}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default ContactItem;