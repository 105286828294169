import { current } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { FormGroup, Label, Input, Row, Col } from 'reactstrap';


const DayTimePicker = ({ day, updateTiming, index,  currentValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openTime, setOpenTime] = useState('00:00');
  const [closeTime, setCloseTime] = useState('00:00');

  const updateByObj = (obj, val) => {

    console.log("updateByObj", obj, val)

    updateTiming(index, obj, val);
  };

  

  return (
    <Row>
      <Col md={2}>
        <FormGroup check>
          <Label check>
            <Input type="checkbox" checked={currentValue.checked} onChange={ (e) => updateByObj('checked', e.target.checked  ) } />
            {day}
          </Label>
        </FormGroup>
      </Col>
      <Col md={4}>   
        <input value={currentValue.open} type="time" onChange={ (e) => updateByObj('open', e.target.value )} disabled={  !currentValue.checked }   name='openingTime'   className="form-control"  />
      </Col>
      <Col md={4}>
        <input value={currentValue.close} type="time" onChange={ (e) => updateByObj('close', e.target.value )} disabled={  !currentValue.checked } name='closingTime'  className="form-control"  />
      </Col>
    </Row>
  );
};

export default DayTimePicker;