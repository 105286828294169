import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequestForm, postRequest } from "../../components/Common/Utils.js";



export default function JpSetting() {

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  let history = useHistory();

  const JpGet = async () => {
    setLoader(true);
    try{
    const response = await postRequest(`admin/jp-get`, {});
    const data = response;
    setData(data.data)
   console.log(data)
    }catch(error){
      console.log("Failed to fetch data:", error)
    }finally{
    setLoader(false);
    }
  };
  useEffect(() => {
    JpGet();
  }, []);

  const JpSettingform = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    let formData=Object.fromEntries(data.entries())
   console.log(formData)
    const response = await postRequest("admin/jp-create/", formData);
    console.log({response})
    if (response.status == 1) {
      toast.success(response.message);
      JpGet()
    } else {
      toast.error(response.message);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div claaName="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Settings</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Settings</li>:{" "}
              <li className="breadcrumb-item">JP Settings</li>
            </ol>
          </div>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loader?
              <CardBody style={{height : "100px"}}>
              <Spinner
                  color="info"
                  style={{
                    height: '4rem',
                    width: '4rem',
                    position : "absolute",
                    left: "50%"
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            :
            <CardBody>
              <h4 className="card-title"> JP Credentials</h4>

              <Form onSubmit={JpSettingform}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">User</label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                          
                            defaultValue={data.email||""}
                            name="email"
                            className="form-control"
                          />
                        </div>
                      </div>




                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          Password
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="password"
                            defaultValue={data.pass||""}
                            name="pass"
                            className="form-control"
                            id="horizontal-password-input"
                            required="required"
                          />
                          <br />
                        
                        </div>
                      </div>

               
                      <div className="mt-5 mt-lg-4">
                        <div className="row justify-content-end">
                          <div className="col-sm-9">
                            <div>
                              <button
                                type="submit"
                                className="btn btn-primary w-md"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            }
          </Card>
        </Col>
      </Row>

    
    </React.Fragment>
  );
}
