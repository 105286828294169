import React, { Component } from 'react';
import { Table, Card, Badge, Button } from "reactstrap";
import { getRequest } from "../../components/Common/Utils.js";

//Import Images
import user1 from "../../assets/images/users/user-1.jpg";
import user2 from "../../assets/images/users/user-2.jpg";
import user3 from "../../assets/images/users/user-3.jpg";
import user4 from "../../assets/images/users/user-4.jpg";
import user5 from "../../assets/images/users/user-5.jpg";
import user6 from "../../assets/images/users/user-6.jpg";

class LatestOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {          
            events : []
        }
    }

    componentDidMount(){
        this.fetchData();
    };

    fetchData = async () => {
        const response = await getRequest(`event/recentAdded`);
        const data = response.data;

        this.setState({
           events : data
        });
       
    }

    render() {
        return (
            <React.Fragment>
                <Card>
                    <div className="card-body">
                        <h4 className="card-title mb-4">Latest Events</h4>

                        <div className="table-responsive">
                        <Table className="align-middle table-centered table-vertical table-nowrap">
                                <thead className="table-light">
                                            <tr>
                                            <th>Squad 1</th>
                                            <th>Squad 2</th>
                                            <th>Venue</th>                                           
                                            <th>Date & Time</th>  
                                                                         
                                            </tr>
                                        </thead>
                                <tbody>
                                    {
                                        this.state.events.map((transaction, key) =>
                                            <tr key={key}>
                                                {/* <td>#{key + 1}</td> */}
                                               
                                                
                                                
                                                <td>
                                                    <img src={transaction.squadInfo.squadImage} alt="user" className="avatar-xs rounded-circle me-2" />  {transaction.squadInfo.squadName}
                                                     
                                                </td>

                                                <td>
                                                    <img src={transaction.competitorInfo.squadImage} alt="user" className="avatar-xs rounded-circle me-2" />  {transaction.competitorInfo.squadName}
                                                     
                                                </td>  

                                                <td>
                                                    {transaction.venue.name}
                                                     
                                                </td>   

                                                 <td>
                                                    {transaction.date + ' ' + transaction.time}
                                                        
                                                    </td> 
                                                {/* <td>
                                                    <a href={"venuedetails/"+ transaction.venue._id }><Button color="secondary" size="sm" className="waves-effect waves-light">View</Button></a>
                                                </td> */}
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Card>
            </React.Fragment>
        );
    }
}

export default LatestOrders;