import React, { useState, useEffect, useRef } from "react";
import { useHistory,useParams, useLocation  } from "react-router-dom";
import { Row, Col} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {  postRequestForm , postRequest} from "../../components/Common/Utils.js";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import './Chat.css';
import socketIOClient from "socket.io-client";
import Lightbox from "react-image-lightbox";
import ContactList from './ContactList';

import {
  MainContainer,
  ChatContainer,
  MessageList, 
  Message,
  MessageInput,Avatar
} from "@chatscope/chat-ui-kit-react";

export default function CreateVideo() {
  const location = useLocation();
  const fileInputRef = useRef(null);
  const state = location.state?.row;


  const [socket, setSocket] = useState(null);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [image, setImage] = React.useState("");

  const [videoData, setVideoData] = useState(null); 
  const [previewImage, setPreviewImage] = useState(""); 
  const [loading, setLoading] = useState(false);  
  const [selectedImage, setSelectedImage] = useState(""); 
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [model, setModel] = useState(false);
  const [contacts, setContacts] = useState([
   
  ]);

  const [channel, setChannel] = useState(null);
  const channelRef = useRef();
  channelRef.current = channel;
  const [selectedContacts, setSelectedContacts] = useState(null);
  let history = useHistory();
  const { id } = useParams(); 
  const [messages, setMessages] = useState([]);
  useEffect(() => {
  const newSocket = socketIOClient('https://soket.yalatorapp.com');
  setSocket(newSocket);

  newSocket.on("agent_contacts_loaded", (contactsData) => {
     console.log("agent_contacts_loaded", contactsData)
     setContacts(contactsData);
  });



  newSocket.on("message_history", (channelData) => {
    console.log("message_history", channelData)
    setMessages(channelData);
  });

  console.log({messages})
  newSocket.on("agent_new_message", (msgData) => { 
    console.log("channelRef", channelRef.current);
    console.log("channelData.channelId", msgData.channelId);
    console.log("new_msg_avilable1", msgData);
  
    if(msgData.channelId == channelRef.current){
      console.log("new_msg_avilable", msgData);
  
      // fetch current time in hour and minute
      const sentTime = new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });
      const msgObject = {
        direction: "incoming",
        content: msgData.msg,
        avatar: msgData.member[1].image,
        msgType: msgData.msgType,
        sender: sentTime,
        status : msgData.status
      };  

      setContacts(prevContacts => {
        return prevContacts.map(contact => {
          if (contact._id === msgData.channelId) {
            return {
              ...contact,             
              lastMessage: msgData.msg  // updating the lastMessage for the contact
            };
          }
          return contact;
        });
      });

      setMessages(prevMessages => [...prevMessages, msgObject]);

  
    } else {
  
      // Assuming you have a state called contacts and a function to update it called setContacts.
      setContacts(prevContacts => {
        return prevContacts.map(contact => {
          if (contact._id === msgData.channelId) {
            return {
              ...contact,
              newMessageCount: contact.newMessageCount ? contact.newMessageCount + 1 : 1,
              lastMessage: msgData.msg  // updating the lastMessage for the contact
            };
          }
          return contact;
        });
      });


    } 
  });


  return () => {
      newSocket.disconnect();  
    }
}, []);

useEffect(() => {
  if (state) {

    console.log("state", state)

    let status = contacts.find((contact) => contact._id == state._id);
    if(status){
      state.status = status.status;
    }else{
      state.status = "offline";
    }

    const contactNew = {
      _id: state._id,
      userName: state.userName,
      userImage: state.userImage ? state.userImage : `https://ui-avatars.com/api/?name=${state.userName}`,
      status:  "online"
    }

    setSelectedContacts(contactNew);
    setChannel(state._id);
  }
}, [state]);

useEffect(() => {
  if (socket) {    
      const agentId = id.split("-")[0];
      socket.emit("init_chat_agent", agentId);
  }
}, [socket]);

useEffect(() => {
  if(!channel) return;  
    socket.emit("join_channel",  channel);  
}, [channel]);

const onselectContact = (contact) => {
  console.log("onselectContact", contact)
  setSelectedContacts(contact);
  setChannel(contact._id);
};

const handleImageUpload = async (event) => {
  const file = event.target.files[0];
  if (file) {      
      const formData = new FormData();
      formData.append("file", file);
      const response = await postRequestForm("homepage/upload/image", formData);
      let image  = response.data.url  
      
      const sentTime = new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });
      setMessages([...messages, { direction: "outgoing", content: image, avatar: "https://ui-avatars.com/api/?name=Sumit+Hariyani", sender:sentTime, msgType: 'image' }]);
      const message = {
          channelId: channel,
          messageType: "image",      
          message: image       
      };
    
      console.log("message", message)
    
      // update the contacts list with the new message
    
      setContacts(prevContacts => {
        return prevContacts.map(contact => {
          if (contact._id === channel) {
            return {
              ...contact,
              lastMessage: "Image"  // updating the lastMessage for the contact
            };
          }
          return contact;
        });
      });
    
      socket.emit("agent_push_message", message);
     
  }
};


const handleSendMessage = (content) => {
  const sentTime = new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });
  setMessages([...messages, { direction: "outgoing", content, avatar: "https://ui-avatars.com/api/?name=Sumit+Hariyani", sender:sentTime, msgType: 'text' }]);
  const message = {
      channelId: channel,
      messageType: "text",      
      message: content         
  };

  console.log("message", message)

  // update the contacts list with the new message

  setContacts(prevContacts => {
    return prevContacts.map(contact => {
      if (contact._id === channel) {
        return {
          ...contact,
          lastMessage: content  // updating the lastMessage for the contact
        };
      }
      return contact;
    });
  });

  socket.emit("agent_push_message", message);
  // TODO: send this message to the backend or through WebSocket to the other user
}; 

  const Createvideo = async (e) => {
    e.preventDefault();
    if (!venueId) {
      toast.error("Please select venue");
      return;
    }
    if (!videoData.title) {
      toast.error("Please enter title");
      return;
    }
    if (!videoData.description) {
      toast.error("Please enter description");
      return;
    }
    if (!selectedImage) {
      toast.error("Please select video");
      return;
    }
   setLoading(true);  
    const formData = new FormData();
    formData.append("venueId", venueId); 
    formData.append("title", videoData.title);
    formData.append("description", videoData.description); 
    formData.append("videoUrl", selectedImage);        
    const response = await postRequestForm("home-block/video-component/create", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/videos");
      }, 1000);
      
    } else {
      toast.error(response.message);
    }
  };


  const updateMessage = () => {
    try {
      const response =  postRequest(`chat/update-message-status`, {chatId : id});
      const data = response.data;
      // window.location.href = data.url;
      console.log("data", data);
    } catch (error) {
      console.log("Failed to fetch data:", error);
    } 
  }

  useEffect(() => {
    updateMessage();
  },)

  const updateValue = (index, value) => {
    const list = {...videoData}
    list[index] = value
    setVideoData(list)    
  } 

  const handleAttachClick = () => {
    // Programmatically trigger the file input when the attach icon is clicked.
    fileInputRef.current.click();
  };

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

 
 

  return (
    <React.Fragment>
      <ToastContainer />
      <Row>
        <Col lg={12}>  
              <div className="row">

                <div className="col-lg-3" style={{ background: "#fff", height: "87vh" }}>
                  <ContactList contacts={contacts} onselectContact={onselectContact} />
                </div>

                <div className="col-lg-9" style={{ height: "80vh" }}>

                  { selectedContacts && ( <div className="chatHeader">
                    <Avatar src={ selectedContacts.userImage ? selectedContacts.userImage : `https://ui-avatars.com/api/?name=${selectedContacts.userName}`  } name={ selectedContacts?.userName} />
                    <div className="userInfo">
                        <div className="userName">{ selectedContacts?.userName}</div>
                        {/* <div className="userStatus">
                            <span className={ selectedContacts?.status == "online" ? "statusCircle online" : "statusCircle offline" }></span>
                            { selectedContacts?.status}
                        </div> */}
                    </div>
                  </div>)}                 
                <MainContainer>
                {selectedContacts && (<ChatContainer>
                  <MessageList>
                  {messages.map((msg, idx) => {
                    if (msg.msgType === 'text') {
                      return (
                        <Message 
                          key={idx} 
                          model={{ 
                            message: msg.content, 
                            direction: msg.direction,                            
                            sender: msg.sender,
                            sentTime: msg.sentTime,
                            avatar: <Avatar src={msg.avatar} name={msg.sender || "Me"} />
                          }} 
                        />
                      );
                    } else if (msg.msgType === 'image') {
                      return (
                        <Message
                          key={idx}
                          model={{
                            direction: msg.direction,
                            sentTime: msg.sentTime,
                            sender: msg.sender,
                            payload: (
                              <Message.CustomContent>
                                <div onClick={() => showLightBox(msg.content)}>
                                  <img src={msg.content} alt="Sent image" style={{ width: "100px", cursor: "pointer" }} />
                                </div>
                              </Message.CustomContent>
                            ),
                            avatar: <Avatar src={msg.avatar} name={msg.sender || "Me"} />
                          }}
                        />
                      );
                    }
                    // Handle other types as needed
                    return null;
                  })}


                  </MessageList>
                  <MessageInput 
                      placeholder="Type a message here..." 
                      onSend={content => handleSendMessage(content)}
                      onAttachClick={handleAttachClick}
                  />
                  </ChatContainer>)}
                
                </MainContainer>
                 </div> 

              </div> 
        </Col>
      </Row>
          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={handleImageUpload}
          />

          {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />)
        }

    </React.Fragment>
  );
}
