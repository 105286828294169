import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";

import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequestForm,
  putRequest,
  postRequestForm,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";

import DealDragModal from "./DealDragModal";

import moment from "moment";
const filterObj = {

};
const DealManagementlist = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [bannerdata, setbannerdata] = useState([]);
  const [value, setValue] = React.useState(10);
  const [sort, setsort] = React.useState("orderNo");
  const [sortOrder, setsortOrder] = React.useState("asc");
  const [searchText, setSearchText] = useState('');
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [filter, setFilter] = useState({ ...filterObj });
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [loader2, setLoader2] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [canCreate, setCanCreate] = useState(true);
  const [canEdit, setCanEdit] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canExport, setCanExport] = useState(true);

  useEffect(() => {
    bannerManagment();
  }, [currentPage, sizePerPageOrg, sort, sortOrder]);

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
        console.log("page", page);
      }
    }

    if (type === "search") {
      console.log("searchText", searchText);
    }
  };

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value == 1 ? 1 : 0;

    const formData = new FormData();
    formData.append("dealId", actId);
    formData.append("status", status);

    const res = await putRequestForm("deal/", formData);

    if (res.status == 1) {
      toast.success("Banner Status updated Successfully");
      bannerManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const getFilters = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {

        var filterObject = {
          [key]: filter[key],
        };


        if (key === "title") {
          filterObject.type = "regex";
        } else if (key === "homeBlockType") {
          filterObject.type = "eq";
        }
        else if (key === "sizeId") {
          filterObject.type = "obj-eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });
    return filterArgs;
  };


  function activeFormatter(cellContent, row) {
    return (
      <>
        {row.status == 1 ? (
          <>
            <button
              className="btn btn-active"
              type="button"
              onClick={() => GetValueOnChange((row.status = false), row._id)}
            >
              Active
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-deactive"
              type="button"
              onClick={() => GetValueOnChange((row.status = true), row._id)}
            >
              Inactive
            </button>
          </>
        )}
      </>
    );
  }
  function MediaFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.image}
          onClick={() => showLightBox(row.image)}
          alt="Image"
          width={"50px"}
        />
      </>
    );
  }

  function selectedFormatter(cellContent, row) {
    return (
      <div style={{ display: 'flex', gap: '10px' }}>
        <img
          src={row.selectedImage}
          onClick={() => showLightBox(row.selectedImage)}
          alt="Image"
          width={"50px"}
          style={{ borderRadius: '20%' }} // This makes the image round
        />
        <p>{row.selectedName}</p>
      </div>
    );
  }

  function redirectToPaymentGatewayFormatter(cellContent, row) {
    return (
      <>
        {row.redirectToPaymentGateway == 1 ? (
          <>

            <button

              className="btn btn-active"
              type="button"

            >
              Yes
            </button>
          </>
        ) : (
          <>

            <button
              className="btn btn-deactive"
              type="button"

            >
              No
            </button>
          </>
        )}
      </>
    );
  }

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }
  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "title",
      text: "Title",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div
            style={{
              maxHeight: '5em',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              // cursor: 'pointer'
            }}
            title={row.title}
          >
            {row.title}
          </div>
        );
      }
    },
    // {
    //   dataField: "titleFrench",
    //   text: "Title(French)",
    //   sort: false,
    // },
    // {
    //   dataField: "titleGerman",
    //   text: "Title(German)",
    //   sort: false,
    // },

    {
      dataField: "description",
      text: "Description",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div
            style={{
              maxHeight: '5em',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              // cursor: 'pointer'
            }}
            title={row.description}
          >
            {row.description}
          </div>
        );
      }
    },
    {
      dataField: "image",
      text: "Image",
      formatter: MediaFormatter,
      sort: false,
    },

    {
      dataField: "amount",
      text: "Amount",
      sort: false,
    },

    {
      dataField: "finalAmount",
      text: "Final Amount",
      sort: false,
    },


    {
      dataField: "type",
      text: "Type",
      sort: false,
    },

    {
      dataField: "discountText",
      text: "Discount Text ",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div
            style={{
              maxHeight: '5em',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              // cursor: 'pointer'
            }}
            title={row.discountText}
          >
            {row.discountText}
          </div>
        );
      }
    },
    // {
    //   dataField: "discountTextFrench",
    //   text: "Discount (French) ",
    //   sort: false,
    // },
    // {
    //   dataField: "discountTextGerman",
    //   text: "Discount(German) ",
    //   sort: false,
    // },
    // {
    //   dataField: "description",
    //   text: "Description (English) ",
    //   sort: false,
    // },
    // {
    //   dataField: "descriptionFrench",
    //   text: "Description (French) ",
    //   sort: false,
    // },
    // {
    //   dataField: "descriptionGerman",
    //   text: "Description (German)  ",
    //   sort: false,
    // },

    {
      dataField: "startDate",
      text: "Start Date",
      formatter: dateStartFormatter,
      sort: false,
    },

    {
      dataField: "endDate",
      text: "End Date",
      formatter: dateEndFormatter,
      sort: false,
    },


    {
      dataField: "status",
      text: "Status",
      formatter: activeFormatter,
      sort: false,
    },

    {
      dataField: "createdAt",
      text: "Created At",
      formatter: dateFormatter,
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];

  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  }
  const handlesorting = (event) => {
    setsort(event.target.value);
  }
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload =async () => {
    setLoader(true);
    setSearchText('')
    const response = await postRequest(`deal/list`, {
      page: 1,
      limit: limit,
      sortField: sort,
      sortOrder: sortOrder,
    });
    const data = response.data;
    setTotalPage(data.count);
    setCurrentPage(data.page);
    setbannerdata(data.deals);

    setLoader(false);
  };


  function dateFormatter(cellContent, row) {
    return (
      <React.Fragment>
        {moment(row.createdAt).format("MMM Do YY")}
      </React.Fragment>
    );
  }

  function dateStartFormatter(cellContent, row) {
    return (
      <React.Fragment>
        {moment(row.startDate).format("MMM Do YY")}
      </React.Fragment>
    );
  }

  function dateEndFormatter(cellContent, row) {
    return (
      <React.Fragment>
        {moment(row.endDate).format("MMM Do YY")}
      </React.Fragment>
    );
  }


  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex" >
          {canEdit && (
            <button type="button" className=" btn btn-info  mx-2">
              <Link
                to={{
                  pathname: `/updateDeal/${row._id}`,
                  state: { row },
                }}
              >
                <i className="fal fa-pencil fs-5 text-light"></i>
              </Link>
            </button>
          )}

          {canDelete && (
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => handlerdelete(row._id)}
            >
              <i className="fal fa-trash  fs-5"></i>
            </button>
          )}
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const limit = 100000000;
  const exportCSV = async () => {
    setLoader(true);

    const filterArgs = getFilters();
    const payload = {
      sortField: 'createdAt',
      sortOrder: 'desc',
    };

    // if (filterArgs?.length) {
    //   payload.filterArgs = filterArgs;
    // } else {
    //   if (searchText) {
    //     payload.search = searchText;
    //   }
    // }

    try {
      const response = await postRequest(`deal/export-csv`, payload);
      const data = response.data;
      window.location.href = data.url;
      console.log("data", data);
    } catch (error) {
      console.log("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }


  }
  const bannerManagment = async (page = 0) => {
    setLoader(true);
    try {
      const response = await postRequest(`deal/list`, {
        page: page,
        limit: limit,
        search: searchText,
        sortField: sort,
        sortOrder: sortOrder,
      });
      const data = response.data;
      setTotalPage(data.count);
      setCurrentPage(data.page);
      setbannerdata(data.deals);
    } catch (error) {
      console.log("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`deal/`, {
          dealId: id,
        });
        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          bannerManagment();
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };

  const updateDealData = async (data) => {
    setbannerdata(data);
    setShowOrder(false);

    const response = await postRequest(`deal/update-order`, { data: data });
    console.log("data-response", response);
  }

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Deal Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Deal Management</li>:
                <li className="breadcrumb-item">Deal List</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-4 text-end">
            <Button onClick={() => setShowOrder(true)} className="btn btn-info" style={{ marginRight: '10px' }}>
              <i className="fas fa-grip-vertical" ></i> Order
            </Button>
            {canExport && (
              <button onClick={() => { exportCSV() }} type='button' className='btn btn-primary mx-2'><i class="fas fa-upload"></i> Export CSV</button>
            )}
            {canCreate && (
              <Link to="/createDeal">
                <Button>
                  <i className="fas fa-plus"></i> Create
                </Button>
              </Link>
            )}
            &nbsp;&nbsp;&nbsp;
          </div>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: bannerdata?.length,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={bannerdata}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className='col-lg-12 d-flex mb-3'>

                                  <label className='mb-0 text-label'>Page Size</label>

                                  <select value={value} onChange={handleChange} className="form-select" style={{ width: "7%" }}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                  </select>

                                  <label className='mb-0 text-label ms-3'>Sort By:</label>
                                  <select value={sort} onChange={handlesorting} className="form-select" style={{ width: "10%" }}>
                                    <option value="orderNo">Order No</option>
                                    <option value="title">Title</option>
                                    <option value="type">Type</option>
                                    <option value="startDate">Start Date</option>
                                    <option value="endDate">End Date</option>
                                    <option selected value="createdAt">CreatedAt</option>
                                  </select>

                                  <label className='mb-0 text-label ms-3'>Sort Order:</label>
                                  <select value={sortOrder} onChange={handlesortOrder} className="form-select" style={{ width: "10%" }}>
                                    <option value="desc">DESC</option>
                                    <option value="asc">ASC</option>
                                  </select>

                                  <div className='filter-item'>
                                    <input type="search" placeholder='Search' value={searchText} className="form-control" onChange={(e) => setSearchText(e.target.value)} style={{ width: "30%" }} />
                                    <button onClick={() => { bannerManagment() }} type='button' className='btn btn-primary mx-2'>Search</button>
                                    <button type='button' onClick={handleReload} className='btn btn-danger'>Reset</button>
                                  </div>
                                </Col>

                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    // onTableChange={handleTableChange}
                                    remote={false}
                                    classes="table-striped"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}




                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}

        <DealDragModal show={showOrder} closeModal={() => setShowOrder(false)} data={bannerdata} setData={updateDealData} />


      </React.Fragment>
    </>
  );
};

export default DealManagementlist;
