import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const AgentMiddleware = ({
  component: Component,
  layout: Layout,
  isAuthAgent,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      const authType = localStorage.getItem("authType");

      // Check if route requires agent authentication and the user isn't an agent
      if (isAuthAgent && authType !== "agent") {
        return (
          <Redirect
            to={{ pathname: "/agent/login", state: { from: props.location } }}
          />
        );
      }

      // Otherwise render the requested component
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

AgentMiddleware.propTypes = {
  isAuthAgent: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default AgentMiddleware;