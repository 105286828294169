import React, { useState } from 'react';
import { Collapse, CardBody, Card, Input, Label } from 'reactstrap';

const ModuleAccessControl = ({ initialAccessSchema, onUpdate }) => {
  const [moduleAccessSchema, setModuleAccessSchema] = useState(initialAccessSchema);

  const handleParentCheckboxChange = (moduleIndex, allow) => {
    let newModuleAccess = [...moduleAccessSchema];
    newModuleAccess[moduleIndex].allow = allow;
    
    // If parent is checked, check the first child and expand. If unchecked, uncheck and collapse all children.
    newModuleAccess[moduleIndex].pageAccess = newModuleAccess[moduleIndex].pageAccess.map((page, pageIndex) => ({
      ...page,
      allow: allow && pageIndex === 0 ? true : false
    }));
    
    setModuleAccessSchema(newModuleAccess);
    onUpdate(newModuleAccess); // Send updated data back to the parent component.
  };

  const handleChildCheckboxChange = (moduleIndex, pageIndex, allow) => {
    let newModuleAccess = [...moduleAccessSchema];
    newModuleAccess[moduleIndex].pageAccess[pageIndex].allow = allow;

    // If the first child is unchecked, uncheck and collapse the parent as well.
    if (pageIndex === 0 && !allow) {
      newModuleAccess[moduleIndex].allow = false;
    }
    
    setModuleAccessSchema(newModuleAccess);
    onUpdate(newModuleAccess); // Send updated data back to the parent component.
  };

  return (
    <>
      {moduleAccessSchema.map((module, moduleIndex) => (
        <Card 
          style={{ marginBottom: '1rem' }} 
          key={moduleIndex}
          className={module.allow ? 'highlight-parent' : ''}
        >
          <CardBody>
            <div className="custom-checkbox" style={{ marginRight: "8px"  }}>
              <Input
                type="checkbox"
                checked={module.allow}
                onChange={(e) => handleParentCheckboxChange(moduleIndex, e.target.checked)}
                style={{ marginRight: "12px"  }}
                disabled={module.readOnly} 
              />
              <Label onClick={() => handleParentCheckboxChange(moduleIndex, !module.allow)}>
                {module.module}
              </Label>
            </div>
            <Collapse isOpen={module.allow}>
              {module.pageAccess.map((page, pageIndex) => (
                <div key={pageIndex} className="custom-checkbox"  style={{ marginLeft: "16px"  }}>
                  <Input
                    type="checkbox"
                    checked={page.allow}
                    onChange={(e) => handleChildCheckboxChange(moduleIndex, pageIndex, e.target.checked)}
                    style={{ marginRight: "12px"  }}
                    disabled={page.readOnly} 
                  />
                  <Label>{page.title}</Label>
                </div>
              ))}
            </Collapse>
          </CardBody>
        </Card>
      ))}
    </>
  );
};

export default ModuleAccessControl;
