import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";
import MultiselectCommon from "../../components/Multiselect/MultiselectCommon.js";
import {
  Row,
  Col,
  Form,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
// import "../../assets/scss/multi-select.scss";
import "../../assets/scss/multi-select.scss";
import moment from "moment";
import { getRequest, postRequest, postRequestForm } from "../Common/Utils.js";
import FileUpload from "../../components/Common/FileUpload.js";


const CustomTicketform = ({
  SelectformData,
  updateTicketpData,
  closeTicketModal,
  selectedTicketoption,
  selectedIndex,
}) => {
  const [ticket, setTicket] = useState([]);
  const [previewImage, setPreviewImage] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [ImageUrl, setImageUrl] = useState([null]);
  const [defaultDays, setDefaultDays] = useState([]);
  const [loader, setLoader] = useState(false);
  const [fileUploadLoader, setFileUploadLoader] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);

  // useEffect(() => {
  //   if (!selectedImage) return;
  //   Imageupload();
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     setPreviewImage((oldImage) => {
  //       const updatedImage = Array.isArray(oldImage)
  //         ? [...oldImage, reader.result]
  //         : [reader.result];
  //       return updatedImage;
  //     });
  //   };
  //   reader.readAsDataURL(selectedImage);
  // }, [selectedImage]);

  const CreateCustomTicketform = async (e) => {
    e.preventDefault();
    if (!ticket?.title) {
      toast.error("Tittle is required");
      return;
    }
    if (!ticket?.description) {
      toast.error("Description is required");
      return;
    }
    if ( imageUrls.length == 0) {
      toast.error("Please select at least one Image");
      return;
    }
    let imageData = imageUrls.filter((item) => item != null);
    let formdata = {
      title: ticket?.title,
      _id: ticket?._id,
      description: ticket?.description,
      images: imageData,
      days: selectedDayIds,
      startDate: ticket?.startDate,
      endDate: ticket?.endDate,
      totalSeats:
        ticket?.availabilityType == "regular" ? ticket?.totalSeats : "",
      availabilityTime:
        ticket?.availabilityType == "regular"
          ? `${ticket?.start}-${ticket?.end}`
          : "",
      amount: ticket?.amount,
      amountForChild: ticket?.amountForChild,
      availabilityType: ticket?.availabilityType,
      availabilityTimeSlot: ticket?.availabilityType == "slot" ? timeSlots : [],
    };
    console.log(formdata);
    // return false;
    SelectformData(formdata);
    console.log(formdata);
    closeTicketModal();
  };

  const [selectedDay, setSelectedDay] = useState([]);
  const [selectedDayIds, setSelectedDayIds] = useState([]);
  const [dayList, setDayList] = useState([]);

  const msUpdateDay = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedDayIds(ids);
    console.log(ids);
  };
  useEffect(() => {
    const days = [
      // { label: "Monday", value: "mon" },
      { id: "tue", name: "Tuesday" },
      { id: "wed", name: "Wednesday" },
      { id: "thu", name: "Thursday" },
      { id: "fri", name: "Friday" },
      { id: "sat", name: "Saturday" },
      { id: "sun", name: "Sunday" },
    ];
    setDayList(days);
  }, []);

  useEffect(() => {
    if (selectedIndex !== null && selectedIndex !== "") {
      const selectedData = updateTicketpData || [];
      setTicket((prevTicket) => ({
        ...prevTicket,
        ...selectedData,
        start: selectedData?.availabilityTime
          ? selectedData.availabilityTime.split("-")[0]
          : "",
        end: selectedData?.availabilityTime
          ? selectedData.availabilityTime.split("-")[1]
          : "",
      }));
      console.log(selectedData?.images);
      let images=selectedData?.images||[]
      setImageUrls(images);
      // setPreviewImage(selectedData?.images);
      setSelectedDay(selectedData?.days);
      setTimeSlots(selectedData?.availabilityTimeSlot);
    }
  }, [selectedIndex, updateTicketpData, selectedDay]);

  useEffect(() => {
    if (selectedDay?.length > 0) {
      const defaultItems = dayList?.filter((item) => {
        return selectedDay?.includes(item.id);
      });
      setSelectedDayIds(defaultItems.map((item) => item.id));
      setDefaultDays(defaultItems);
    }
  }, [selectedDay]);

  const updateValue = (key, value) => {
    setTicket((prevTicket) => ({
      ...prevTicket,
      [key]: value,
    }));
  };

  const Imageupload = async (e) => {
    const formData = new FormData();
    setFileUploadLoader(true)
    formData.append("file", e.target.files[0]);
    // formData.append("file", selectedImage);
    const response = await postRequestForm("homepage/upload/image", formData);
    let image = response.data.url;
    setFileUploadLoader(false)
    console.log({ image });
    setImageUrls([...imageUrls, image]);
  };

  const RemoveImage = (index) => {
    // console.log({ url });
    // let image = previewImage.filter((i, j) => j !== index);
    let UrlImage = imageUrls.filter((i, j) => j !== index);
    // setPreviewImage(image);
    setImageUrls([...UrlImage]);
  };

  //   selected date
  // add multipler slot
  const [timeSlots, setTimeSlots] = useState([]);
  const addSlot = () => {
    const initialTimeSlots = timeSlots || [];
    setTimeSlots([
      ...initialTimeSlots,
      { availabilityTime: "00:00-00:00", totalSeats: 0 },
    ]);
  };
  const updateSlot = (index, type, value) => {
    const updatedSlots = [...timeSlots];
    if (type === "availabilityTime") {
      updatedSlots[index][type] = value;
    } else {
      updatedSlots[index][type] = value;
    }
    setTimeSlots(updatedSlots);
  };

  const deleteSlot = (index) => {
    const updatedSlots = [...timeSlots];
    updatedSlots.splice(index, 1);
    setTimeSlots(updatedSlots);
  };
  return (
    <React.Fragment>
      <ToastContainer />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title"> Create Custom Ticket</h4>

              <Form onSubmit={CreateCustomTicketform}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Title</label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            onChange={(e) =>
                              updateValue("title", e.target.value)
                            }
                            value={ticket?.title}
                            name="title"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Description
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            onChange={(e) =>
                              updateValue("description", e.target.value)
                            }
                            defaultValue={ticket?.description}
                            name="description"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          File
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="file"
                            onChange={(e) =>
                              Imageupload(e)
                            }
                            // required='required'
                            name="mediaUrl"
                            className="form-control"
                            id="horizontal-password-input"
                          />
                          <br />
                          <div className="row">
                            {imageUrls?.map((image, index) => (
                              <div className="d-flex align-items-center flex-column col-md-3">
                                <img
                                  key={index}
                                  src={image}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    padding: "5px",
                                  }}
                                  alt={`Preview ${index + 1}`}
                                />

                                <button
                                  type="button"
                                  className="btn btn-sm btn-danger"
                                  onClick={() => RemoveImage(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Select Days
                        </label>
                        <div className="col-sm-6">
                          <div className="multi-selected">
                            <MultiselectCommon
                              options={dayList}
                              onSelect={msUpdateDay}
                              onRemove={msUpdateDay}
                              selectedValues={defaultDays}
                              placeholder="Select Days"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Start Date
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="date"
                            className="form-control"
                            defaultValue={
                              ticket?.startDate
                                ? moment(ticket.startDate).format("YYYY-MM-DD")
                                : ""
                            }
                            id="horizontal-password-input"
                            name="startDate"
                            min={new Date().toISOString().split("T")[0]}
                            onChange={(e) =>
                              updateValue("startDate", e.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          End Date
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="date"
                            className="form-control"
                            id="horizontal-password-input"
                            min={ticket?.startDate}
                            defaultValue={
                              ticket?.endDate
                                ? moment(ticket.endDate).format("YYYY-MM-DD")
                                : ""
                            }
                            name="endDate"
                            onChange={(e) =>
                              updateValue("endDate", e.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Availability Type
                        </label>

                        <div className="col-sm-6">
                          <select
                            value={ticket?.availabilityType}
                            className="form-control"
                            onChange={(e) =>
                              updateValue("availabilityType", e.target.value)
                            }
                            name="availabilityType"
                            class="form-select"
                          >
                            <option value="">Select</option>
                            <option value="regular">Regular</option>
                            <option value="slot">Multiple Slot</option>
                          </select>
                        </div>
                      </div>

                      {ticket?.availabilityType == "regular" && (
                        <>
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Total Seats
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="number"
                                min={0}
                                onChange={(e) =>
                                  updateValue("totalSeats", e.target.value)
                                }
                                value={ticket?.totalSeats}
                                name="totalSeats"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="row mb-4">
                            <label
                              htmlFor="horizontal-firstname-input"
                              className="col-sm-3 col-form-label"
                            >
                              Start Time / End Time
                            </label>
                            <div className="col-sm-3">
                              <label>Start Time:</label>
                              <input
                                type="time"
                                min="00:00"
                                defaultValue={ticket?.start}
                                onChange={(e) =>
                                  updateValue("start", e.target.value)
                                }
                                className="form-control"
                              />
                            </div>
                            <div className="col-sm-3">
                              <label>End Time:</label>
                              <input
                                type="time"
                                defaultValue={ticket?.end}
                                min={ticket?.start || "00:00"}
                                onChange={(e) =>
                                  updateValue("end", e.target.value)
                                }
                                className="form-control"
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {ticket?.availabilityType == "slot" && (
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Select Slots
                          </label>

                          <div className="col-sm-8">
                            {timeSlots?.map((slot, index) => (
                              <div className="col-sm-12" key={index}>
                                <div
                                  class="row"
                                  style={{ marginBottom: "16px" }}
                                >
                                  <div className="col-lg-3 px-1">
                                    <input
                                      type="time"
                                      min="00:00"
                                      className="form-control"
                                      value={
                                        slot.availabilityTime.split("-")[0]
                                      }
                                      onChange={(e) => {
                                        const newStartTime = e.target.value;
                                        const newEndTime =
                                          slot.availabilityTime.split("-")[1];
                                        if (newEndTime < newStartTime) {
                                          updateSlot(
                                            index,
                                            "availabilityTime",
                                            `${newStartTime}-${newStartTime}`
                                          );
                                        } else {
                                          updateSlot(
                                            index,
                                            "availabilityTime",
                                            `${newStartTime}-${newEndTime}`
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-3 px-1">
                                    <input
                                      type="time"
                                      min={slot.availabilityTime.split("-")[0]}
                                      className="form-control"
                                      value={
                                        slot.availabilityTime.split("-")[1]
                                      }
                                      onChange={(e) => {
                                        const newEndTime = e.target.value;
                                        const newStartTime =
                                          slot.availabilityTime.split("-")[0];
                                        if (newEndTime < newStartTime) {
                                          updateSlot(
                                            index,
                                            "availabilityTime",
                                            `${newStartTime}-${newStartTime}`
                                          );
                                        } else {
                                          updateSlot(
                                            index,
                                            "availabilityTime",
                                            `${newStartTime}-${newEndTime}`
                                          );
                                        }
                                      }}
                                    />
                                  </div>

                                  <div className="col-lg-3 px-1">
                                    <input
                                      type="number"
                                      className="form-control"
                                      min={0}
                                      value={slot.totalSeats}
                                      onChange={(e) =>
                                        updateSlot(
                                          index,
                                          "totalSeats",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>

                                  <div className="col-sm-3">
                                    <button
                                      type="button"
                                      style={{
                                        color: "white",
                                        backgroundColor: "red",
                                        border: "none",
                                        padding: "10px 20px",
                                      }}
                                      onClick={() => deleteSlot(index)}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}

                            <button
                              type="button"
                              style={{
                                color: "white",
                                backgroundColor: "green",
                                border: "none",
                                padding: "10px 20px",
                              }}
                              onClick={addSlot}
                            >
                              Add Slot
                            </button>
                          </div>
                        </div>
                      )}

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Amount
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="number"
                            min={0}
                            onChange={(e) =>
                              updateValue("amount", e.target.value)
                            }
                            value={ticket?.amount}
                            name="amount"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Amount For Child
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="number"
                            min={0}
                            onChange={(e) =>
                              updateValue("amountForChild", e.target.value)
                            }
                            value={ticket?.amountForChild}
                            name="amountForChild"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="mt-5 mt-lg-4">
                        <div className="row justify-content-end">
                          <div className="col-sm-9">
                            <div>
                              {loader ? (
                                <>
                                  <Spinner color="info"></Spinner>
                                  <p>Image Uploading Please Wait...</p>
                                </>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <FileUpload message="File Uploading" status={fileUploadLoader} />

    </React.Fragment>
  );
};

const CustomTicketModal = ({
  show,
  closeModal,
  SelectformData,
  updateTicketpData,
  selectedTicketoption,
  selectedIndex,
}) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Custom Ticket
        </h5>
        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <CustomTicketform
          selectedTicketoption={selectedTicketoption}
          SelectformData={SelectformData}
          updateTicketpData={updateTicketpData}
          closeTicketModal={closeModal}
          selectedIndex={selectedIndex}
        />
      </div>

    </Modal>
  );
};

export default CustomTicketModal;
