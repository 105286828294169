import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
// import rButton from "reactstrap/button";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import {
  deleteRequest,
  putRequest,
  postRequest,
  putRequestForm,
  RBButton,
  deleteConfirmationWords,
} from "components/Common/Utils";
import { useParams, useLocation } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { format } from "date-fns";
// import StarComponent from "./StarComponent";

import Lightbox from "react-image-lightbox";

import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Avatar,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import LocationPicker from "react-location-picker";
// import DayTimePicker from "./DayTimePicker";

import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import "./CustomCard.css";
// import RoomListTable from "./RoomTable";
import PriceTable from "./PriceTable";
import MemberTable from "./MemberTable";
import PolicyTable from "./PolicyTable";
import BookingInfoTable from "./BookingInfoTable";
// import VenueSpecialOfferListTable from "./VenueSpecialOfferList";
// import VenueClaimsListTable from "./VenueClaimsTable";
// import VenueNotificationListTable from "./VenueNotificationTable";
// import VenueReviewListTable from "./VenueReviewTable";
// import VenueFollowListTable from "./VenueFollowingTable";
// import VenueCheckinTable from "./VenueCheckinTable";
// import VenueActiveStoryListTable from "./VenueActiveStoryTable";
// // import VenueStoryListTable from "./VenueStoryTable";
// import VenueAdListTable from "./VenueAdsTable";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

const defaultPosition = {
  lat: 33.6706,
  lng: 36.0227,
};

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default function Bookingdetails({ venueId, venueName = "" }) {
  const location = useLocation();
  const state = location.state?.row;
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [venueData, setData] = useState(state);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [loader, setLoader] = useState(false);
  const [image, setImage] = React.useState("");
  const [images, setImages] = React.useState([]);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightboxGallery, setOpenLightBoxGallery] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedReview, setSelectedReview] = React.useState(null);

  const [policyTab, setPolicyTab] = useState([]);
  const [priceTab, setPriceTab] = useState([]);
  const [infoTab, setInfoTab] = useState([]);

  const [textReply, setTextReply] = React.useState("");
  const [line, setLine] = useState([]);
  console.log("venueData", line)
  const handleMusicSelectionChange = (newSelectedMusic) => {
    const oldMusic = [...selectedMusic];
    if (newSelectedMusic.length > oldMusic.length) {
      const addedMusic = newSelectedMusic.filter(
        (newItem) => !oldMusic.some((oldItem) => olditem?._id === newitem?._id)
      );
      if (addedMusic.length > 0) {
        setSelectedMusic(newSelectedMusic);
        handlerToggle("venue/music/toggle", {
          venueId: id,
          musicId: addedMusic[0]?._id,
        });
      }
    } else if (newSelectedMusic.length < oldMusic.length) {
      const removedMusic = oldMusic.filter(
        (oldItem) =>
          !newSelectedMusic?.some((newItem) => newitem?._id === olditem?._id)
      );
      if (removedMusic.length > 0) {
        setSelectedMusic(newSelectedMusic);
        handlerToggle("venue/music/toggle", {
          venueId: id,
          musicId: removedMusic[0]?._id,
        });
      }
    }
  };

  const handleThemeSelectionChange = (newSelectedTheme) => {
    const oldTheme = [...selectedTheme];
    if (newSelectedTheme.length > oldTheme.length) {
      const addedTheme = newSelectedTheme.filter(
        (newItem) => !oldTheme.some((oldItem) => olditem?._id === newitem?._id)
      );
      if (addedTheme.length > 0) {
        setSelectedTheme(newSelectedTheme);
        handlerToggle("venue/theme/toggle", {
          venueId: id,
          themeId: addedTheme[0]._id,
        });
      }
    } else if (newSelectedTheme.length < oldTheme.length) {
      const removedTheme = oldTheme.filter(
        (oldItem) =>
          !newSelectedTheme.some((newItem) => newitem?._id === olditem?._id)
      );
      if (removedTheme.length > 0) {
        setSelectedTheme(newSelectedTheme);
        handlerToggle("venue/theme/toggle", {
          venueId: id,
          themeId: removedTheme[0]?._id,
        });
      }
    }
  };

  const handleCuisineSelectionChange = (newSelectedCuisine) => {
    const oldCuisine = [...selectedCuisine];
    if (newSelectedCuisine.length > oldCuisine.length) {
      const addedCuisine = newSelectedCuisine.filter(
        (newItem) => !oldCuisine.some((oldItem) => olditem?._id === newitem?._id)
      );
      if (addedCuisine.length > 0) {
        setSelectedCuisine(newSelectedCuisine);
        handlerToggle("venue/cuisine/toggle", {
          venueId: id,
          cuisineId: addedCuisine[0]?._id,
        });
      }
    } else if (newSelectedCuisine.length < oldCuisine.length) {
      const removedCuisine = oldCuisine.filter(
        (oldItem) =>
          !newSelectedCuisine?.some((newItem) => newitem?._id === olditem?._id)
      );
      if (removedCuisine.length > 0) {
        setSelectedCuisine(newSelectedCuisine);
        handlerToggle("venue/cuisine/toggle", {
          venueId: id,
          cuisineId: removedCuisine[0]?._id,
        });
      }
    }
  };

  const handleOpen = (review) => {
    setSelectedReview(review);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
        console.log("page", page);
      }
    }

    console.log("type", type);

    if (type === "search") {
      console.log("searchText", searchText);
    }
  };

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div >
          <button type="button" className=" btn btn-primary text-light">
            {" "}
            <Link
              to={{
                pathname: `/bookingLinedetails`,
                state: { row, id },
              }}
            >
              {" "}
              <i className="fal fa-eye text-light"></i>
            </Link>{" "}
          </button>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  function lineAtdata(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <p>{moment(row?.LineDate).format("DD-MM-YYYY")} </p>
        </div>
      </React.Fragment>
    );
  }
  function BeignAtdata(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <p>{moment(row?.BeginTravelDate).format("DD-MM-YYYY")} </p>
        </div>
      </React.Fragment>
    );
  }
  function EndAtdata(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <p>{moment(row?.EndTravelDate).format("DD-MM-YYYY")} </p>
        </div>
      </React.Fragment>
    );
  }

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    // {     
    //   text: "Hotel",
    //   sort: false,
    //   formatter: hotelFormatter,
    // },
    {
      dataField: "IdBookLine",
      // formatter: userFormatter,
      text: "Book-ID",
      sort: false,
    },
    {
      dataField: "ServiceName",
      text: "Service Name",
      sort: false,
    },
    {
      dataField: "ProductTypeName",
      text: "Product Type Name",
      sort: false,
    },
    {
      dataField: "LineDate",
      text: "Line Date",
      formatter: lineAtdata,
      sort: false,
    },
    {
      dataField: "BeginTravelDate",
      text: "Begin Travel Date",
      formatter: BeignAtdata,
      sort: false,
    },
    {
      dataField: "Status",
      text: "Status",

      sort: false,
    },
    {
      dataField: "EndTravelDate",
      text: "End Travel Date",
      formatter: EndAtdata,
      sort: false,
    },
    // {
    //   dataField: "createdAt",
    //   text: "Created At",
    //   formatter: createdAtdata,
    //   sort: false,
    // },
    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];



  const handleFeatureSelectionChange = (newSelectedFeature) => {
    const oldFeature = [...selectedFeature];
    if (newSelectedFeature?.length > oldFeature.length) {
      const addedFeature = newSelectedFeature?.filter(
        (newItem) => !oldFeature?.some((oldItem) => olditem?._id === newitem?._id)
      );
      if (addedFeature.length > 0) {
        setSelectedFeature(newSelectedFeature);
        handlerToggle("venue/feature/toggle", {
          venueId: id,
          featureId: addedFeature[0]?._id,
        });
      }
    } else if (newSelectedFeature?.length < oldFeature.length) {
      const removedFeature = oldFeature.filter(
        (oldItem) =>
          !newSelectedFeature?.some((newItem) => newitem?._id === olditem?._id)
      );
      if (removedFeature.length > 0) {
        setSelectedFeature(newSelectedFeature);
        handlerToggle("venue/feature/toggle", {
          venueId: id,
          featureId: removedFeature[0]._id,
        });
      }
    }
  };

  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const useStyles = makeStyles({
    tableHeader: {
      color: "#ffffff", // Use your preferred color
      backgroundColor: "#3f51b5", // Header background color
      fontWeight: "bold",
    },
    descriptionText: {
      fontSize: "0.8rem",
    },
    deleteIcon: {
      color: "red",
    },
    roundImage: {
      borderRadius: "50%",
      width: "75px",
      height: "75px",
    },
    roundImageSm: {
      borderRadius: "50%",
    },

    gridList: {
      width: "100%",
    },
    icon: {
      color: "rgba(255, 55, 25, 0.54)",
    },

    // galleryImage: {
    //   width: "100%",
    // },
  });

  const breakpointColumnsObj = {
    default: 3, // adjust number of columns here
    1100: 3,
    700: 2,
    500: 1,
  };

  const classes = useStyles();

  const { id } = useParams();

  console.log("--->>>", { id })

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const VenueFetchDetail = async () => {

    // setLoader(false);
    postRequest(`cron/booking-detail`, { bookId: `${id}` }).then((data) => {
      let venueData = data.data;
      let line = venueData?.Lines || [];
      console.log("test--api--hit", venueData)
      //let status = data.status;
      setData(venueData);
      setLine(line);

      const policyData = fetchPolicyData(venueData);
      const priceData = venueData?.type == "transfer" ? venueData?.transfers?.map(transfer => transfer?.price) : venueData?.type == "hotel" ? venueData?.hotels?.map(hotel => hotel.price) : venueData?.tickets?.map(ticket => ticket.price);
      const infoData = fetchInfoData(venueData);

      setPolicyTab(policyData);
      setPriceTab(priceData);
      setInfoTab(infoData);


      console.log("venueData");
      console.log(venueData);

      setLoader(false);
    });
  };
  useEffect(() => {
    VenueFetchDetail();
  }, []);

  const fetchPolicyData = (venueData) => {
    if (venueData?.type == "transfer") {
      const policyData = venueData?.transfers?.map((item) => {
        const plObj = {
          description: item?.cancellationPolicy.Description,
          name: item?.transferInfo.Name,
        }
        return plObj;
      });
      return policyData;
    }

    if (venueData?.type == "hotel") {
      const policyData = venueData?.hotels?.map((item) => {
        const plObj = {
          description: item?.cancellationPolicy.Description,
          name: item?.hotelInfo?.Name,
        }
        return plObj;
      });
      return policyData;
    }

    if (venueData?.type == "ticket") {
      const policyData = venueData?.tickets?.map((item) => {
        const plObj = {
          description: item?.cancellationPolicy?.Description,
          name: item?.ticketInfo?.Name,
        }
        return plObj;
      });
      return policyData;
    }
  }

  const fetchInfoData = (venueData) => {
    if (venueData?.type == "transfer") {
      const policyData = venueData?.transfers?.map((item) => {
        const plObj = {
          description: item?.transferInfo?.Description,
          name: item?.transferInfo?.Name,
          image: item?.transferInfo.Images?.length > 0 ? item?.transferInfo.Images[0].Image : "",
          code: item?.transferDetails?.Code,
          start: item?.transferDetails?.Start,
          end: item?.transferDetails?.End,
          status: item?.transferDetails?.Status,
        }
        return plObj;
      });
      return policyData;
    }

    if (venueData?.type == "hotel") {
      const policyData = venueData?.hotels?.map((item) => {
        const plObj = {
          name: item?.hotelInfo?.Name,
          hotelCategory: item?.hotelInfo?.HotelCategory,
          hotelType: item?.hotelInfo?.Type,
          address: item?.hotelInfo?.Address,
          code: item?.hotelInfo?.Code,
          start: item?.hotelDetails?.Start,
          end: item?.hotelDetails?.End,
          status: item?.hotelDetails?.Status,
        }
        return plObj;
      });
      return policyData;
    }

    if (venueData?.type == "ticket") {
      const policyData = venueData?.tickets?.map((item) => {
        const plObj = {
          description: item?.ticketInfo?.Description,
          name: item?.ticketInfo?.Name,
          image: item?.ticketInfo?.Images?.length > 0 ? item?.ticketInfo?.Images[0].Image : "",
          code: item?.ticketDetails?.Code,
          start: item?.ticketDetails?.Start,
          end: item?.ticketDetails?.End,
          status: item?.ticketDetails?.Status,
        }
        return plObj;
      });
      return policyData;
    }
  }

  const Updatevenue = async (e) => {
    const formData = new FormData();
    formData.append("venueId", id);

    if (timeOptions && timeOptions.length > 0) {
      timeOptions.forEach((item, index) => {
        if (item?.checked) {
          formData.append(`days[]`, days[index].toString().toLowerCase());
          formData.append(`open_time[]`, item?.open);
          formData.append(`close_time[]`, item?.close);
        }
      });
    }

    const response = await putRequestForm("venue/update", formData);

    if (response.status == 1) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;
    setTimeOptions(newTimeOptions);
  };

  const handlerdelete = (id, url, obj, key) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`${url}`, obj);

        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          // remove from venueData
          const vData = { ...venueData };
          vData[key] = vData[key].filter((item) => item?._id !== id);
          setData(vData);
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };

  const handlerdelete2 = (id, file) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id, file);
      }
    });
  };

  const showRandomWordDialog = (id, file) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords?.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteGalleryFile(id, file);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteGalleryFile = async (id, file) => {
    const response = await deleteRequest(`venue/gallery/delete`, {
      venueId: id,
      file: file,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      TransferFetchDetail();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const handlerToggle = async (url, obj) => {
    await deleteRequest(`${url}`, obj);
  };

  const updateReply = async () => {
    const response = await putRequest("rating/reply/update", {
      ratingId: selectedReview._id,
      reply: textReply,
    });

    // update in venueData as well
    const selReview = selectedReview;

    if (selReview.reply?.reply) {
      selReview.reply.reply = textReply;
    } else {
      selReview.reply = { reply: textReply };
    }

    setSelectedReview(selReview);

    setOpen(false);

    if (response.status == 1) {
      toast.success("reply Updated");
    } else {
      toast.error(response.message);
    }
  };

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const showLightBoxGallery = (imgSrc) => {

    const index = images.indexOf(imgSrc);
    setPhotoIndex(index !== -1 ? index : 0);
    setOpenLightBoxGallery(true);
  }


  const {

    userName,
    locator,
    status,
    userData,
    type,
    comment,
    externalBookingReference,
    createdAt
  } = venueData || {};

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reply to Review</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your reply to the review here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Reply"
            type="text"
            fullWidth
            multiline
            defaultValue={
              selectedReview?.reply?.reply ? selectedReview?.reply?.reply : ""
            }
            onChange={(e) => {
              setTextReply(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={updateReply} color="primary">
            Reply
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
      <Row>
        <Col className="10">
          <div className="page-title-box">
            <h4>Booking Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Booking Management</li>:{" "}
              <li className="breadcrumb-item">Booking details</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.back()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>

        {loader ? (
          <CardBody style={{ height: "100px" }}>
            <Spinner
              color="info"
              style={{
                height: "4rem",
                width: "4rem",
                position: "absolute",
                left: "50%",
              }}
            >
              Loading...
            </Spinner>
          </CardBody>
        ) : (
          <>
            <Card className="custom-card">
              {/* <div
                className="cover-image"
                style={{ backgroundImage: `url(${coverImage})` }}
                onClick={() => showLightBox(coverImage)}
              ></div> */}
              <div
                className="cover-image"
                style={{ backgroundColor: "rgb(29, 155, 240)" }}
              ></div>
              <div className="profile-image">
                <img
                  src={`https://ui-avatars.com/api/?background=random&name=${userName}`}
                  onClick={() => showLightBox(`https://ui-avatars.com/api/?background=random&name=${userName}`)}
                  alt="Image Not Available"
                />

              </div>
              <br></br>

              <div className="row my-2 py-2">
                <div
                  className="userinfo col-md-12 "
                  style={{ paddingLeft: "20px" }}
                >
                  <div className="fst">
                    {" "}
                    <h3> {userName} </h3>{" "}
                  </div>
                  <div className="row">

                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Email: <span> {venueData?.Holder?.Email}</span>{" "}
                      </p>{" "}
                    </div>
                    {/* <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Nationality: <span> {venueData?.Holder?.nationality}</span>{" "}
                      </p>{" "}
                    </div> */}


                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Phone: <span> {venueData?.Holder?.Phone1}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-4 fst py-1">
                      <p>
                        {" "}
                        Booking Type:
                        <span> {type} </span>
                      </p>
                    </div>
                    <div className="col-md-4 fst py-1">
                      <p>
                        {" "}
                        External Booking Reference:
                        <span> {externalBookingReference} </span>
                      </p>
                    </div>

                    <div className="col-md-4 fst py-1">
                      <p>
                        {" "}
                        Booking Code:
                        <span> {venueData?.BookingCode} </span>
                      </p>
                    </div>
                    <div className="col-md-4 fst py-1">
                      <p>
                        {" "}
                        Booking Status:
                        <span> {venueData?.Status} </span>
                      </p>
                    </div>
                    <div className="col-md-4 fst py-1">
                      <p>
                        {" "}
                        Description
                        <span> {venueData?.Description} </span>
                      </p>
                    </div>
                  </div>
                  <hr />

                  <div className="row">
                    <div className="col-md-12 fst py-1">
                      <p>
                        {" "}
                        Comment: <span> {comment}</span>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <Col className="col-12">
              <Card>
                {loader ? (
                  <CardBody style={{ height: "100px" }}>
                    <Spinner
                      color="info"
                      style={{
                        height: "4rem",
                        width: "4rem",
                        position: "absolute",
                        left: "50%",
                      }}
                    >
                      Loading...
                    </Spinner>
                  </CardBody>
                ) : (
                  <CardBody className="table-shorting">
                    {
                      <PaginationProvider
                        pagination={paginationFactory({
                          page: currentFPage,
                          sizePerPage: sizePerPageOrg,
                          totalSize: totalPage,
                          custom: true,
                        })}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <>
                            <ToolkitProvider
                              keyField="_id"
                              data={line}
                              columns={columns}
                            >
                              {(props) => (
                                <Row>
                                  {/* <Col className="col-lg-12 d-flex mb-3">
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>
                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ width: "7%" }}
                                  >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                  </select>

                                  <label className="mb-0 text-label ms-3">
                                    Sort By:
                                  </label>
                                  <select
                                    value={sort}
                                    onChange={handlesorting}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                   
                                    <option value="user">
                                      {" "}
                                      User
                                    </option>
                                    <option value="comment">Comment</option>
                                    <option value="externalBookingReference">Booking Reference</option>
                                    <option value="locator">Locator</option>
                                    <option value="type">Type</option>
                                    <option value="status">Status</option>
                                    <option value="createdAt">Created At</option>
                                  </select>
                                  <label className="mb-0 text-label ms-3">
                                    Sort Order:
                                  </label>
                                  <select
                                    value={sortOrder}
                                    onChange={handlesortOrder}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="asc"> Ascending</option>
                                    <option value="desc">Descending</option>
                                    
                                  </select>
                                  <div className="filter-item">
                                    <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      style={{ width: "30%" }}
                                    />
                                    <button
                                      onClick={() => {
                                        ratingManagment();
                                      }}
                                      type="button"
                                      className="btn btn-primary mx-2"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="button"
                                      onClick={handleReload}
                                      className="btn btn-danger mx-2"
                                    >
                                      Reset
                                    </button>

                                    <button
                                      type="button"
                                      onClick={toggleModel}
                                      className="btn btn-info"
                                    >
                                      Filter
                                    </button>
                                  </div>
                                </Col> */}

                                  <Col className="col-lg-12 text-end">
                                    <BootstrapTable
                                      onTableChange={handleTableChange}
                                      remote={true}
                                      classes="table-striped"
                                      {...props.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </Col>
                                </Row>
                              )}
                            </ToolkitProvider>

                            <Row>
                              <Col className="col-lg-12 text-end">
                                <div className="text-md-right">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </PaginationProvider>
                    }
                  </CardBody>
                )}
              </Card>
            </Col>
            {/* <Col md="12">
              <div
                id="exTab2"
                className="twitter-like-tabs2"
                style={{ background: "#ffff", marginLeft: "25px" }}
              >
                <ul
                  className="nav nav-pills"
                  style={{ width: "480px", display: "contents" }}
                >
                  <li className="active">
                    <a href="#location" data-toggle="tab">
                      {" "}
                      Location{" "}
                    </a>
                  </li>
               
                  <li className="active">
                    <a href="#members" data-toggle="tab">
                      {" "}
                      Members{" "}
                    </a>
                  </li>                

                  <li>
                    <a href="#offers" data-toggle="tab">
                      {" "}
                      Rooms
                    </a>
                  </li>                

                  <li>
                    <a href="#gallery" data-toggle="tab">
                      {" "}
                      Gallery{" "}
                    </a>
                  </li>                 

                  <li >
                    <a href="#price" data-toggle="tab">
                      {" "}
                      Price{" "}
                    </a>
                  </li>
                  <li >
                    <a href="#policy" data-toggle="tab">
                      {" "}
                      Policy{" "}
                    </a>
                  </li>
                  <li >
                    <a href="#bookinginfo" data-toggle="tab">
                      {" "}
                      Booking Info{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </Col> */}
            {/* <Col md="12">
              <div className="tab-content clearfix">               
             

                <div className="tab-pane padded-tab" id="features">              
                  <div className="tab-pane padded-tab" id="cuisine">
                      <div className="card feature-card">
                          <div className="row">
                              {
                                  venueData?.features?.map((feature, index) => (
                                      <div key={index} className="col-md-3 fst py-1">
                                          <ul className="feature-list">
                                              <li className="feature-item">
                                                  {feature}
                                              </li>
                                          </ul>
                                      </div>
                                  ))
                              }
                          </div>
                      </div>
                  </div>
              </div>



              <div className="tab-pane  padded-tab" id="price">
               {venueData?._id  && (
                    <PriceTable
                      priceTab={priceTab}
                    />
                  )}
                </div>    
                <div className="tab-pane  padded-tab" id="policy">
               {venueData?._id  && (
                    <PolicyTable
                    policyTab={policyTab}
                    />
                  )}
                </div>    

                <div className="tab-pane  padded-tab" id="bookinginfo">
               {venueData?._id  && (
                    <BookingInfoTable
                      infoTab={infoTab} 
                      type={venueData?.type}                   
                    />
                  )}
                </div>    

                <div className="tab-pane active padded-tab" id="members">
               {venueData?._id  && (
                    <MemberTable
                       members={venueData?.members}
                    />
                  )}
                </div>                 
               
              </div>
            </Col> */}
          </>
        )}

        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}




      </Row>
      <ToastContainer />{" "}
    </>
  );
}
