import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
import { makeStyles } from "@material-ui/core/styles";
import {
  postRequest,
  deleteRequest,
  putRequestForm,
  deleteConfirmationWords,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const filterObj = {
  title: "",
  type: "",
  category: "",
  status: "",
  createdAt: "",
};

const Iconlist = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [icondata, seticondata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [value, setValue] = React.useState(10);
  const [selectedItems, setselectedItems] = useState([]);
  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(12);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [model, setModel] = useState(false);
  const [showload, setShowLoad] = useState(false)
  const [filter, setFilter] = useState({ ...filterObj });

  useEffect(() => {
    iconManagment(false);
  }, [currentPage]);

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value == 1 ? 1 : 0;

    const formData = new FormData();
    formData.append("iconId", actId);
    formData.append("status", status);

    const res = await putRequestForm("activity/icon/update", formData);

    if (res.status == 1) {
      toast.success("Icon Status updated Successfully");
      iconManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };
  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
  };

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const applyFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        if (key === "venue_name") {
          var filterObject = {
            "venue.name": filter["venue_name"],
          };
        } else {
          var filterObject = {
            [key]: filter[key],
          };
        }

        if (key === "title") {
          filterObject.type = "regex";
        } else if (key === "status") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });
    toggleModel();
    iconManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    iconManagment();
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }
  function loadMore(){
    setCurrentPage(currentPage+1)
  }

  function activeFormatter(cellContent, row) {
    return (
      <>
        {row.status == 1 ? (
          <>
            <button
              className="btn btn-active"
              type="button"
              onClick={() => GetValueOnChange((row.status = false), row._id)}
            >
              Active
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-deactive"
              type="button"
              onClick={() => GetValueOnChange((row.status = true), row._id)}
            >
              Inactive
            </button>
          </>
        )}
      </>
    );
  }
  function MediaFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.image}
          onClick={() => showLightBox(row.image)}
          alt="Image"
          width={"50px"}
        />
      </>
    );
  }
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }
  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Title</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.title}
          onChange={(e) => updateFilter("title", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Status</label>
      <div className="col-sm-6">
        <select
          onChange={(e) => updateFilter("status", e.target.value)}
          className="form-select"
          value={filter.status}
        >
          <option value="">Select Status</option>
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,
   

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "title",
      text: "Title",
      sort: false,
    },

    {
      dataField: "image",
      text: "Image",
      formatter: MediaFormatter,
      sort: false,
    },

    {
      dataField: "status",
      text: "Status",
      formatter: activeFormatter,
      sort: false,
    },

    {
      dataField: "createdAt",
      text: "Created At",
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];
  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    window.location.reload();
  };
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div>
        
          <button
            type="button"
            marginLeft="25px"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id)}
          >
            <i class="fal fa-trash  fs-5"></i>
          </button>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const limit = 10;
  const useStyles = makeStyles({
    tableHeader: {
      color: "#ffffff", 
      backgroundColor: "#3f51b5", 
      fontWeight: "bold",
    },
    descriptionText: {
      fontSize: "0.8rem",
    },
    deleteIcon: {
      color: "#ec536c",
    },
    viewIcon: {
      color: "green",
    },
    roundImage: {
      borderRadius: "50%",
      width: "100px",
      height: "100px",
    },
    roundImageSm: {
      borderRadius: "50%",
    },
  });

  const classes = useStyles();

  const iconManagment = async (reset = true) => {
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
    };
   
    try{
    const response = await postRequest(`admin/icon-list`, payload);
    const data = response.data;
    setTotalPage(data.count);

    if(data?.list?.length){
      let iconCurrent = []
      if(!reset){
        iconCurrent = [...data.list];
      }
      seticondata(iconCurrent)

      if(iconCurrent?.length >= data?.count ){ 
        setShowLoad(false)
      }else{    
        setShowLoad(true)
      }

    } }catch(error){
      console.error("Failed to fetch data:", error);
    }finally{
    setLoader(false);
  }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id);
      }
    });
  };

  const showRandomWordDialog = (id) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteIcon(id);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteIcon = async (id) => {
    const response = await deleteRequest("admin/delete-icon", { iconId: id });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      iconManagment(false);
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };  

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Icon Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Icon Management</li>:{" "}
                <li className="breadcrumb-item">Icon List</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end">
            <Link to="/Create-Icon">
              {" "}
              <Button>
                {" "}
                <i class="fas fa-plus"></i> Create
              </Button>{" "}
            </Link>
            &nbsp;&nbsp;&nbsp;
          </div>
          <Col className="col-12">
            {loader ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
             <Row>
                {icondata?.map((item, index) => (
                  <Col key={item._id} xs={12} sm={6} md={4} lg={3} xl={2}>
                  <Card>
                  <CardBody>
                  
                    <img
                      src={item.image}
                      onClick={() => showLightBox(item.image)}
                      alt="img"
                      className="mb-3"
                      style={{ cursor: "pointer", height: "200px", width: "100%" }}
                    />
                   
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="text-truncate font-size-14">
                      {item.venueName}
                    </h5>
                      <Button color="danger" onClick={() => handlerdelete(item._id)}>
                        <i className="fal fa-trash fs-5"></i> 
                      </Button>
                    </div>
                  </CardBody>
                  </Card>
                  </Col>
                ))}
              </Row>
            )}
          </Col>
        </Row>
        { showload && (
          <Row className="justify-content-center">
              <Col xs={12} className="text-center">
                      <Button color="info" style={{ align: "center" }} onClick={ () => loadMore() }>
                        Load More
                      </Button>
              </Col>
          </Row>
      ) }
       
      </React.Fragment>
      {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
    </>
  );
};

export default Iconlist;
