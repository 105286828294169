import React from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Spinner,
    Badge,
    UncontrolledTooltip,
    Modal,
    Button,
  } from "reactstrap";
export default function CustomLoder() {

  return (
    <CardBody style={{ height: "100px" }}>
    <Spinner
      color="info"
      style={{
        height: "4rem",
        width: "4rem",
        position: "absolute",
        left: "50%",
      }}
    >
      Loading...
    </Spinner>
  </CardBody>
  )
}
