import React, { Component } from 'react';
import { Table, Card, CardBody, Button } from "reactstrap";
import { getRequest } from "../../components/Common/Utils.js";

//Import Images
import user2 from "../../assets/images/users/user-2.jpg";
import user3 from "../../assets/images/users/user-3.jpg";
import user4 from "../../assets/images/users/user-4.jpg";
import user5 from "../../assets/images/users/user-5.jpg";
import user6 from "../../assets/images/users/user-6.jpg";

class LatestTransactions extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            squads : []
        }
    }

    componentDidMount(){
        this.fetchData();
    };

    fetchData = async () => {
        const response = await getRequest(`squad/recentAdded`);
        const data = response.data;

        this.setState({
           squads : data
        });

        console.log(data);
    }



    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Latest Squads</h4>
                        <div className="table-responsive">
                            <Table className="align-middle table-centered table-vertical table-nowrap">
                                <thead className="table-light">
                                    <tr>
                                       <th>Name</th>
                                       <th>Level</th>
                                       <th>Owner</th>
                                       <th>Players</th>
                                       <th></th>                                     
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.squads.map((transaction, key) =>
                                            <tr key={key}>
                                                {/* <td>#{key + 1}</td> */}
                                                <td>
                                                    <img src={transaction.squadImage} alt="user" className="avatar-xs rounded-circle me-2" /> {transaction.squadName}
                                                </td>
                                                <td><i className={"mdi mdi-checkbox-blank-circle text-success"}></i> {transaction.level}</td>
                                                <td>
                                                
                                                    <img src={transaction.owner.image} alt="user" className="avatar-xs rounded-circle me-2" /> {transaction.owner.first_name} 
                                                    
                                                   
                                                    
                                                    
                                                </td>

                                                <td>
                                                     {transaction.players.length }
                                                    
                                                </td>
                                                <td>
                                                    <a href={"squaddetails/"+ transaction._id }><Button color="secondary" size="sm" className="waves-effect waves-light">View</Button></a>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default LatestTransactions;