import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation  } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, FormGroup, Form, Table, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {  putRequestForm } from "../../components/Common/Utils.js";

export default function UpdateTime() {

  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;

  
  
  const [previewCover, setPreviewCover] = useState("");
  const [imageIcon, setImageIcon] = useState("");
  
  const [timeData, setTimeData] = useState();
  
  const [loading, setLoading] = useState(false);  

 
  const [selectedCover, setSelectedCover] = useState(""); 

  const actionOptions2 = [
    { label: "True", value: true },	
    { label: "False", value: false  },  
  ];

  const { id } = useParams();

  useEffect(() => {
    setTimeData(state);
    


    setImageIcon( state?.image ? state?.image : "" )
  }, [state])

 
  
  
  useEffect(() => {
    if(!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result)
      setImageIcon("");
    }
    reader.readAsDataURL(selectedCover)
  }, [selectedCover])
  
 
  
 
  

  const Updatetime = async (e) => {
    e.preventDefault();
   setLoading(true);
  
    const formData = new FormData();
    formData.append("id", id);
    formData.append("venueId",timeData.venueId);
    formData.append("day", timeData.day);   
    formData.append("openingTime", timeData.openingTime); 
    formData.append("closingTime", timeData.closingTime); 

    const response = await putRequestForm("venue/timing/update", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        // history goback 
        history.goBack();
      }, 1000);
      
    } else {
      toast.error(response.message);
    }  
    
  };

  const updateValue = (index, value) => {
    const list = {...timeData}
    list[index] = value
    setTimeData(list)
  } 
    
 
    

  return (
    <React.Fragment>

      <ToastContainer />

      <div claaName="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Time Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Time Management</li>:{" "}
              <li className="breadcrumb-item">
                Update Time
              </li>
            </ol>
          </div>
        </div>
      </div>
     
      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>

          { loading ? 
                <CardBody style={{height : "100px"}}>
                <Spinner
                    color="info"
                    style={{
                      height: '4rem',
                      width: '4rem',
                      position : "absolute",
                      left: "50%"
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
                :

            <CardBody>
              <h4 className="card-title"> Update Time </h4>
              <Form onSubmit={Updatetime}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">
                    <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Day</label>
                            <div className="col-sm-6">
                              <select value={timeData?.day} onChange={ (e) => updateValue('day', e.target.value) } name='day'  className="form-control">
                                  <option value="mon">Monday</option>
                                  <option value="tue">Tuesday</option>
                                  <option value="wed">Wednesday</option>
                                  <option value="thu">Thursday</option>
                                  <option value="fri">Friday</option>
                                  <option value="sat">Saturday</option>
                                  <option value="sun">Sunday</option>
                              </select>

                            </div>
                        </div>                         

                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Opening Time</label>
                            <div className="col-sm-6">
                            <input type="time" onChange={ (e) => updateValue('openingTime', e.target.value) }  value={timeData?.openingTime} name='openingTime'  className="form-control"  />
                            </div>
                        </div>      

                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Closing Time</label>
                            <div className="col-sm-6">
                            <input type="time" onChange={ (e) => updateValue('closingTime', e.target.value) } value={timeData?.closingTime} name='closingTime'  className="form-control"  />
                            </div>
                        </div>                 
                        
                        
                       
                       
                        <div className="mt-5 mt-lg-4">
                            <div className="row justify-content-end">
                            <div className="col-sm-9">
                            <div>
                                <button type="submit"  className="btn btn-primary w-md">Submit</button>
                            </div>
                            </div>
                            </div>
                        </div>

                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody> }
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
