import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequestForm,
  postRequest,
  putRequestForm,
} from "../../components/Common/Utils.js";
import HotelModal from "../../components/Modal/HotelModal.js";
import DealModal from "components/Modal/DealModal.js";
import TicketModal from "components/Modal/TicketModal.js";
import TransferModal from "components/Modal/TransferModal.js";
import CustomTicketlistModal from "components/Modal/CustomTicketlistModal.js";

// import VenueModal from "../../components/Modal/HotelModal.js";

export default function CreateBanner() {
  const location = useLocation();
  const state = location.state?.row;

  const [bannerData, setBannerData] = useState(null);
  const [previewImage, setPreviewImage] = useState("");

  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [model, setModel] = useState(false);
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");
  const [offerImage, setOfferImage] = useState("");
  const [offerModel, setOfferModel] = useState(false);
  const [activityId, setActivityId] = useState("");
  const [activityText, setActivityText] = useState("");
  const [activityImage, setActivityImage] = useState("");
  const [activityModel, setActivityModel] = useState(false);
  const [eventId, setEventId] = useState("");
  const [eventText, setEventText] = useState("");
  const [eventImage, setEventImage] = useState("");
  const [eventModel, setEventModel] = useState(false);

  const [selectedImage, setSelectedImage] = useState("");

  const [url, seturl] = useState("");

  let history = useHistory();

  useEffect(() => {
    console.log("state", state);

    if (state?.type == "hotel") {
      setVenueText(state?.selectedName);
      setVenueImage(state?.selectedImage);
    }

    if (state?.type == "deal") {
      setOfferText(state?.selectedName);
      setOfferImage(state?.selectedImage);
    }

    if (state?.type == "ticket") {
      setActivityText(state?.selectedName);
      setActivityImage(state?.selectedImage);
    }

    if (state?.type == "transfer") {
      setEventText(state?.selectedName);
      setEventImage(state?.selectedImage);
    }

    const bData = {
      type: state.type,
      title: state.title,
      titleGerman: state.titleGerman,
      titleFrench: state.titleFrench,
      redirectPayment: state.redirectToPaymentGateway,
    };

    setPreviewImage(state?.image);

    setBannerData(bData);
  }, [state]);

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const toggleModel = () => {
    console.log("toggleModel", model);
    setModel(!model);
  };

  const closeModal = () => {
    setModel(false);
  };
  const toggleOfferModel = () => {
    console.log("toggleOfferModel", offerModel);
    setOfferModel(!offerModel);
  };
  const closeOfferModal = () => {
    setOfferModel(false);
  };

  const toggleActivityModel = () => {
    console.log("toggleActivityModel", activityModel);
    setActivityModel(!activityModel);
  };
  const closeActivityModal = () => {
    setActivityModel(false);
  };

  const toggleEventModel = () => {
    console.log("toggleEventModel", eventModel);
    setEventModel(!eventModel);
  };
  const closeEventModal = () => {
    setEventModel(false);
  };

  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(id?.name);
    setVenueImage(id?.thumbImage);
    const formInput = { ...bannerData };
    formInput.item = id;
    formInput.subHeading = name;
    formInput.subText = venue?.address;
    formInput.buttonText = "View";
    formInput.logo = venue?.thumbImage;
    formInput.background = venue?.cover;
    setBannerData(formInput);
    // setPreviewLogo(venue?.image);
    //setPreviewImage(venue?.image);
    closeModal();
  };

  const selectOfferRow = (offer) => {
    setVenueId(offer);
    setOfferText(offer?.title);
    setOfferImage(offer?.image);
    const formInput = { ...bannerData };
    formInput.item = offer?._id;

    console.log("offer", offer, formInput);

    if (bannerData.title == "") formInput.title = offer?.title;
    if (bannerData.description == "")
      formInput.description = offer?.description;

    formInput.subHeading = offer?.venue?.name;
    formInput.subText = offer?.venue?.address;
    formInput.buttonText = "View";
    formInput.logo = offer?.venue?.logo;
    formInput.background = offer?.image;

    setBannerData(formInput);
    // setPreviewImage(offer?.image);
    // setPreviewLogo(offer?.venue?.logo);
    closeOfferModal();
  };

  const selectActivityRow = (id, name, activity) => {
    setVenueId(id);
    console.log({ id, name, activity });

    setActivityText(id?.title);
    setActivityImage(id?.images[0] || "");
    console.log("id", id);
    const formInput = { ...bannerData };
    formInput.item = id;
    if (bannerData.title == "") formInput.title = name;
    if (bannerData.description == "") formInput.description = id?.description;
    formInput.subHeading = id?.name;
    formInput.subText = id?.address;
    formInput.buttonText = "View";
    formInput.logo = id?.logo;
    formInput.background = id?.images?.length ? id?.images[0] : "";
    setBannerData(formInput);
    // setPreviewImage(activity?.galleries?.length ? activity?.galleries[0] : "");
    // setPreviewLogo(activity?.provider?.logo);
    closeActivityModal();
  };

  const selectEventRow = (id, name, event) => {
    setVenueId(id);
    setEventText(id?.name);
    setEventImage(id?.image);
    console.log("id", id);
    const formInput = { ...bannerData };
    formInput.item = id;
    if (bannerData.title == "") formInput.title = name;
    if (bannerData.description == "")
      formInput.description = event?.description;
    formInput.subHeading = event?.venue?.name;
    formInput.subText = event?.venue?.address;
    formInput.buttonText = "View";
    formInput.logo = event?.venue?.logo;
    formInput.background = event?.image;
    setBannerData(formInput);
    // setPreviewImage(event?.image);
    // setPreviewLogo(event?.venue?.logo);
    closeEventModal();
  };

  const UpdateBannerform = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const FormValues = Object.fromEntries(data.entries());
    // setLoading(true);
    const formData = new FormData();
    formData.append("bannerId", state?._id);
    formData.append("title", bannerData.title);
    formData.append("titleGerman", bannerData.titleGerman);
    formData.append("titleFrench", bannerData.titleFrench);
    formData.append("type", bannerData.type);
    formData.append(
      "redirectToPaymentGateway",
      bannerData.redirectPayment ? 1 : 0
    );

    if (url) {
      formData.append("image", url);
    }

    if (bannerData.type == "hotel") {
      if (venueId?._id) {
        formData.append("selectedId", venueId?._id);
      }
    }

    if (bannerData.type == "deal") {
      if (venueId?._id) {
        formData.append("selectedId", venueId?._id);
      }
    }

    if (bannerData.type == "ticket") {
      if (venueId?._id) {
        formData.append("selectedId", venueId?._id);
      }
    }

    if (bannerData.type == "transfer") {
      if (venueId?._id) {
        formData.append("selectedId", venueId?._id);
      }
    }

    console.log({ FormValues });

    const response = await putRequestForm("homepage/banner/", formData);
    // setLoading(false);
    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/banner");
      }, 1000);
    } else {
      toast.error(response.message);
    }
  };

  const Createbannerform = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("title", bannerData.title);
    formData.append("titleGerman", bannerData.titleGerman);
    formData.append("titleFrench", bannerData.titleFrench);
    formData.append("type", bannerData.type);
    formData.append("selectedId", venueId?._id);

    formData.append("image", url);

    const response = await postRequestForm("homepage/banner/", formData);
    console.log({ response });
    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/banner");
      }, 1000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...bannerData };
    list[index] = value;
    setBannerData(list);
  };

  const Imageupload = async (e) => {
    const formData = new FormData();
    formData.append("file", selectedImage);
    const response = await postRequestForm("homepage/upload/image", formData);
    let image = response.data.url;
    console.log({ image });
    seturl(image);
  };

  useEffect(() => {
    Imageupload();
  }, [selectedImage]);
  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row d-flex flex-row">
        <div className="col-md-10">
          <div className="page-title-box">
            <h4>Banner Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Banner Management</li>
              <li className="breadcrumb-item">Update Banner</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title"> Update Banner</h4>

              <Form onSubmit={UpdateBannerform}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Title (English)
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            onChange={(e) =>
                              updateValue("title", e.target.value)
                            }
                            value={bannerData?.title}
                            name="title"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Title (French)
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            onChange={(e) =>
                              updateValue("titleFrench", e.target.value)
                            }
                            value={bannerData?.titleFrench}
                            name="titleFrench"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Title (German)
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            onChange={(e) =>
                              updateValue("titleGerman", e.target.value)
                            }
                            value={bannerData?.titleGerman}
                            name="titleGerman"
                            className="form-control"
                          />
                        </div>
                      </div>
                      {/* <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Link</label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            onChange={(e) =>
                              updateValue("link", e.target.value)
                            }
                            value={bannerData?.link}
                            name="link"
                            className="form-control"
                          />
                        </div>
                      </div> */}
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Type</label>

                        <div className="col-sm-6">
                          <select
                            className="form-control"
                            onChange={(e) =>
                              updateValue("type", e.target.value)
                            }
                            value={bannerData?.type}
                            name="type"
                            class="form-select"
                          >
                            <option value="">Select</option>

                            <option value="deal">Deal</option>
                            <option value="transfer">Transfer</option>
                            <option value="ticket">Ticket</option>

                            <option value="hotel">Hotel</option>
                          </select>
                        </div>
                      </div>

                      {bannerData?.type == "ticket" && (
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Ticket
                          </label>
                          <div
                            className="col-sm-6"
                            onClick={() => toggleActivityModel()}
                          >
                            {activityText ? (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#7a6fbe",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleActivityModel()}
                                >
                                  Choose Ticket
                                </button>
                                {/* <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div> */}
                                {/* <img
                                    src={activityImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img> */}
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={activityText}
                                  id="selectedVenue"
                                  placeholder="No Ticket chosen"
                                />
                              </div>
                            ) : (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#7a6fbe",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleActivityModel()}
                                >
                                  Choose Ticket
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No Ticket chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {bannerData?.type == "deal" && (
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Deal
                          </label>
                          <div
                            className="col-sm-6"
                            onClick={() => toggleOfferModel()}
                          >
                            {offerText ? (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#7a6fbe",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseOfferBtn"
                                  onclick={() => toggleOfferModel()}
                                >
                                  Choose Deal
                                </button>
                                {/* <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div> */}
                                <img
                                  src={offerImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={offerText}
                                  id="selectedOffer"
                                  placeholder="No Deal chosen"
                                />
                              </div>
                            ) : (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#7a6fbe",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseOfferBtn"
                                  onclick={() => toggleOfferModel()}
                                >
                                  Choose Deal
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  value={offerText}
                                  id="selectedOffer"
                                  placeholder="No Deal chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {bannerData?.type == "transfer" && (
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Transfer
                          </label>
                          <div
                            className="col-sm-6"
                            onClick={() => toggleEventModel()}
                          >
                            {eventText ? (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#7a6fbe",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseEventBtn"
                                  onclick={() => toggleEventModel()}
                                >
                                  Choose Transfer
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={eventImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={eventText}
                                  id="selectedEvent"
                                  placeholder="No Custom Component chosen"
                                />
                              </div>
                            ) : (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#7a6fbe",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseEventBtn"
                                  onclick={() => toggleEventModel()}
                                >
                                  Choose Transfer
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  value={eventText}
                                  id="selectedEvent"
                                  placeholder="No Custom  Component chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {bannerData?.type == "hotel" && (
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Hotel
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => toggleModel()}
                          >
                            {venueText ? (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#7a6fbe",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseOfferBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Hotel
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={venueImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={venueText}
                                  id="selectedOffer"
                                  placeholder="No Hotel chosen"
                                />
                              </div>
                            ) : (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#7a6fbe",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseOfferBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Hotel
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  value={venueText}
                                  id="selectedOffer"
                                  placeholder="No Hotel chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          File
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="file"
                            onChange={(e) =>
                              setSelectedImage(e.target.files[0])
                            }
                            name="mediaUrl"
                            className="form-control"
                            id="horizontal-password-input"
                          />
                          <br />

                          <img
                            src={previewImage}
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                updateValue("redirectPayment", e.target.checked)
                              }
                              checked={bannerData?.redirectPayment || false}
                              id="redirectPaymentCheckbox"
                              className="form-check-input"
                            />
                            <label
                              htmlFor="redirectPaymentCheckbox"
                              className="form-check-label"
                            >
                              Redirect to Payment Gateway
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="mt-5 mt-lg-4">
                        <div className="row justify-content-end">
                          <div className="col-sm-9">
                            <div>
                              <button
                                type="submit"
                                className="btn btn-primary w-md"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* modal */}
      <HotelModal show={model} closeModal={closeModal} selectRow={selectRow} />
      <DealModal
        show={offerModel}
        closeModal={closeOfferModal}
        selectRow={selectOfferRow}
      />
      {/* <TicketModal
        show={activityModel}
        closeModal={closeActivityModal}
        selectRow={selectActivityRow}
      /> */}
      {/* CustomTicketlistModal */}

      <CustomTicketlistModal
        show={activityModel}
        closeModal={closeActivityModal}
        selectRow={selectActivityRow}
      />

      <TransferModal
        show={eventModel}
        closeModal={closeEventModal}
        selectRow={selectEventRow}
      />
    </React.Fragment>
  );
}
