import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequest,
  customValidator,
  putRequestForm,
} from "../../components/Common/Utils.js";

export default function AdminProfileUpdate() {
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [organizerData, setData] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [previewLogo, setPreviewLogo] = useState("");
  const [selectedLogo, setSelectedLogo] = useState("");
  const [completedCrop, setCompletedCrop] = useState(null);
  const [previewCover, setPreviewCover] = useState("");
  const [adminData, setAdminData] = useState(null);

  let history = useHistory();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const OrgFetchDetail = async () => {
    setLoading(false);
    postRequest(`admin/fetch-profile`, {}).then((data) => {
      setLoading(false); 
      let userdata = data.data;   
      console.log(data) 
      setAdminData(userdata);  
    });
  };
  useEffect(() => {
    OrgFetchDetail();
  }, []);
  const Updateeventorganizer = async (e) => {
    e.preventDefault();

    // const eventData = { ...adminData };
    // if (!eventData.name) {
    //   toast.error("Please enter event name");
    //   return;
    // }
    setLoading(true);
    
   

    const response = await postRequest("admin/update-profile", {
      ...adminData,
    });
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      eventManagment();
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...adminData };
    list[index] = value;
    setData(list);
    setAdminData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Profile Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Profile Management</li>:{" "}
              <li className="breadcrumb-item">Update Profile</li>
            </ol>
          </div>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4> Update Profile </h4>
                <Form onSubmit={Updateeventorganizer}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Name
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("name", e.target.value)
                              }
                              value={adminData?.name}
                              name="name"
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Last Name
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("last_name", e.target.value)
                              }
                              value={adminData?.last_name}
                              name="first_name"
                              className="form-control"
                            />
                          </div>
                        </div> */}

                       

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Phone
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("phone", e.target.value)
                              }
                              value={adminData?.phone}
                              name="phone"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Email
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="email"
                              onChange={(e) =>
                                updateValue("email", e.target.value)
                              }
                              value={adminData?.email}
                              name="email"
                              className="form-control"
                            />
                          </div>
                        </div>

                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Login id
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="loginId"
                              onChange={(e) =>
                                updateValue("loginId", e.target.value)
                              }
                              value={adminData?.loginId}
                              name="loginId"
                              className="form-control"
                            />
                          </div>
                        </div> */}

                       

                        

                        

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
