import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import logo from "../../assets/images/logo.png";

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useHistory } from "react-router-dom";
import { loginUser, apiError } from "../../store/actions";
// import images
import { postRequest } from "components/Common/Utils";

const Login = (props) => {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [reason, setReason] = useState("");
  const [userReason, setUserReason] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  async function submitRequest() {
    let data = { name, email, phone, reason, user_reason: userReason };
    if (rememberMe) {
      localStorage.setItem(
        "rememberedUser",
        JSON.stringify({ name, email, phone, reason, user_reason: userReason })
      );
    } else {
      localStorage.removeItem("rememberedUser");
    }

    postRequest("user/delete-request/add", data).then((data) => {
      let log = data;
      if (log.status) {
        toast.success(log.message);
        setTimeout(() => {
          history.push("/dashboard");
        }, 1000);
      } else {
        toast.error(log.message);
      }
    });
  }

  useEffect(() => {
    const rememberedUser = localStorage.getItem("rememberedUser");
    if (rememberedUser) {
      const { name, email, phone, reason, user_reason } = JSON.parse(rememberedUser);
      setName(name);
      setEmail(email);
      setPhone(phone);
      setReason(reason);
      setUserReason(user_reason);
      setRememberMe(true);
    }
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="text-center" style={{ padding: 20 }}>
                    <img src={logo} alt="" height="40" className="auth-logo-light" /> 
                  </div>
                  <div className="">
                    <h4 className="text-muted font-size-18 mb-1 text-center">
                      Delete Account Request!
                    </h4>
                    <p className="text-muted text-center">
                        Please submit the form, and our support team will be happy to assist you!
                    </p>
                    <AvForm className="form-horizontal mt-4" onSubmit={(e) => handleValidSubmit(e)}>
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="name"
                          label="Full Name"
                          value={name}
                          className="form-control"
                          placeholder="Enter Full Name"
                          type="text"
                          required
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          value={email}
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="phone"
                          label="Phone Number"
                          value={phone}
                          className="form-control"
                          placeholder="Enter phone number"
                          type="text"
                          required
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="reason">Select Reason</label>
                        <select
                          name="reason"
                          className="form-control"
                          required
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                        >
                          <option value="">Select Reason</option>
                          <option value="Privacy Concerns">Privacy Concerns</option>
                          <option value="Not Using the Service">Not Using the Service</option>
                          <option value="Created a New Account">Created a New Account</option>
                          <option value="Prefer a Different Service">Prefer a Different Service</option>
                          <option value="Security Concerns">Security Concerns</option>
                          <option value="Too Many Notifications / Emails">Too Many Notifications / Emails</option>
                          <option value="Difficult to Use">Difficult to Use</option>
                          <option value="Poor Customer Service">Poor Customer Service</option>
                          <option value="Limited Features or Functionality">Limited Features or Functionality</option>
                          <option value="Cost-Related Issues">Cost-Related Issues</option>
                          <option value="Technical Issues or Bugs">Technical Issues or Bugs</option>
                          <option value="Concerns About Content or Community">Concerns About Content or Community</option>
                          <option value="Personal Reasons">Personal Reasons</option>
                          <option value="Data Deletion Requests">Data Deletion Requests</option>
                          <option value="Ethical or Moral Reasons">Ethical or Moral Reasons</option>
                          <option value="Too Many Ads">Too Many Ads</option>
                          <option value="Dissatisfaction with Premium Services">Dissatisfaction with Premium Services</option>
                          <option value="Not Enough Features">Not Enough Features</option>
                          <option value="Unwanted Promotions">Unwanted Promotions</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="userReason"
                          label="Describe Your Reason"
                          value={userReason}
                          className="form-control"
                          placeholder="Describe your reason"
                          type="textarea"
                          required
                          onChange={(e) => setUserReason(e.target.value)}
                        />
                      </div>

                      <div className="mb-3 row mt-4">
                        <div className="col-6"></div>
                        <div className="col-6 text-end">
                          <button
                            className="btn btn-orange w-md waves-effect waves-light"
                            type="submit"
                            onClick={submitRequest}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
};
