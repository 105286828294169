import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../Common/Utils.js";
import { Link, NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
  Form,
} from "reactstrap";

const FormComponent = ({ addData, discountList }) => {
  const [brunchData, setBrunchData] = useState({});

  const updateValue = (index, value) => {
    const list = { ...brunchData };
    list[index] = value;
    setBrunchData(list);
  };

  const addBrunchData = (e) => {
    e.preventDefault();

    if (!brunchData.item) {
      alert("Please enter item");
      return false;
    }

    if (!brunchData.amount) {
      alert("Please enter amount");
      return false;
    }

    if (!brunchData.itemDiscount) {
      alert("Please enter discount");
      return false;
    }

    addData(brunchData);
    const packData = { ...brunchData };
    packData.item = "";
    packData.amount = "";
    packData.itemDiscount = "";       
    setBrunchData(packData);
  };

  return (
    <>
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="table-shorting">
                <Form onSubmit={addBrunchData}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Item
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("item", e.target.value)
                              }
                              value={brunchData?.item}
                              name="item"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Amount
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("amount", e.target.value)
                              }
                              value={brunchData?.amount}
                              name="amount"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount
                          </label>
                          <div className="col-sm-6">
                          <select
                              value={brunchData?.itemDiscount}
                              onChange={(e) =>
                                updateValue("itemDiscount", e.target.value)
                              }
                              class="form-select"
                            >
                              <option> Select</option>
                              {discountList.map((item, index) => (
                                <option value={item._id}>{item.title}</option>
                              ))}
                            </select>
                           
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </>
  );
};

const BrunchModal = ({ show, closeModal, addData, discountList }) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Add Brunches
        </h5>
        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <FormComponent addData={addData} discountList={discountList} />
      </div>
    </Modal>
  );
};

export default BrunchModal;
